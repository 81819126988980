import { useEffect, useRef, useState } from 'react'
import { useEditorContext } from '@/scenes/engine'
import DropZone from '@components/Dropzone'

import { uniqueFilename } from '@/utils/unique'
import { useSelector } from 'react-redux'
import { selectUploads } from '@/store/slices/uploads/selectors'
import { useAppDispatch } from '@/store/store'
import { setUploading, uploadFile } from '@/store/slices/uploads/actions'
import ButtonCustom from '@/components/ButtonCustom'
import { KIND, SHAPE, SIZE } from 'baseui/button'
import { SizeButton } from '@/constants/sizeButton'
import UploadsIcon from '../../Icons/Uploads'
import { ObjectType } from '@/scenes/engine/common/constants'
import { lightTheme } from '@/customTheme'
import Icons from '../../Icons'
import AutoScroll from '@/components/AutoScroll'
import { ThemeProvider } from 'baseui'
import useAppContext from '@/hooks/useAppContext'
import { PanelType } from '@/constants/app-options'
import { Modal, ModalBody, ModalFooter, ModalHeader, ROLE } from 'baseui/modal'
import { LabelLarge } from 'baseui/typography'
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository'
import { nanoid } from 'nanoid'
import { MediaImageType } from '@/scenes/engine/objects/media-repository/media_image_type'
import { useStyletron } from 'baseui'
import { selectTemplateDetails } from '@/store/slices/templates/selectors'
import { selectImageElements } from '@/store/slices/imageElement/selectors'
import CanvasImageRenderer from '@/scenes/engine/utils/canvasImageRenderer'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'
import HeadingInspector from '@/components/HeadingInspector'

function Uploads({
  name,
  setIsOpen,
  isOpen,
}: {
  name: string
  setIsOpen: Function
  isOpen: boolean
}) {
  const templateDetails = useSelector(selectTemplateDetails)
  const [css, theme] = useStyletron()
  const [currentFile, setCurrentFile] = useState<any>(null)
  const [hasOneImage, setHasOneImage] = useState(false)
  const [totalImageUpload, setTotalImageUpload] = useState(0)
  
  
  const inputFileRef = useRef<HTMLInputElement>(null)
  const uploads = useSelector(selectUploads).filter(upload => !upload.fromCreationPage)
   
  // const uploading = useSelector(selectUploading)
  const editor = useEditorContext().editor
  const dispatch = useAppDispatch()
  let imageProcessing = MediaImageRepository.getInstance()._mediaImageRepositoryProcessing
  const filterPacks = useSelector(selectImageElements)
  const { activeObject } = useEditorContext() as any

  const { setActivePanel, setObjDragging, objDragging, isReplacingImage, setIsReplacingImage } =
    useAppContext()

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isBoxShadow, setIsBoxShadow] = useState(false)

  const handleDropFiles = (files: FileList) => {
    let isUnsupport = false

    for (let file of files) {
      if (
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/ifif' ||
        file.type === 'image/pjpeg' ||
        file.type === 'image/pjp'
      ) {
        handleUploadFile(file)

        const reader = new FileReader()
        reader.addEventListener(
          'load',
          function () {
            setCurrentFile(reader.result)
          },
          false
        )

        if (file) {
          reader.readAsDataURL(file)
        }
        // @ts-ignore
        window.dataLayer.push({ event: 'photo_upload'});
      } else {
        isUnsupport = true
      }
    }

    if (isUnsupport) {
      setIsOpenModal(true)
    }
  }

  const handleUploadFile = async (file: File) => {
    try {
      const updatedFileName = uniqueFilename(file.name)
      const updatedFile = new File([file], updatedFileName)
      dispatch(
        setUploading({
          progress: 0,
          status: 'IN_PROGRESS',
        })
      )

      await dispatch(uploadFile({ file: updatedFile }))
      // const response = await api.getSignedURLForUpload({ name: updatedFileName })
      // await axios.put(response.url, updatedFile, {
      //   headers: { 'Content-Type': 'image/png' },
      // })
      // await api.updateUploadFile({ name: updatedFileName })
      customAmplitude('bazaart.add.photo.upload')
    } catch (err) {
      console.log({ err })
    }
  }

  const addImageToCanvas = async url => {
    let guid = nanoid()
    let assetStateId = nanoid()

    let maskInfo = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractMask(url)

    await MediaImageRepository.getInstance().storeImageBase64(guid, assetStateId, MediaImageType.latest, url)
    await MediaImageRepository.getInstance().storeImageBase64(
      guid,
      assetStateId,
      MediaImageType.original,
      url
    )
    await MediaImageRepository.getInstance().storeImageBase64(
      guid,
      assetStateId,
      MediaImageType.mask,
      maskInfo.blob
    )

    let frame = editor.handlers.frameHandler.get()
    let layerSize = maskInfo.size
    let canvasAspectRatio = frame.width / frame.height
    let layerAspectRatio = layerSize.width / layerSize.height
    let width = 0.6

    if (layerAspectRatio < canvasAspectRatio) {
      width = (width * layerAspectRatio) / canvasAspectRatio
    }

    const object = {
      type: ObjectType.BAZAART_IMAGE,
      centerPoint: {
        x: 0.5,
        y: 0.5,
      },
      sizeOnCanvas: {
        width: width,
      },
      transformation: {
        horizontalFlip: false,
        verticalFlip: false,
      },
      boundingBox: { y: 0, width: 1, height: 1, x: 0 },
      absoluteRotation: 0,
      bazaartGuid: guid,
      layerAssetStateId: assetStateId,
      hasTransparency: maskInfo.hasTransparency,
    }
    editor.handlers.objectsHandler.add(object)

    customAmplitude('bazaart.add.photo.canvas')
    setActivePanel(PanelType.BACKGROUND)
  }

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleDropFiles(e.target.files)
    setTotalImageUpload(totalImageUpload + e.target.files.length)
    if(e.target.files.length === 1) {
      setHasOneImage(true)
    }
    e.target.value = ''
  }

  const handleInputFileRefClick = () => {
    inputFileRef.current?.click()
  }

  const { setRemovingBg, setRemoveBgSuccess, setRemoveBgAbortController } = useAppContext()

  const removeBg = async resizedImage => {
    setRemoveBgSuccess(false)
    setRemovingBg(true)
    let abortController = new AbortController()
    setRemoveBgAbortController(abortController)

    setTimeout(async () => {
      await editor.handlers.objectsHandler.removeBg(null, abortController.signal, resizedImage)
      setTimeout(() => {
        setRemoveBgSuccess(true)
      }, 100)
    }, 100)
  }

  async function fileSelected(fileObject) {
    let resizedImage = await imageProcessing.resizeBlobToMaxEdgeSize(fileObject.url, 1280)
    let bb = await imageProcessing.extractBoundingBox(resizedImage, false)
    let resizedHtmlImage = await imageProcessing.loadImage(resizedImage)
    let croppedHtmlImage = await imageProcessing.cropHtmlImage(resizedHtmlImage, bb);
    let croppedImage = croppedHtmlImage.src

    if (isReplacingImage) {
      setIsReplacingImage(false)
      const replaceLayer = activeObject ? activeObject : editor.handlers.objectsHandler.replaceRefLayer
      const isMagicTemplate = templateDetails.is_magic
      let isMagicLyaer = isMagicTemplate && replaceLayer.isTemplateLayer
      editor.handlers.transactionHandler.save()
      await editor.handlers.objectsHandler.replaceImage(croppedImage, isMagicLyaer)
      await CanvasImageRenderer.getInstance().render(
        editor.handlers.canvasHandler.canvas.getActiveObject(),
        filterPacks
      )
      if (isMagicLyaer) {
        removeBg(croppedImage)
      }

      const eventProperties = {
        Tool: 'bazaart.replace',
        Type: ObjectType.BAZAART_IMAGE,
      }
      customAmplitude('Selected tool', eventProperties)
    } else {
      addImageToCanvas(croppedImage)
      customAmplitude('bazaart.add.photo.click')
    }
    setIsOpen()
  }

  useEffect(() => {
    if (hasOneImage) {
      if (uploads && uploads.length > 0 && totalImageUpload === uploads.length) {
        fileSelected(uploads[0])
        setHasOneImage(false)
      }
    }
    // if (uploads && uploads.length === 1) {
    //   fileSelected(uploads[0])
    // }
  }, [hasOneImage, uploads, totalImageUpload])

  const { t } = useTranslation()

  return (
    <ThemeProvider theme={lightTheme}>
      <div
        id="popover-photo"
        style={{
          overflow: 'hidden',
          boxSizing: 'border-box',
          position: 'fixed',
          top: 'calc(-50vh + 48px + 180px)',
          left: '80px',
          background: '#ffffff',
          width: '340px',
          height: 'calc(100vh - 96px)',
          flex: 'none',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
          borderRadius: '16px',
          display: isOpen ? 'flex' : 'none',
          flexDirection:'column'
        }}
      >
        <HeadingInspector
          hasBoxShadow={isBoxShadow}
          hasNavigation={isReplacingImage}
          handleNavigation={() => setIsOpen()}
          title={isReplacingImage? t('Replace photo') : !uploads.length ? t('Photos') : t('Uploads')}
          hasClose={true}
          handleClose={() => {
            setIsOpen()
          }}
        ></HeadingInspector>
        <div
          style={{
            textAlign: 'center',
            paddingBottom: '12px',
            boxShadow: isBoxShadow ? '0px 1px 6px 0px rgba(0, 0, 0, 0.10)' : 'none',
          }}
        >
          <ButtonCustom
            kind={KIND.primary}
            type={SizeButton.LARGE}
            onClick={handleInputFileRefClick}
            style={{ color: 'white', margin: '0 auto', width: '292px' }}
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '6px' }}>
              <UploadsIcon fill="#fff"/>
              <p style={{ ...lightTheme.typography.Small14Semibold, color: '#fff' }}>{t('Upload photos')}</p>
            </div>
          </ButtonCustom>
        </div>
        <DropZone handleDropFiles={handleDropFiles} isParentOpen={isOpen}>
          {!uploads.length && (
            <div
              style={{
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                borderRadius: '8px',
                margin: '24px',
                marginTop: 0,
                border: '1px dashed #B2B2B2',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Icons.UploadsImage />
              <div style={{ ...lightTheme.typography.Small14Semibold, color:lightTheme.colors.text.text_600_default, margin: '16px 0' }}>
                {t('Drag & drop files here')}
              </div>
              {/* <ButtonCustom
                kind={KIND.primary}
                type={SizeButton.LARGE}
                onClick={handleInputFileRefClick}
                style={{ width: '244px', margin: '0 auto' }}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '6px' }}>
                  <UploadsIcon size={24} />
                  <p style={{ ...lightTheme.typography.Small14Semibold }}>Upload from device</p>
                </div>
              </ButtonCustom> */}
              <input
                onChange={handleFileInput}
                type="file"
                id="file"
                ref={inputFileRef}
                style={{ display: 'none' }}
                accept="image/png, image/jpg, image/jpeg"
                multiple
              />
            </div>
          )}
          {!!uploads.length && (
            <>
              <AutoScroll
                style={{
                  backgroundColor: '#ffffff',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '8px',
                  flexBasis: 'content',
                  // height: 'calc(100% - 89px)',
                  height: '100%',
                  borderRadius: '16px',
                  marginTop: '0',
                }}
                handleScroll={e => {
                  if (e.target.scrollTop > 0) {
                    setIsBoxShadow(true)
                  } else {
                    setIsBoxShadow(false)
                  }
                }}
              >
                {/* {uploading && <img width="100%" src={currentFile} alt="uploaded" />} */}
                {uploads.map((upload, index) => (
                  <div
                    key={upload.url}
                    className={css({
                      cursor: 'pointer',
                      width: '92px',
                      height: '92px',
                      borderRadius: '8px',
                      zIndex: 1000,
                      position: 'relative',
                      ':hover div': {
                        display: 'block !important',
                      },
                      ':active div': {
                        display: 'block !important',
                        background: 'rgba(0,0,0,.4) !important',
                      },
                    }) + ' item-list-upload'}
                    onClick={() => {
                      fileSelected(upload)
                    }}
                  >
                    <img
                      style={{ width: '100%', height: '100%', borderRadius: '8px', objectFit: 'cover' }}
                      src={upload.url}
                      alt="preview"
                      draggable={true}
                      onDrag={(e: any) => {
                        setIsOpen()
                        if (!objDragging.item || objDragging.item.url !== upload.url) {
                          setObjDragging({
                            item: upload,
                            type: ObjectType.BAZAART_IMAGE,
                          })
                        }
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        inset: 0,
                        background: 'rgba(0,0,0,0.25)',
                        display: 'none',
                        zIndex: 10,
                        borderRadius: '8px',
                        pointerEvents: 'none',
                      }}
                    ></div>
                  </div>
                ))}
              </AutoScroll>

              <input
                onChange={handleFileInput}
                type="file"
                id="file"
                ref={inputFileRef}
                style={{ display: 'none' }}
                accept="image/png, image/jpg, image/jpeg"
                multiple
              />
            </>
          )}
        </DropZone>

        <Modal
          onClose={() => setIsOpenModal(false)}
          isOpen={isOpenModal}
          animate
          closeable={true}
          focusLock={false}
          returnFocus={false}
          size={SIZE.default}
          role={ROLE.dialog}
          overrides={{
            Backdrop: {
              style: ({ $theme }) => ({
                ...lightTheme.modal.BackdropStyle
              }),
            },
            Root: {
              style: ({ $theme }) => ({
                display: 'flex',
                flexDirection: 'column',
                zIndex: 1000,
              }),
            },
            Dialog: {
              style: ({ $theme, $isOpen, $isVisible }) => ({
                width: '460px',
                height: '190px',
                padding: '24px',
                ...lightTheme.modal.DialogStyle({ $theme, $isOpen, $isVisible })
              }),
            },
            Close: {
              style: ({ $theme }) => ({
                display: 'none'
              })
            }
          }}
        >
          <ModalHeader
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0',
              height: '48px',
              paddingBottom: '16px',
            }}
          >
            <p style={{ ...lightTheme.typography.Header20bold }}>{t('Unsupported file type')}</p>

            <ButtonCustom
              shape={SHAPE.square}
              kind={KIND.minimal}
              type={SizeButton.SMALL}
              onClick={() => setIsOpenModal(false)}
            >
              <Icons.Close size={24} />
            </ButtonCustom>
          </ModalHeader>
          <ModalBody style={{ display: 'flex', flexDirection: 'row', gap: '10px', margin: '0' }}>
            <LabelLarge
              $style={{
                fontSize: '14px',
                fontWeight: '500',
                color: '#666666',
                lineHeight: '22px',
              }}
            >
              {t('Files for upload should be .jpg or .png and 10 MB or less.')}
            </LabelLarge>
          </ModalBody>
          <ModalFooter
            style={{
              height: '72px',
              margin: '0',
              padding: '0',
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'end',
              gap: '8px',
            }}
          >
            <ButtonCustom
              kind={KIND.primary}
              type={SizeButton.STANDARD}
              onClick={() => setIsOpenModal(false)}
              style={{ width: 'fit-content', padding: '8px 12px' }}
            >
              <p style={{ fontSize: '14px', fontWeight: '600', lineHeight: '24px' }}>{t('Ok, got it!')}</p>
            </ButtonCustom>
          </ModalFooter>
        </Modal>
      </div>
    </ThemeProvider>
  )
}

export default Uploads
