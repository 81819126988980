import { useEffect, useState } from 'react'
import { PopoverType, panelListItems } from '@/constants/app-options'
import PanelListItem from '../../Panels/PanelListItem'
import useAppContext from '@/hooks/useAppContext'
import Graphics from '../../Panels/PanelItems/Graphics'

function AddGraphics() {
  const panelListItem = panelListItems.find(x => x.name === 'Graphics')

  const { activePanel, popoverActive, setPopoverActive } = useAppContext()

  return (
    <div>
      <div
        onClick={() => {
          setPopoverActive(popoverActive === PopoverType.GRAPHICS ? null : PopoverType.GRAPHICS)
        }}
        id="popover-graphic"
      >
        <PanelListItem
          disable={panelListItem.disable}
          label={panelListItem.name}
          name={panelListItem.name}
          key={panelListItem.name}
          icon={panelListItem.name}
          activePanel={activePanel}
          panelSelected={popoverActive === PopoverType.GRAPHICS}
        />
      </div>
      <Graphics close={() => setPopoverActive(null)} isOpen={popoverActive === PopoverType.GRAPHICS} />
    </div>
  )
}

export default AddGraphics
