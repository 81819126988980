import { useEffect, useState } from 'react'
import useAppContext from '@/hooks/useAppContext'
import { useParams } from 'react-router'
import { getFonts } from '@store/slices/fonts/actions'
import { getEffect } from '@/store/slices/effect/actions'
import { getBackgroundPacks } from '@store/slices/backgroundPacks/actions'
import { useAppDispatch } from '@store/store'
import Navbar from './components/Navbar'
import Panels from './components/Panels'
import Footer from './components/Footer'
import api from '@services/api'
import Editor from '../engine/BZCanvas'
import { useEditorContext } from '../engine'
import { getGraphicPacks } from '@/store/slices/graphicsPacks/actions'
import { getTemplateDetails, getTemplatesSuperCategories } from '@/store/slices/templates/actions'
import Loading from '@/components/Loading'
import { PanelType, PopoverType } from '@/constants/app-options'
import PanelItems from './components/Panels/PanelItems'
import { SubMenuType } from '@/constants/editor'
import TooltipMenu from './components/TooltipMenu'
import { CSSTransition } from 'react-transition-group'
import './editor.css'
import { useSelector } from 'react-redux'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { ThemeProvider } from 'baseui'
import { lightTheme } from '@/customTheme'
import { setTemplateConfig } from '@/store/slices/templates/actions'
import ModalCustom from './components/Modal/Modal'
import { getImageElements } from '@/store/slices/imageElement/actions'
import RemoveToolCanvas from '../engine/RemoveToolCanvas'
import RemoveToolFooter from './components/Footer/RemoveToolFooter'
import { SignInManager } from './components/Navbar/components/SignInManager'
import { setIsSampleUser, setOpenModalTryPremium } from '@/store/slices/user/actions'
import {
  selectCallbackFromCreationPage,
  selectIsOpenErrorModalRemoveBg,
} from '@/store/slices/editor/selector'
import { setCallbackFromCreationPage, setIsOpenErrorModalRemoveBg } from '@/store/slices/editor/action'
import { customAmplitude } from '@/utils/customAmplitude'
import ContextMenu from './components/ContextMenu'
import * as amplitude from '@amplitude/analytics-browser'
import Sidebar from './components/Sidebar/Sidebar'
import { selectUploads } from '@/store/slices/uploads/selectors'
import { setUploads } from '@/store/slices/uploads/actions'
import { ActionType } from '@/store/slices/editor/reducer'
import { selectTemplatesSuperCategories } from '@/store/slices/templates/selectors'
import { selectOpenModalTryPremium } from '@/store/slices/user/selectors'
import { Helmet } from 'react-helmet';
import { t } from 'i18next'
import ImageRemoveTutorial from '@assets/images/image-remove-tutorial.jpg'
import { useTour } from '@reactour/tour'
import {useFrontChatBoot} from '../../libs/front-chat-sdk/hooks/use-front-chat-boot'


function App({ location }) {
  //
  const {
    activePanel,
    activeSubMenu,
    isOpenInspector,
    setIsOpenInspector,
    popoverActive,
    setPopoverActive,
    isOpenPixelManipulationObject,
    setisOpenPixelManipulationObject,
    setIsOpenPixelManipulationWithAnimation,
    setToolType,
    setIsOpenTutorial
  } = useAppContext()
  const { canvas, contextMenuDetails, setContextMenuDetails } = useEditorContext()

  const [componentDefault, setComponentDefault] = useState(null)
  const [unloadedTemplate, setUnloadedTemplate] = useState(false)

  const { activeObject } = useEditorContext()
  const { setCurrentTemplate, setIsLoadedJsonSuccess, isLoadedJsonSuccess } = useAppContext()

  const openModalTryPremium = useSelector(selectOpenModalTryPremium)

  const editor = useEditorContext().editor
  const { id = null } = useParams<{ id: string }>()

  const dispatch = useAppDispatch()
  const urlParams = new URLSearchParams(window.location.search)

  const { removingBg, setRemovingBg, removeBgAbortController, setRemoveBgAbortController, canCancelRemoveBg } = useAppContext()

  const preview_path = urlParams.get('prevUrl')
  const po = PopoverType[urlParams.get('po')]
  const rp = urlParams.get('rp')
  // const fonts = useSelector(selectFonts)
  const isOpenErrorModalRemoveBg = useSelector(selectIsOpenErrorModalRemoveBg)

  const [boundingRectFrame, setBoundingRectFrame] = useState(null)
  const [loadPixelTool, setLoadPixelTool] = useState(false)
  const uploads = useSelector(selectUploads)

  const callbackFromCreationPage = useSelector(selectCallbackFromCreationPage)
  const templatesSuperCategories = useSelector(selectTemplatesSuperCategories)

  const { setIsOpen } = useTour()
  const chatId = '196a6f45d0e4514761f59cd3554f6ea8';
  const {frontChat, initialize, isInitialized} = useFrontChatBoot(document.body);
  useEffect(() => {
    // dispath(getElements())
    // MINIBIS: hides it becasue we don't want to show those for now
    dispatch(getFonts())
    dispatch(getGraphicPacks())
    dispatch(getBackgroundPacks())
    if(!templatesSuperCategories.length) {
      dispatch(getTemplatesSuperCategories())
    }
    dispatch(getEffect())
    dispatch(getImageElements())
    chooseSampleUser()
    setPopoverActive(po)
    if(rp === 'RESIZE') {
      dispatch(
        setCallbackFromCreationPage({
          action: ActionType.CUSTOM_SIZE,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const editorConfig = {
    clipToFrame: true,
    visible: true,
  }

  const chooseSampleUser = () => {
    const randomNumber = Math.floor(Math.random() * 10) + 1
    const isChooseen = randomNumber === 1
    dispatch(setIsSampleUser(isChooseen))
    const identifyEvent = new amplitude.Identify()
    identifyEvent.set('SampleUser', isChooseen)
  }

  const handleCallbackFromCreationPage = async () => {
    setIsLoadedJsonSuccess(false)
    editor.handlers.objectsHandler.clear(false, false)
    if (callbackFromCreationPage.action === ActionType.CUSTOM_SIZE) {
      if(callbackFromCreationPage.analytic) {
        const eventProperties = {
          Source: 'Scratch'
        }
        eventProperties['Project Size Id'] = callbackFromCreationPage.analytic
        customAmplitude('Create', eventProperties)
      }
    } else if (callbackFromCreationPage.action === ActionType.ADD_IMAGE) {
      await editor.handlers.objectsHandler.addImageToCanvasByUrl(uploads[0].url, true)
      customAmplitude('Create', {
        Source: 'Photo'
      })
    } else if (callbackFromCreationPage.action === ActionType.REMOVE_BG) {
      await editor.handlers.objectsHandler.addImageToCanvasByUrl(uploads[0].url, true, true)
      customAmplitude('Create', {
        Source: 'usecase.magic'
      })
    } else if (callbackFromCreationPage.action === ActionType.REMOVE_OBJ) {
      setIsOpenPixelManipulationWithAnimation(false)
      await editor.handlers.objectsHandler.addImageToCanvasByUrl(uploads[0].url, true)
      let containerOverlay = document.getElementById('container-overlay-remove')
      if(containerOverlay) {
        containerOverlay.style.display = 'block'
      }
      setToolType('Remove')
      customAmplitude('Create', {
        Source: 'usecase.remove'
      })
    } else if (callbackFromCreationPage.action === ActionType.ADD_TEMPLATE) {
      setPopoverActive(PopoverType.TEMPLATES)
      customAmplitude('Create', {
        Source: 'Template'
      })
      dispatch(setCallbackFromCreationPage(null))
    } else if (callbackFromCreationPage.action === ActionType.QUICK_TUTORIAL) {
      await editor.handlers.objectsHandler.addImageToCanvasByUrl(ImageRemoveTutorial, true)
    } else if (callbackFromCreationPage.action === ActionType.MAGIC_BACKGROUND) {
      setIsOpenPixelManipulationWithAnimation(false)
      await editor.handlers.objectsHandler.addImageToCanvasByUrl(uploads[0].url, true)
      let containerOverlay = document.getElementById('container-overlay-remove')
      if(containerOverlay) {
        containerOverlay.style.display = 'block'
      }
      setToolType('MagicBg')
      customAmplitude('Create', {
        Source: 'usecase.magicbg'
      })
    }
    setIsLoadedJsonSuccess(true)
    dispatch(setUploads([]))
  }

  useEffect(() => {
    if (!editor) {
      return
    }
    const abortController = new AbortController()
    const signal = abortController.signal
    if (callbackFromCreationPage) {
      (async () => {
        await handleCallbackFromCreationPage()
        setLoadPixelTool(true)
      })()
    } else {
      setLoadPixelTool(true)
      setIsLoadedJsonSuccess(false)
      if (id) {
        if(!signal.aborted) {
          api.getCreationById(id).then(async data => {
            if (!data) {
              setUnloadedTemplate(true)
              setIsLoadedJsonSuccess(false)
              return
            }
            if (data && data.object !== 'error') {
              // const fontFace = new FontFace(
              //   'Poppins-SemiBold',
              //   `url(https://media.bazaart.me/Stickers/0b1991c9-d4d8-4ada-b54d-7c2898523b24.ttf)`,
              //   {
              //     style: 'normal',
              //     weight: '400',
              //   }
              // )
              // fontFace.load().then(loadedFont => {
              //   document.fonts.add(loadedFont)
              // })
              if(!signal.aborted) {
                dispatch(setTemplateConfig(data.config))
                dispatch(getTemplateDetails(id))
                setCurrentTemplate(data)
                handleLoadTemplate(data)
              }
            }
          })
        }
      } else {
        if (!SignInManager.getInstance().userIsPremium) {
          if(!openModalTryPremium.opened) {
            dispatch(
              setOpenModalTryPremium({
                isOpen: true,
                source: 'Editor Open',
                opened: true
              })
            )
          }
    
          const eventProperties = {
            Source: 'Editor Open',
            Type: 'Standard',
          }
          customAmplitude('Premium Prompt', eventProperties)
          // @ts-ignore
          window.dataLayer.push({ event: 'premium_prompt', ...eventProperties })
        }
        setIsLoadedJsonSuccess(true)
      }
    }

    editor.on('history:changed', () => {
      setBoundingRectFrame(() => editor.handlers.frameHandler.get().getBoundingRect())
    })
    editor.on('frame:changed', () => {
      setBoundingRectFrame(() => editor.handlers.frameHandler.get().getBoundingRect())
    })
    const handleLeaveEditor = function (e) {
      if(!editor) 
        return
      if(editor.handlers.transactionHandler.redos.length || editor.handlers.transactionHandler.undos.length) {
        e.preventDefault()
        e.stopPropagation()
        this.alert('You have unsaved changes. Are you sure you want to leave?')
      }
    }
    window.addEventListener('beforeunload', handleLeaveEditor)
    return () => {
      editor.off('history:changed', () => {})
      abortController.abort()
      setIsLoadedJsonSuccess(true)
      window.removeEventListener('beforeunload', handleLeaveEditor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, location])

  const handleLoadTemplate = async template => {
    const fonts = []
    if (template.config && template.config.fonts) {
      template.config.fonts.forEach(font => {
        fonts.push({
          name: font.keySystemName,
          url: font.keyFileURL,
          options: { weight: '400' },
        })
      })
    }
    const filteredFonts = fonts.filter(f => !!f.url)
    if (filteredFonts.length > 0) {
      await loadFonts(filteredFonts)
    }
    // TODO fix implemnt import from json template

    await editor.importFromJSON(template)
    setIsLoadedJsonSuccess(true)

    const eventProperties = {
      Source: 'Template',
      'Template id': id,
      'Category id': 0,
      'Category Name': 'none',
    }
    customAmplitude('Editor Open', eventProperties)

    if (!SignInManager.getInstance().userIsPremium && !openModalTryPremium.opened) {
      dispatch(
        setOpenModalTryPremium({
          isOpen: true,
          source: 'Editor Open',
          opened: true
        })
      )

      const eventProperties = {
        Source: 'Editor Open',
        Type: 'Standard',
      }
      customAmplitude('Premium Prompt', eventProperties)
      // @ts-ignore
      window.dataLayer.push({ event: 'premium_prompt', ...eventProperties })
    }
  }

  const loadFonts = fonts => {
    const promisesList = fonts.map(font => {
      // @ts-ignore
      // return new FontFace(font.name, `url(${font.url.replace('https://media.bazaart.me','/media')})`, font.options).load().catch(err => err)
      return new FontFace(font.name, `url(${font.url})`, font.options).load().catch(err => err)
    })

    return new Promise((resolve, reject) => {
      Promise.all(promisesList)
        .then(res => {
          res.forEach(uniqueFont => {
            // @ts-ignore
            if (uniqueFont && uniqueFont.family) {
              // @ts-ignore
              document.fonts.add(uniqueFont)
              resolve(true)
            }
          })
        })
        .catch(err => reject(err))
    })
  }

  useEffect(() => {
    setComponentDefault(() =>
      (activeObject && activeSubMenu) || (!activeObject && activeSubMenu === SubMenuType.COLOR)
        ? PanelItems[activeSubMenu]
        : activeObject
        ? PanelItems[PanelType.TOOLBOX]
        : PanelItems[activePanel]
    )
    // setIsOpenInspector(true)
  }, [activePanel, activeSubMenu, activeObject])

  const abortRemoveBackground = () => {
    removeBgAbortController.abort()
    setRemoveBgAbortController(null)
    setRemovingBg(false)
  }

  useEffect(() => {
    document.addEventListener(
      'keydown',
      e => {
        if (e.keyCode === 27) {
          if (removingBg && removeBgAbortController) {
            abortRemoveBackground()
          }
        }
      },
      false
    )

    return () => {
      document.removeEventListener(
        'keydown',
        e => {
          // console.log('esc', e)
        },
        false
      )
    }
  }, [removingBg, setRemovingBg, removeBgAbortController, setRemoveBgAbortController])

  useEffect(() => {
    if (canvas) {
      canvas.on('mouse:down', e => {
        if (!isOpenInspector) {
          // @ts-ignore
          if (e.target && e.target.isTemplateLayer) {
            return
          }
          setIsOpenInspector(true)
          editor.handlers.scrollbarHandler.updateScrollPosition()
        }
      })
    }
  }, [canvas, isOpenInspector])


  useEffect(() => {
    if (!initialize || isInitialized) {
      return;
    }

    initialize({chatId});
  }, [isInitialized, initialize]);

  return (
    <ThemeProvider theme={lightTheme}>
      <Helmet>
        <title>Bazaart Design Editor</title>
      </Helmet>
      <div
        style={{
          position: 'relative',
          width: '100vw',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          background: '#F2F2F2',
          overflow: 'hidden',
        }}
        onClick={(e: any) => {
          if (contextMenuDetails) {
            setContextMenuDetails(null)
          }
          if (e.target.closest('#wrap-modal')) {
            return
          }
          if (e.target.closest('.btn-close-popover')) {
            setIsOpenInspector(true)
          }
          if (popoverActive) {
            if (
              !e.target.closest('#popover-photo') &&
              !e.target.closest('#popover-graphic') &&
              !e.target.closest('#popover-template') &&
              !e.target.closest('#popover-stock') &&
              !e.target.closest('#popover-text')
            ) {
              setPopoverActive(null)
            }
          }
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            transition: 'all .4s ease-in',
          }}
        >
          <Sidebar />
          <div
            id="wrap-canvas"
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
              transition: 'all .4s ease-in',
            }}
            onContextMenu={e => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <TooltipMenu />
            {(editor && loadPixelTool) ? <RemoveToolCanvas /> : null}
            <Editor config={editorConfig} />
            {canvas && <ContextMenu />}
          </div>
          {isOpenPixelManipulationObject ? <RemoveToolFooter /> : <Footer />}
        </div>
        {/* <Panels /> */}
        <Panels isOpen={isOpenInspector && activePanel !== null && componentDefault} />

        <ModalCustom
          isOpen={unloadedTemplate || isOpenErrorModalRemoveBg}
          onClose={value => {
            if (isOpenErrorModalRemoveBg) {
              dispatch(setIsOpenErrorModalRemoveBg(value))
            } else {
              setUnloadedTemplate(value)
            }
          }}
          content={{
            header: isOpenErrorModalRemoveBg ? t('Oops!') : t('Uh-oh! Something went wrong!'),
            desc: isOpenErrorModalRemoveBg
              ? t('We couldn’t remove the background from your photos. Please try again.')
              : t('Try a different template.'),
          }}
          action={{
            primary: {
              name: 'Ok',
              handle: () => {
                if (isOpenErrorModalRemoveBg) {
                  dispatch(setIsOpenErrorModalRemoveBg(false))
                } else {
                  location.href = 'https://bazaart.me'
                }
              },
            },
          }}
        />
        {boundingRectFrame ? <div
          id="container-overlay-remove"
          style={{ position: 'absolute', inset: 0, background: 'transparent', zIndex: 98 }}
        >
          <div
            className="overlay-remove"
            style={{
              position: 'absolute',
              // only show when removing bg
              display: removingBg ? 'flex' : 'none',
              top: `${boundingRectFrame.top < 0 ? 0  : boundingRectFrame.top}px`,
              left: boundingRectFrame.left > 0 ? `${boundingRectFrame.left}px` : 0,
              width: boundingRectFrame.left < 0 ? `100vw` : `${boundingRectFrame.width}px`,
              height:
                boundingRectFrame.top < 0
                  ? `calc(100vh - 64px)`
                  : `${boundingRectFrame.height}px`,
              borderRadius: boundingRectFrame.top < 0 ? 0 : '8px',
              background: 'rgba(0,0,0,.65)',
              zIndex: 99,
            }}
          >
            <CSSTransition in={removingBg} classNames="my-node" timeout={400}>
              <div id="wrap-load-animation">
                <canvas
                  id="canvas-animation"
                  style={{
                    height: '124px',
                    marginTop: '-62px',
                  }}
                ></canvas>
                <p
                  style={{
                    color: '#fff',
                    transition: 'all 0.7s',
                    margin: '8px 0 24px',
                    ...lightTheme.typography.LabelLarge,
                  }}
                  id="text-remove-bg"
                >
                  {t('Just a sec...')}
                </p>
                <ButtonCustom
                  transparent
                  type={SizeButton.SMALL}
                  style={{
                    minWidth: '80px',
                    width: 'max-content',
                    borderRadius: '16px',
                    pointerEvents: 'auto',
                    display: canCancelRemoveBg ? 'flex' : 'none',
                  }}
                  onClick={() => {
                    abortRemoveBackground()
                  }}
                >
                  <p
                    style={{
                      ...lightTheme.typography.LabelLarge,
                      color: lightTheme.colors.contentPrimary,
                    }}
                  >
                    {t('Cancel')}
                  </p>
                </ButtonCustom>
              </div>
            </CSSTransition>
          </div>
        </div> : null}
      </div>
      {/* intro video */}
      {/* <IntroVideo /> */}
    </ThemeProvider>
  )
}

export default App