import { OrderBy, createApi } from 'unsplash-js'
import nodeFetch from 'node-fetch'

const unsplash = createApi({
  accessKey: 'a635a3dd695420252fa95c18bd323dc05125d9078d121785cc9eb4c36fd785ba',
  fetch: nodeFetch,
})

export const getImagesUnplash = async (page?): Promise<any> => {
  try {
    const res = await unsplash.photos.list({
      page: page ? page : 1,
      perPage: 30,
      orderBy: OrderBy.LATEST,
    })
    return res.response.results
  } catch (err) {
    console.log('err', err)
  }
}

export const searchPhotosUnplash = async (query: string, page?): Promise<any> => {
  try {
    const res = await unsplash.search.getPhotos({
      query: query,
      page: page ? page : 1,
      perPage: 30,
      orderBy: "relevant"
    })
    return res.response.results
  } catch (err) {
    console.log('err', err)
  }
}

export const reportDownloadUnplash = async (url: string): Promise<any> => {
  try {
    unsplash.photos.trackDownload({ downloadLocation: url });
    return
  } catch (err) {
    console.log('err', err)
  }
}