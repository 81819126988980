import { lightTheme } from '@/customTheme'
import { ThemeProvider, styled, useStyletron } from 'baseui'
import Icons from '../Editor/components/Icons'
import { formatSizesBasicForCreationPage } from '@/constants/format-sizes'
import { selectUserIsPremium } from '@/store/slices/user/selectors'
import { useSelector } from 'react-redux'
import { ShowModalType } from '../engine/common/constants'
import useAppContext from '@/hooks/useAppContext'
import { useAppDispatch } from '@/store/store'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import { setCallbackFromCreationPage } from '@/store/slices/editor/action'
import { useHistory } from 'react-router-dom'
import { ActionType } from '@store/slices/editor/reducer'
import { customAmplitude } from '@/utils/customAmplitude'
import { useTranslation } from 'react-i18next'
import ModalCustomSize from '@/components/ShowModal/ModalCustomSize'
import { useState } from 'react'

const ResizeItem = styled('div', props => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '144px',
  width: '222px',
  borderRadius: '16px',
  cursor: 'pointer',
  backgroundColor: lightTheme.colors.grayScale50,
  ':hover': {
    backgroundColor: lightTheme.colors.grayScale100,
  },
  ':active': {
    backgroundColor: lightTheme.colors.grayScale100,
  },
}))
function Resize() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const hasPremium = useSelector(selectUserIsPremium)
  const { setShowModalType } = useAppContext()
  const history = useHistory()
  const [ css ] = useStyletron()
  const [isOpenCustomSize, setIsOpenCustomSize] = useState(false)
  const [customSize, setCustomSize] = useState({ width: 2048, height: 2048 })
  const [aspectRatio, setAspectRatio] = useState(1)
  const [unit, setUnit] = useState('px')

  const applyCustomSize = ({width, height}) => {
    dispatch(
      setCallbackFromCreationPage({
        action: ActionType.CUSTOM_SIZE,
        value: { width: width, height: height, sizeId: 'custom-size', icon: Icons.CustomSize, unit: unit },
        analytic: 'bazaart.size.custom',
      })
    )
    history.push('/')
  }
  return (
    <ThemeProvider theme={lightTheme}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '22px' }}>
        <div
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          className="heading"
        >
          <h2 style={{ ...lightTheme.typography.Header20bold }}>{t('Start designing')}</h2>
          <div
            onClick={() => {
              dispatch(
                setCallbackFromCreationPage({
                  action: ActionType.CUSTOM_SIZE,
                  analytic: 'See all'
                })
              )
              history.push('/')
            }}
            className={css({
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              borderRadius: '12px',
              padding: '0 4px 0 8px',
              height: '24px',
              ':hover': {
                background: lightTheme.colors.grayScale50,
              },
              ':active': {
                background: lightTheme.colors.grayScale100,
              },
            })}
          >
            <p
              style={{
                ...lightTheme.typography.Small12medium,
              }}
            >
              {t('See all')}
            </p>
            <Icons.RedArrowRight fill={lightTheme.colors.blackGray} />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '24px', overflow: 'hidden' }}>
          {/* upload photo */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }} onClick={() => {
            let inputRef = document.getElementById('input-ref-on-create-page')
            if(inputRef) {
              inputRef.click()
            }
          }}>
            {/* upload photo */}
            <ResizeItem>
              <div
                style={{
                  width: '190px',
                  height: '112px',
                  borderRadius: '8px',
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23000000' stroke-width='2' stroke-dasharray='4' stroke-dashoffset='4' /%3e%3c/svg%3e")`,
                }}
              >
                <Icons.UploadsIconLarge />
              </div>
            </ResizeItem>
            <p
              style={{
                ...lightTheme.typography.Small14regular,
                color: lightTheme.colors.contentPrimary,
                textAlign: 'center',
              }}
            >
              {t('Upload photo')}
            </p>
          </div>
          {/* resize */}
          {formatSizesBasicForCreationPage.map((item, index) => {
            return (
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
                key={item.id}
                onClick={() => {
                  if (index) {
                    dispatch(
                      setCallbackFromCreationPage({
                        action: ActionType.CUSTOM_SIZE,
                        value: {
                          width: item.size.width,
                          height: item.size.height,
                          sizeId: `${item.size.width}_${item.size.height}`,
                        },
                        analytic: item.analytic,
                      })
                    )
                    history.push('/')
                  } else {
                    if (hasPremium) {
                      setIsOpenCustomSize(true)
                      // setShowModalType(ShowModalType.CUSTOM_SIZE)
                    } else {
                      customAmplitude('Premium Prompt', {
                        Source: 'BtSubscriptionCanvasSize',
                        analytic: item.analytic,
                      })
                      dispatch(
                        setOpenModalTryPremium({
                          isOpen: true,
                          source: 'Scratch',
                          callback: () => {
                            setIsOpenCustomSize(true)
                            // setShowModalType(ShowModalType.CUSTOM_SIZE)
                          },
                          opened: true,
                        })
                      )
                    }
                  }
                }}
              >
                <ResizeItem>
                  <div
                    style={{
                      width: `${(item.size.width * 96) / item.size.height}px`,
                      height: '96px',
                      borderRadius: '4px',
                      backgroundColor: '#fff',
                      boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.08)',
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {!hasPremium && index === 0 ? (
                      <div style={{ position: 'absolute', top: '-9px', right: '-9px', zIndex: 1 }}>
                        <Icons.PremiumIcon />
                      </div>
                    ) : null}
                    {index === 0 && <Icons.CustomSize />}
                  </div>
                </ResizeItem>
                <p
                  style={{
                    ...lightTheme.typography.Small14regular,
                    color: lightTheme.colors.contentPrimary,
                    textAlign: 'center',
                  }}
                >
                  {t(item.name)}
                </p>
              </div>
            )
          })}
        </div>
        <ModalCustomSize 
          isOpen={isOpenCustomSize}
          onClose={() => setIsOpenCustomSize(false)}
          customSize={customSize}
          setCustomSize={setCustomSize}
          applyCustomSize={applyCustomSize}
          aspectRatio={aspectRatio}
          setAspectRatio={setAspectRatio}
          unit={unit}
          setUnit={setUnit}
          />
      </div>
    </ThemeProvider>
  )
}

export default Resize
