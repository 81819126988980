import { lightTheme } from '@/customTheme'
import { styled } from 'baseui'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonCustom from '../ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND } from 'baseui/button'
import VideoRemoveTutorial from '@assets/video/remove-tutorial.mp4'
import useAppContext from '@/hooks/useAppContext'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { setCallbackFromCreationPage } from '@/store/slices/editor/action'
import { ActionType } from '@/store/slices/editor/reducer'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppDispatch } from '@/store/store'
import { handleCurrentState, setShowedRemoveObjectTutorial } from '@/utils/tutorial'
import { useEditorContext } from '@/scenes/engine'
import { customAmplitude } from '@/utils/customAmplitude'

const WrapAutoScroll = styled('div', (props: any) => ({
  boxSizing: 'border-box',
  height: '100%',
  overflowY: 'scroll',
  overflowX: 'hidden',
  '::-webkit-scrollbar': {
    width: '8px',
    margin: '20px 0',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: lightTheme.colors.grayScale100,
    minHeight: '100px ',
  },
  '::-webkit-scrollbar-thumb:hover': {
    cursor: 'pointer',
    backgroundColor: lightTheme.colors.grayScale150,
  },
  ...props.style,
}))

function ModalTutorial() {
  const { t } = useTranslation()
  const { showModalType, setShowModalType, setIsOpenTutorial } = useAppContext()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { editor} = useEditorContext()
  const location = useLocation();

  useEffect(() => {
    if(showModalType === ShowModalType.QUICK_TUTORIAL) {
      customAmplitude('Tutorial Started', {
        'Tutorial Name': 'Remove',
      })
    }
  }, [showModalType])

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        maxWidth: '920px ',
        maxHeight: '652px',
        display: 'flex',
        flexDirection: 'row',
        background: 'rgba(255, 255, 255, 1)',
        borderRadius: '16px',
        overflow: 'hidden',
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      id="modal-try-premium"
    >
      {/* left */}
      <WrapAutoScroll
        style={{
          padding: '224px 32px 16px 32px',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
          width: '50%',
        }}
      >
        <h3 style={{ ...lightTheme.typography.Header16bold }}>{t('Quick tutorial')}</h3>
        <h2 style={{ margin: '12px 0 0 0', ...lightTheme.typography.Title34Bold }}>{t("Remove unwanted objects from a photo")}</h2>
        {/* <h4
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            margin: '4px 0 0 0',
            ...lightTheme.typography.Small14Semibold,
            color: '#000',
          }}
        >
          {t('Remove people and objects from your photos')}
        </h4> */}
        <ButtonCustom
            type={SizeButton.LARGE}
            kind={KIND.primary}
            style={{
              position: 'relative',
              ...lightTheme.typography.Small14Semibold,
              color: '#fff',
              width: '165px',
              marginTop: '32px',
            }}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              const isFromCreatePage = location.pathname === '/create'
              // No handlers is initialized yet when user is in create page
              if(!isFromCreatePage) {
                handleCurrentState(editor.handlers, isFromCreatePage)
              }
              dispatch(setCallbackFromCreationPage({
                action: ActionType.QUICK_TUTORIAL
              }))
              setIsOpenTutorial(true)
              setShowedRemoveObjectTutorial()
              location.pathname === '/' ? history.replace('/') : history.push('/')
              setShowModalType(null)
              customAmplitude('Tutorial Step Completed', { 'Tutorial Step Number' : 0 })
            }}
          >
            {t('Show me')}
          </ButtonCustom>
          <ButtonCustom
            type={SizeButton.STANDARD}
            kind={KIND.secondary}
            style={{
              position: 'relative',
              ...lightTheme.typography.Small14Semibold,
              color: '#fff',
              minWidth: '110px',
              width: 'fit-content',
              marginTop: 'auto',
              padding: '12px',
              border: 'none',
              background: 'transparent',
            }}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              setShowedRemoveObjectTutorial()
              setShowModalType(null)
            }}
          >
            <p style={{...lightTheme.typography.LabelLarge, color: '#666', letterSpacing: '-0.1%'}}>{t('Maybe later')}</p>
          </ButtonCustom>
      </WrapAutoScroll>
      {/* right */}
      <div
        style={{
          width: '50%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          background: 'rgba(249, 249, 249, 1)',
          padding: '32px'
        }}
      >
        <video
          width="100%"
          height="100%"
          style={{
            objectFit: 'cover',
            borderRadius: '16px',
          }}
          loop
          autoPlay
          muted
          src={VideoRemoveTutorial}
        >
            <source src={VideoRemoveTutorial} type='video/mp4; codecs=hevc"' />
          <source src={VideoRemoveTutorial} type='video/mp4; codecs=hevc"' />
        </video>
      </div>
    </div>
  )
}

export default ModalTutorial