import React, { useEffect, useState, useRef } from 'react'
import HeadingInspector from '@/components/HeadingInspector'
// import Search from '@/components/icons/Search'
import { lightTheme } from '@/customTheme'
import { ThemeProvider } from 'baseui'
import PackContainer from '@/PackContainer'
import { useSelector } from 'react-redux'
import { selectBackgroundPacks } from '@/store/slices/backgroundPacks/selectors'
import { useEditorContext } from '@/scenes/engine'
import AutoScroll from '@/components/AutoScroll'
import { selectUser, selectUserIsPremium } from '@/store/slices/user/selectors'
import { useTranslation } from 'react-i18next'

function Fill({
  isOpen,
  setIsOpenFill,
  bgActiveID,
  handleSelectBgImage,
}: {
  isOpen: boolean
  setIsOpenFill: Function
  bgActiveID: string
  handleSelectBgImage: Function
}) {
  const backgroundPacks = useSelector(selectBackgroundPacks)
  const user = useSelector(selectUser)
  const { editor, activeObject, canvas } = useEditorContext()
  const [isBoxShadow, setIsBoxShadow] = useState(false)
  const { t } = useTranslation()

  //
  const [defaultSection, setDefaultSection] = useState(10)
  const listBackground = useRef(null)
  const hasPremium = useSelector(selectUserIsPremium)

  // useEffect(() => {
  //   if (editor) {
  //     if (activeObject && activeObject.type === ObjectType.BAZAART_STICKER) {
  //       // set background active for STICKER
  //       // setBgActiveID(() => ....)
  //     }
  //     if (!activeObject && editor.handlers.frameHandler.getBackgroundImage()) {
  //       const currentBackgroundImageJSON = editor.handlers.frameHandler.getBackgroundImage().toJSON()

  //       // @ts-ignore
  //       // setBgActiveID(() => currentBackgroundImageJSON.id)
  //     }
  //   }
  // }, [editor, isOpen, activeObject])

  const renderRow = ({ index, style }) => {
    return (
      <PackContainer
        style={{
          ...style,
          paddingTop: '0',
          height: 'fit-content',
          boxSizing: 'border-box',
          marginTop: index === 0 ? '0' : null,
        }}
        pack={backgroundPacks[index]}
        onItemSelected={handleSelectBgImage}
        bgActiveID={bgActiveID}
        itemsPerLine={5}
        hasPremium={hasPremium || backgroundPacks[index].is_free}
      />
    )
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <div
        style={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          width: '340px',
          overflow: 'hidden',
          position: 'absolute',
          top: '0px',
          bottom: '16px',
          right: isOpen ? '0px' : '-356px',
          borderRadius: '12px',
          background: '#fff',
          zIndex: 1,
          // transition: 'all .4s ease-in',
          height: '100%',
        }}
      >
        <HeadingInspector
          hasNavigation={true}
          handleNavigation={() => setIsOpenFill(false)}
          hasBoxShadow={isBoxShadow}
          title={t("Fill")}
        ></HeadingInspector>

        <AutoScroll
          ref={listBackground}
          handleScroll={e => {
            if (e.target.scrollTop > 0) {
              setIsBoxShadow(true)
            } else {
              setIsBoxShadow(false)
            }
            if (
              Math.round(listBackground.current.scrollTop) + listBackground.current.clientHeight ===
              listBackground.current.scrollHeight
            ) {
              setDefaultSection(pre => pre + 10)
            }
          }}
        >
          {/* <div
            style={{
              boxSizing: 'border-box',
              position: 'fixed',
              width: '292px',
              background: '#fff',
              paddingBottom: '50px',
              zIndex: 1000,
            }}
          >
            <Search handleValueChange={() => {}} size="large" />
          </div> */}

          <>
            {backgroundPacks.map((b, index) => {
              if (index < defaultSection) {
                return <div key={index}>{renderRow({ index, style: {} })}</div>
              }
              return null
            })}
          </>
        </AutoScroll>
        {/* </div> */}
        {/* <div
          style={{
            boxSizing: 'border-box',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '88px',
            borderTop: '1px solid rgba(229, 229, 229, 1)',
            background: 'white',
            padding: '19px 24px 20px',
          }}
        >
          <ButtonCustom
            kind={KIND.primary}
            type={SizeButton.LARGE}
            onClick={() => {}}
            style={{ color: 'white' }}
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '6px' }}>
              <UploadsIcon size={24} />
              <p style={{ fontWeight: 600, fontSize: '14px' }}>{'Upload'}</p>
            </div>
          </ButtonCustom>
        </div> */}
      </div>
    </ThemeProvider>
  )
}

export default Fill
