import { fabric } from 'fabric'
import BaseHandler from './BaseHandler'
import { HandlerOptions } from '../common/interfaces'
import { FrameOptions } from '../objects'
import { loadImageFromURL } from '../utils/image-loader'
import { nanoid } from 'nanoid'
import { ObjectType } from '../common/constants'
import { MediaImageRepository } from '../objects/media-repository/media_image_repository'
import { MediaImageType } from '@scenes/engine/objects/media-repository/media_image_type'
import { debounce } from 'lodash'
import store from '@/store/store'
import { ActionType } from '@/store/slices/editor/reducer'
import { findSizeId } from '@scenes/Editor/components/Navbar/components/Resize'
import { CanvasBlur } from '../objects/CanvasBlur.filter'
import { lightTheme } from '@/customTheme'
import Icons from '@/scenes/Editor/components/Icons'

class FrameHandler extends BaseHandler {
  frame
  options
  sizeFormat
  backgroundimage

  FRAME_PADDING_ADD_MENU = 88
  FRAME_PADDING_INSPECTOR = 356
  FRAME_PADDING_W = 144 //120; its not 0 becasue we want to hold place for selection border line
  FRAME_PADDING_H = 144 //120; its not 0 becasue we want to hold place for selection border line
  BASE_FRAME_WIDTH = 2048 // base frame width to calculate the ratio
  BASE_FRAME_HEIGHT = 2048 // base frame height to calculate the ratio

  baseFrameWidth
  baseFrameHeight
  baseFrameCenterX
  baseFrameCenterY
  originalObjectsStates = []

  constructor(props: HandlerOptions) {
    super(props)
    // this.options = defaultFrameOptions
    this.initialize()
  }

  initialize() {
    const callbackFromCreationPage = store.getState().editor.editor.callbackFromCreationPage
    let frame_width = 2048
    let frame_height = 2048
    let frame_fill = 'transparent'
    let frame_strokeWidth = 0
    let sizeId
    let unit
    let icon

    if(callbackFromCreationPage){
      const action = callbackFromCreationPage.action
      if(action === ActionType.CUSTOM_SIZE && callbackFromCreationPage.value){
        frame_width = callbackFromCreationPage.value.width
        frame_height = callbackFromCreationPage.value.height
        sizeId = callbackFromCreationPage.value.sizeId
        unit = callbackFromCreationPage.value.unit
        icon = callbackFromCreationPage.value.icon
      } else if (action === ActionType.REMOVE_OBJ || action === ActionType.ADD_IMAGE || action === ActionType.MAGIC_BACKGROUND || action === ActionType.REMOVE_BG) {
        const size = store.getState().editor.uploads.uploads[0].size
        frame_width = size.width
        frame_height = size.height
      }
    }
    const frame = new fabric.Frame({
      width: frame_width,
      height: frame_height,
      id: '',
      name: 'Initial Frame',
      fill: frame_fill,
      hoverCursor: 'default',
      absolutePositioned: this.config.clipToFrame, // for clipPath
      // rx: 16,
      // ry: 16,
      strokeWidth: frame_strokeWidth,
      stroke: 'rgba(0, 0, 0, 0.15)',
      // @ts-ignore
      sizeId: sizeId === 'custom-size' ? 'custom-size' : findSizeId(frame_width, frame_height),
      unit: unit ? unit : 'px',
      icon: icon ? icon : null,
    })
    this.canvas.add(frame)
    frame.center()
    const interval = setInterval(() => {
      if (this.root.scrollbarHandler && this.root.scrollbarHandler.updateScrollPosition) {
        this.root.zoomHandler.zoomToFit()
        this.root.scrollbarHandler.updateScrollPosition()
        frame.set({
          fill: "#fff",
          strokeWidth: 0.5 / this.canvas.getZoom(),
          // rx: 16 / this.getFitRatio(),
          // ry: 16 / this.getFitRatio(),
        })
        clearInterval(interval)
      }
    }, 100)
  }

  get = () => {
    return this.canvas.getObjects().find(object => object.type === ObjectType.FRAME)
  }

  update = (options, update = true, changeBg = false) => {
    // this.sizeFormat = options
    if (update) {
      this.root.transactionHandler.save()
    }
    const frame = this.get() as fabric.Frame & {
      sizeId?: string,
      icon: any,
      unit: string
    }
    const newFrameWidth = options.width
    const newFrameHeight = options.height
    let hasSizeId = findSizeId(newFrameWidth, newFrameHeight)

    frame.set('width', newFrameWidth)
    frame.set('height', newFrameHeight)
    if (options.sizeId) {
      frame.set('sizeId', options.sizeId)
      if(options.icon) {
        frame.set('icon', options.icon)
      }
    } else if(hasSizeId) {
      let { sizeId, icon } = findSizeId(newFrameWidth, newFrameHeight, true)
      frame.set({
        sizeId: sizeId,
        icon: icon ? icon : null
      })
    } else {
      frame.set({
        sizeId: 'custom-size',
        icon: Icons.CustomSize
      })
    }
    if(update) {
      if(options.icon) {
        frame.set('icon', options.icon)
        frame.set('unit', options.unit)
      } else {
        frame.set('icon', null)
        frame.set('unit', 'px')
      }
    }

    if(!changeBg) {
      this.root.zoomHandler.zoomToFit()
    }

    frame.set({
      rx: 45 * Math.min(frame.width, frame.height) / this.BASE_FRAME_WIDTH,
      ry: 45 * Math.min(frame.width, frame.height) / this.BASE_FRAME_HEIGHT,
      strokeWidth: 0.5 / this.canvas.getZoom(),
    })
    frame.center()
    if (update) {
      this.adjustLayers(newFrameWidth, newFrameHeight)
    }
    this.scaleImageBgFill()
    this.root.scrollbarHandler.handleFrameSizeChange()
    this.canvas.requestRenderAll()
    this.editorEventManager.emit('frame:changed')
  }

  updateCanvasWithFrame = async (canvas) => {
    const currentFrame = this.get();
    const previousFrame = Object.assign({}, currentFrame);
    this.updateFrameSize(previousFrame);
    const { variationLeft, variationTop } = this.calculatePositionVariation(currentFrame, previousFrame);
    this.updateObjectsOnCanvas(canvas, currentFrame, variationLeft, variationTop);
    this.scaleImageBgFill();
    canvas.requestRenderAll();
  }

  updateFrameSize = async (previousFrame) => {
    await this.update({ width: previousFrame.width, height: previousFrame.height }, false);
  }

  calculatePositionVariation = (currentFrame, previousFrame) => {
    const variationLeft = previousFrame.left - currentFrame.left;
    const variationTop = previousFrame.top - currentFrame.top;
    return { variationLeft, variationTop };
  }


  updateObjectsOnCanvas = (canvas, currentFrame, variationLeft, variationTop) => {
    if (variationLeft === 0 && variationTop === 0) {
      return
    }

    canvas.forEachObject((obj) => {
      if (obj.type !== ObjectType.FRAME) {
        obj.clipPath = currentFrame;
      }
      if (obj.type === ObjectType.FRAME) {
        return obj;
      }
      obj.left -= variationLeft;
      obj.top -= variationTop;
      obj.setCoords();
      return obj;
    });
  }

  setBackgroundColor = (color: string, updateTransaction = true) => {
    // const guid = nanoid()
    // update ? this.root.transactionHandler.save() : this.editorEventManager.emit('background:changed', {})
    // this.unsetBackgroundImage()
    // const frame = this.get()
    // frame.set('fill', color)
    // this.canvas.renderAll()

    const abortController = new AbortController()
    this.createColorImage(color,100,100).then((colorImage)=>{
      this.setBackgroundImageURL({id:color, store:"color"}, new URL(colorImage), new URL(colorImage), abortController.signal , updateTransaction)
    })
  }

  setBackgroundImageURL = async (
    backgroundObject: any,
    resizeLatestURL: URL,
    resizeThumbnailURL: URL,
    abortSignal: any,
    updateTransaction: boolean = true
  ) => {
    if(updateTransaction) {
      this.root.transactionHandler.save()
    }
    const guid = backgroundObject.bazaartId ? backgroundObject.bazaartId : nanoid()
    const assetStateId = backgroundObject.layerAssetStateId ? backgroundObject.layerAssetStateId : nanoid()
    // this.selectedBackgroundImageGuid = guid
    await MediaImageRepository.getInstance().storeImageUrl(
      guid,
      assetStateId,
      MediaImageType.thumbnail,
      resizeThumbnailURL,
      abortSignal
    )
    this.setBackgroundImage(guid, assetStateId, MediaImageType.thumbnail, backgroundObject,abortSignal)
    await MediaImageRepository.getInstance().storeImageUrl(
      guid,
      assetStateId,
      MediaImageType.latest,
      resizeLatestURL,
      abortSignal
    )
    this.setBackgroundImage(guid, assetStateId, MediaImageType.latest, backgroundObject,abortSignal)
  }

  setTransparentBg = async (update = true) => {
    const guid = nanoid()
    if (update) {
      this.root.transactionHandler.save()
    }
    // const image: any = await loadImageFromURL(Transparent)
    // let resize = image
    // if(image.width > this.get().width || image.height > this.get().height) {
    //   const ratio = this.get().width / (image.width )
    //   resize = await new MediaImageRepositoryProcessing().resize(image, ratio, ratio)
    // }
    
    this.unsetBackgroundImage()
    const frame = this.get()
    const currentZoom = this.canvas.getZoom()
    const minPatternSize = 11; // Minimum square size to maintain visibility
    const maxPatternSize = 17; // Maximum square size to prevent overly large patterns
    const baseHeight = this.baseFrameHeight ? this.baseFrameHeight : frame.height
    const baseWidth = this.baseFrameWidth ? this.baseFrameWidth : frame.width
    const scaleX = frame.width / baseWidth
    const scaleY = frame.height / baseHeight
    const scaleFactor = Math.max(scaleX, scaleY)
    const newTransparent = function() {
      const chessStatic = new fabric.StaticCanvas(null);
      const baseSize = 30
      let dynamicBaseSize = baseSize * scaleFactor * currentZoom;

      dynamicBaseSize = Math.max(minPatternSize, Math.min(maxPatternSize, dynamicBaseSize));
      chessStatic.setHeight(dynamicBaseSize * 2);
      chessStatic.setWidth(dynamicBaseSize * 2);
      chessStatic.renderAll();
      chessStatic.setBackgroundColor('white', () => {});
      const greyRect1 = new fabric.Rect({
        width: dynamicBaseSize,
        height: dynamicBaseSize,
        left: 0,
        top: 0,
        strokeWidth: 0
      });
      greyRect1.set('fill', lightTheme.colors.grayScale150)
      const greyRect2 = new fabric.Rect({
        width: dynamicBaseSize,
        height: dynamicBaseSize,
        left: dynamicBaseSize,
        top: dynamicBaseSize,
        strokeWidth: 0
      });
      greyRect2.set('fill', lightTheme.colors.grayScale150)
      chessStatic.add(greyRect1);
      chessStatic.add(greyRect2);
      
      chessStatic.calcOffset();
      chessStatic.renderAll();

      const canvasDataUrl = chessStatic.toDataURL({quality: 1})
      const imageElement = new Image()
      imageElement.src = canvasDataUrl
      const transparentPattern = new fabric.Pattern({
        source: imageElement,
        repeat: 'repeat',
        patternTransform: [dynamicBaseSize === maxPatternSize ? 1 : 1 / currentZoom, 0, 0, dynamicBaseSize === maxPatternSize ? 1 : 1 / currentZoom, 0, 0]
      });
      return transparentPattern;
   }
    frame.set('fill', newTransparent())
    this.canvas.requestRenderAll()

  }

  async setBackgroundImage(guidId, assetStateId, mediaImageType: MediaImageType, backgroundObject, abortSignal?: any) {
    let image: any = null
    const frame = this.get()
    const oldFrame = Object.assign({}, frame)
    let imageUrl = await MediaImageRepository.getInstance().getImage(guidId, assetStateId, mediaImageType)
    if (!imageUrl || abortSignal.aborted) {
      return
    }
    image = await loadImageFromURL(imageUrl)
    const item = {
      type: ObjectType.BAZAART_BG,
      centerPoint: {
        x: 0.5,
        y: 0.5,
      },
      sizeOnCanvas: {
        width: 0.2,
      },
      transformation: {
        horizontalFlip: false,
        verticalFlip: false,
      },
      boundingBox: { y: 0, width: 1, height: 1, x: 0 },
      absoluteRotation: 0,
      bazaartGuid: guidId,
      version: 33,
    }
    const options = this.root.frameHandler.getOptions()
    const topOpt = options.top + options.height * item.centerPoint.y
    const leftOpt = options.left + options.width * item.centerPoint.x
    const baseOptions = {
      angle: 0,
      top: topOpt,
      left: leftOpt,
      width: image.width,
      height: image.height,
      originX: 'center',
      originY: 'center',
      scaleX: options.width / image.width,
      scaleY: options.height / image.height,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      metadata: {},
      selectable: false,
      hasControls: false,
      lockMovementY: true,
      lockMovementX: true,
      strokeWidth: 0,
      padding: 0,
      evented: false,
      type: item.type,
      bazaartGuid: item.bazaartGuid,
      layerAssetStateId: assetStateId,
      id: item.bazaartGuid,
      backgrondIdentifier: backgroundObject.id === 'transparent' ? backgroundObject.id : `${backgroundObject.id}_${backgroundObject.store}`,
      version: item.version,
      cropX: 0,
      cropY: 0,
      boundingBox: item.boundingBox,
      effects: {}
    }

    const element = new fabric.StaticImage(image, {
      ...baseOptions,
    })
    let object: fabric.Object = element as any
    // await this.update({ width: oldFrame.width, height: oldFrame.height }, false, true) 
    const variationLeft = oldFrame.left - frame.left
    const variationTop = oldFrame.top - frame.top
    this.unsetBackgroundImage()
    this.canvas.add(object)
    this.editorEventManager.emit('background:changed', {})
    object.clipPath = frame

    this.updateObjectsOnCanvas(this.canvas, frame, variationLeft, variationTop)
    this.scaleImageBgFill()
    this.canvas.moveTo(object, 1)
  }


  async setPlaceholderBgImage(image: HTMLImageElement){
    const frame = this.get()
    const item = {
      type: ObjectType.BAZAART_BG,
      centerPoint: {
        x: 0.5,
        y: 0.5,
      },
      sizeOnCanvas: {
        width: 0.2,
      },
      transformation: {
        horizontalFlip: false,
        verticalFlip: false,
      },
      boundingBox: { y: 0, width: 1, height: 1, x: 0 },
      absoluteRotation: 0,
      version: 33,
    }
    const options = this.root.frameHandler.getOptions()
    const topOpt = options.top + options.height * item.centerPoint.y
    const leftOpt = options.left + options.width * item.centerPoint.x
    const baseOptions = {
      angle: 0,
      top: topOpt,
      left: leftOpt,
      width: image.width,
      height: image.height,
      originX: 'center',
      originY: 'center',
      scaleX: options.width / image.width,
      scaleY: options.height / image.height,
      flipX: false,
      flipY: false,
      skewX: 0,
      skewY: 0,
      metadata: {},
      selectable: false,
      hasControls: false,
      lockMovementY: true,
      lockMovementX: true,
      strokeWidth: 0,
      padding: 0,
      evented: false,
      type: item.type,
      version: item.version,
      cropX: 0,
      cropY: 0,
      boundingBox: item.boundingBox,
      effects: {},
      id:"bg-place-holder"
    }

    let blurredImage = this.applyBlurToImage(image);
    const element = new fabric.StaticImage(blurredImage, {
      ...baseOptions,
    })
    
    // let blurFitler = new BlurFilter(50, 20);
    // blurFitler.repeatEdgePixels = true;
    // blurFitler.multisample = MSAA_QUALITY.HIGH;
    // let filters = [new BasePixiFilter(blurFitler, new Size(0, 0), new Inset(1, 1, 1, 1))]
    // element.applyFilters(filters);

    
    let object: fabric.Object = element as any
    this.unsetBackgroundImage()
    this.canvas.add(object)
    object.clipPath = frame

    this.scaleImageBgFill()
    this.canvas.moveTo(object, 1)
  }

  removePlaceholderBgImage = () => {
    return new Promise(async resolve => {
      // @ts-ignore
      const currentPlceholder = this.canvas.getObjects().find(object => object.id === "bg-place-holder")

      if (currentPlceholder) {
        this.canvas.remove(currentPlceholder)
        resolve(null)
      } else {
        resolve(null)
      }
    })
  }

  private applyBlurToImage(image: HTMLImageElement): HTMLImageElement {
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');

    let blurSize = 20;

    canvas.width = image.width;
    canvas.height = image.height;

    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';

    let blurFilter = `blur(${blurSize}px)`;
    ctx.filter = blurFilter;

    ctx.drawImage(image, 0, 0);

    if (this.canvasBlurNotSupported()) {
      CanvasBlur(ctx, blurSize);
    }

    let blurredImg = new Image();
    blurredImg.src = canvas.toDataURL();
    return blurredImg;
  }

  private canvasBlurNotSupported(): Boolean {
    let userAgent = navigator.userAgent;
    // Check for Safari
    let isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
    // Safari could also be detected by checking the vendor
    let isAppleVendor = /Apple Computer/.test(navigator.vendor);
    // Combine both checks for a more reliable detection
    return (isSafari && isAppleVendor);
  }
  
  public scaleImageBgFill() {
    const currentBackgroundImage = this.getBackgroundImage() as any
    const { width, height } = this.root.frameHandler.getOptions()
    if (currentBackgroundImage) {
      let scaleX = width / currentBackgroundImage.width
      let scaleY = height / currentBackgroundImage.height
      const scaleMax = Math.max(scaleX, scaleY)
      currentBackgroundImage.set({
        scaleY: scaleMax,
        scaleX: scaleMax,
        _originalScaleX: scaleMax,
        _originalScaleY: scaleMax
      })
      
      currentBackgroundImage.center()
    }
  }

  unsetBackgroundImage(): Promise<fabric.StaticImage | null> {
    return new Promise(async resolve => {
      const currentBackgroundImage = this.getBackgroundImage()

      if (currentBackgroundImage) {
        this.canvas.remove(currentBackgroundImage)
        resolve(null)
      } else {
        resolve(null)
      }
    })
  }

  sendToBack = (id?: string) => {
    let refObject = this.canvas.getActiveObject()
    const objects = this.canvas.getObjects()
    const backgroundImage = objects.find(o => o.type === ObjectType.BAZAART_BG)
    if (id) {
      refObject = this.findOneById(id)
    }
    // const indexx =
    if (refObject) {
      if (backgroundImage) {
        refObject.moveTo(3)
      } else {
        refObject.moveTo(2)
      }
    }
  }

  public findOneById = (id: string) => {
    const objects = this.findById(id)
    return objects[0]
  }

  public findById = (id: string) => {
    const objects = this.canvas.getObjects()
    const object = this.findByIdInObjecs(id, objects)
    return [object]
  }

  public findByIdInObjecs = (id: string, objects: fabric.Object[]): any => {
    let item = null

    for (const object of objects) {
      // @ts-ignore
      if (object.id === id) {
        item = object
        break
      }
      if (object.type === 'group') {
        // @ts-ignore
        const itemInGroup = this.findByIdInObjecs(id, object._objects)
        if (itemInGroup) {
          item = itemInGroup
          break
        }
      }
    }
    return item
  }

  create = _options => {
    const frame = new fabric.Frame({
      id: '',
      name: 'Initial Frame',
      fill: '#ffffff',
      hoverCursor: 'default',
      ...this.options,
    })
    this.canvas.add(frame)
    frame.center()
  }

  getBackgroundImage = () => {
    return this.canvas.getObjects().find(object => object.type === ObjectType.BAZAART_BG)
  }

  reset = () => {
    // const frame = this.get()
    // frame.set('fill', defaultFrameOptions.fill)
  }

  setSelectionBorder = () => {
    // const frame = this.root.frameHandler.get()
    // frame.setSelectionBorder()
  }

  getOptions = (): FrameOptions => {
    const frame = this.get()
    // @ts-ignore
    return frame.toJSON(this.root.propertiesToInclude)
  }

  scaleDimension = options => {
    const { width, height } = options
    return {
      height: height,
      width: width,
    }
  }

  getFitRatio = () => {
    if(window.innerHeight < 700) {
      this.FRAME_PADDING_W = 64
      this.FRAME_PADDING_H = 64
    } else {
      this.FRAME_PADDING_W = 144
      this.FRAME_PADDING_H = 144
    }
    const canvasWidth = window.innerWidth - this.FRAME_PADDING_W - this.FRAME_PADDING_ADD_MENU - this.FRAME_PADDING_INSPECTOR
    const canvasHeight = window.innerHeight - this.FRAME_PADDING_H - 64
    const options = this.getOptions()
    let scaleX = canvasWidth / options.width
    let scaleY = canvasHeight / options.height
    if (options.height >= options.width) {
      scaleX = scaleY
      if (canvasWidth < options.width * scaleX) {
        scaleX = scaleX * (canvasWidth / (options.width * scaleX))
      }
    } else {
      if (canvasHeight < options.height * scaleX) {
        scaleX = scaleX * (canvasHeight / (options.height * scaleX))
      }
    }
    return scaleX
  }

  public setOriginalObjectsState = () => {
    const frame = this.get()
    this.baseFrameWidth = frame.width
    this.baseFrameHeight = frame.height
    this.baseFrameCenterX = frame.left + frame.width / 2
    this.baseFrameCenterY = frame.top + frame.height / 2
    this.originalObjectsStates = this.canvas.getObjects().map((object: any) => ({
      id: object.id,
      left: object.left,
      top: object.top,
      scaleX: object.scaleX,
      scaleY: object.scaleY,
      type: object.type,
    }))
  }

  public adjustLayers = (newFrameWidth, newFrameHeight) => {
    const frameOptions = this.getOptions()
    const newFrameCenterX = frameOptions.left + newFrameWidth / 2
    const newFrameCenterY = frameOptions.top + newFrameHeight / 2
    const scaleX = newFrameWidth / this.baseFrameWidth
    const scaleY = newFrameHeight / this.baseFrameHeight
    const scaleFactorMin = Math.min(scaleX, scaleY)
    const scaleFactorMax = Math.max(scaleX, scaleY)

    this.root.canvasHandler.canvas.forEachObject((object: any) => {
      if (object.type === ObjectType.FRAME) {
        return
      }
      const originalObject = this.originalObjectsStates.find(item => item.id === object.id)
      if (!originalObject) {
        return
      }
      object.clipPath = this.get()
      const deltaX = originalObject.left - this.baseFrameCenterX
      const deltaY = originalObject.top - this.baseFrameCenterY

      object.left = newFrameCenterX + deltaX * scaleFactorMin
      object.top = newFrameCenterY + deltaY * scaleFactorMin

      switch (object.type) {
        case ObjectType.BAZAART_OVERLAY:
          object.scaleX = originalObject.scaleX * scaleFactorMax
          object.scaleY = originalObject.scaleY * scaleFactorMax
          object._originalScaleX *= scaleFactorMax
          object._originalScaleY *= scaleFactorMax
          break;
        default:
          object.scaleX = originalObject.scaleX * scaleFactorMin
          object.scaleY = originalObject.scaleY * scaleFactorMin
          object._originalScaleX *= scaleFactorMin
          object._originalScaleY *= scaleFactorMin
          break;
      }
    
      object.setCoords()
    })
  }

  setImageLatest = async (object, canvas) => {
    let image = null;
    let imageUrl = await MediaImageRepository.getInstance().getImage(object.bazaartGuid, object.layerAssetStateId, MediaImageType.latest);

    if (!imageUrl) {
      return
    }

    image = await loadImageFromURL(imageUrl);
    this.setImageProperties(object, image, canvas);
  }

  setImageProperties = async (object, image, canvas) => {
    await object.setElement(image);
    object.set({
      width: image.width,
      height: image.height,
    });
    this.scaleImageBgFill();
    canvas.renderAll();
  }

  setBackgroundIdentifier(object) {
    if (!object.backgrondIdentifier) {
      return
    }
    let splitBackgroundIdentifier = object.backgrondIdentifier.split("_");
    splitBackgroundIdentifier[1] === 'color' && splitBackgroundIdentifier[0] !== "" ? this.setBackgroundColor(splitBackgroundIdentifier[0], false)
      : this.editorEventManager.emit('background:changed', {})
  }

  public updateLayersCoords = debounce(() => {
    this.editorEventManager.emit('scroll:changed')
    this.canvas.forEachObject(obj => obj.setCoords())
  }, 100)

  private async createColorImage(color: string, width: number, height: number): Promise<string> {
    // Create a new canvas element
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    // Get the drawing context
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        throw new Error('Unable to get canvas context');
    }

    // Set the fill color and create a rectangle
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, width, height);

    const blobUrl = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.canvasToBlobUrl(canvas);
    return blobUrl
  }

}

export default FrameHandler