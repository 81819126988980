import { PopoverType, panelListItems } from '@/constants/app-options'
import PanelListItem from '../../Panels/PanelListItem'
import useAppContext from '@/hooks/useAppContext'
import Templates from '../../Panels/PanelItems/Templates'
import { selectUserIsPremium } from '@/store/slices/user/selectors'
import { useSelector } from 'react-redux'
import { setIntroVideoDetail, setOpenModalTryPremium } from '@/store/slices/user/actions'
import { useAppDispatch } from '@/store/store'
import { useState } from 'react'

function AddTemplates() {
  const { setPopoverActive, popoverActive } = useAppContext()

  const panelListItem = panelListItems.find(x => x.name === 'Templates')

  const { activePanel } = useAppContext()
  const hasPremium = useSelector(selectUserIsPremium)
  const dispatch = useAppDispatch()
  const [isDisplayedIntro, setIsDisplayedIntro] = useState(false)

  return (
    <div>
      <div
        onClick={() => {
          if (popoverActive === PopoverType.TEMPLATES) {
            setPopoverActive(null)
          } else {
            setPopoverActive(PopoverType.TEMPLATES)
            // if (!hasPremium) {
            //   dispatch(
            //     setIntroVideoDetail({
            //       isOpen: true,
            //       title: 'Design Like a Pro',
            //       subtitle: 'Pick a template and start creating.',
            //       videoSrc: '@assets/video/intro-template.mp4',
            //       poster: '',
            //       onClickButton: () => {
            //         dispatch(
            //           setOpenModalTryPremium({
            //             isOpen: true,
            //             source: '',
            //             callback: () => {
            //               setPopoverActive(PopoverType.TEMPLATES)
            //               setIsDisplayedIntro(true)
            //             },
            //           })
            //         )
            //       },
            //     })
            //   )
            //   customAmplitude('Intro Prompt', {
            //     Feature: 'bazaart.intro.templates ',
            //   })
            // } else {
            //   if (!isDisplayedIntro) {
            //     dispatch(
            //       setIntroVideoDetail({
            //         isOpen: true,
            //         title: 'Design Like a Pro',
            //         subtitle: 'Pick a template and start creating.',
            //         videoSrc: '@assets/video/intro-template.mp4',
            //         poster: '',
            //         onClickButton: () => {
            //           setPopoverActive(PopoverType.TEMPLATES)
            //           setIsDisplayedIntro(true)
            //         },
            //       })
            //     )
            //     customAmplitude('Intro Prompt', {
            //       Feature: 'bazaart.intro.templates ',
            //     })
            //   } else {
            //     setPopoverActive(PopoverType.TEMPLATES)
            //   }
            // }
          }
        }}
        id="popover-template"
      >
        <PanelListItem
          disable={panelListItem.disable}
          label={panelListItem.name}
          name={panelListItem.name}
          key={panelListItem.name}
          icon={panelListItem.name}
          activePanel={activePanel}
          panelSelected={popoverActive === PopoverType.TEMPLATES}
        />
      </div>
      <Templates isOpen={popoverActive === PopoverType.TEMPLATES} setIsOpen={() => setPopoverActive(null)} />
    </div>
  )
}

export default AddTemplates
