import { lightTheme } from '@/customTheme'
import { styled } from 'baseui'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useAppContext from '@/hooks/useAppContext'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { customAmplitude } from '@/utils/customAmplitude'
import ONBOARDING_1ST from '@assets/video/onboarding-1st.mp4'
import ONBOARDING_2ND from '@assets/video/onboarding-2nd.mp4'
import ONBOARDING_3RD from '@assets/video/onboarding-3rd.mp4'
import ButtonCustom from '../ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND } from 'baseui/button'
import { setLocalStorage } from '@/utils/localStorage'

const WrapAutoScroll = styled('div', (props: any) => ({
  boxSizing: 'border-box',
  height: '100%',
  overflowY: 'scroll',
  overflowX: 'hidden',
  '::-webkit-scrollbar': {
    width: '8px',
    margin: '20px 0',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: lightTheme.colors.grayScale100,
    minHeight: '100px ',
  },
  '::-webkit-scrollbar-thumb:hover': {
    cursor: 'pointer',
    backgroundColor: lightTheme.colors.grayScale150,
  },
  ...props.style,
}))

const DATA_ONBOARDING = [
  {
    title: 'Get your design superpowers',
    desc: 'Welcome to Bazaart!',
    videoSrc: ONBOARDING_1ST,
    step: 1
  },
  {
    title: 'Remove background from photos',
    desc: 'One click. Boom! 💥',
    videoSrc: ONBOARDING_2ND,
    step: 2
  },
  {
    title: 'Create studio-quality photos',
    desc: 'Magic Background',
    videoSrc: ONBOARDING_3RD,
    step: 3
  },
]

function Onboarding() {
  const { t } = useTranslation()
  const { showModalType, setShowModalType } = useAppContext()
  const [onboardingIndex, setOnboardingIndex] = useState(0)
  const videoRefs = useRef([null, null, null]) // Refs for all 3 videos

  useEffect(() => {
    setLocalStorage('showedOnboarding', true)
  }, [])

  useEffect(() => {
    videoRefs.current.forEach((videoRef, index) => {
      if (index === onboardingIndex) {
        videoRef.currentTime = 0 // Reset video to the beginning
        videoRef.play()
      } else {
        videoRef.pause() // Pause other videos
      }
    })
  }, [onboardingIndex])

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        maxWidth: '920px ',
        maxHeight: '652px',
        display: 'flex',
        flexDirection: 'row',
        background: 'rgba(255, 255, 255, 1)',
        borderRadius: '16px',
        overflow: 'hidden',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      {/* left */}
      <WrapAutoScroll
        style={{
          padding: '268px 48px 48px 48px',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
          width: '50%',
          position: 'relative',
        }}
      >
        <h3 style={{ ...lightTheme.typography.Header16bold, fontFamily: 'New-Hero' }}>{t(DATA_ONBOARDING[onboardingIndex].desc)}</h3>
        <h2 style={{ margin: '12px 0 0 0', ...lightTheme.typography.Title34Bold }}>
          {t(DATA_ONBOARDING[onboardingIndex].title)}
        </h2>

        {/* onboarding control */}
        <div
          style={{
            position: 'absolute',
            bottom: '120px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '40px',
            height: '8px',
            gap: '8px',
            display: 'flex',
          }}
        >
          {DATA_ONBOARDING.map((data, index) => {
            return (
              <div
                key={data.step}
                style={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  backgroundColor:
                    index === onboardingIndex ? lightTheme.colors.primary : lightTheme.colors.grayScale150,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setOnboardingIndex(index)
                  customAmplitude('Onboarding', { Step: index + 1 })
                }}
              ></div>
            )
          })}
        </div>
        {/*  */}
        <ButtonCustom
          type={SizeButton.LARGE}
          kind={KIND.primary}
          style={{
            position: 'relative',
            ...lightTheme.typography.Small14Semibold,
            color: '#fff',
            marginBottom: 0,
            marginTop: 'auto',
          }}
          onClick={e => {
            customAmplitude('Onboarding', { Step: onboardingIndex + 1 })
            if(onboardingIndex === 2) {
              setShowModalType(null)
            } else {
              setOnboardingIndex(pre => pre + 1)
            }
          }}
        >
          {t('Next')}
        </ButtonCustom>
      </WrapAutoScroll>
      {/* right */}
      <div
        style={{
          width: '50%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {
          onboardingIndex === 0 ? <div style={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            width: '50px',
            height: '24px',
            padding: '0px 8px',
            ...lightTheme.typography.Small12medium,
            color: lightTheme.colors.white,
            background: 'rgba(0, 0, 0, 0.40)',
            borderRadius: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            zIndex: 1
          }} onClick={() => {
            customAmplitude('Onboarding - Skip')
            setShowModalType(null)
          }}>{t('Skip')}</div> : null
        }
        {DATA_ONBOARDING.map((data, index) => (
          <video
            key={index}
            ref={el => (videoRefs.current[index] = el)}
            width="100%"
            height="100%"
            style={{
              objectFit: 'cover',
              position: 'absolute',
              top: 0,
              left: 0,
              opacity: index === onboardingIndex ? 1 : 0, // Smoothly transition between videos
              transition: 'opacity 0.5s ease-in-out',
            }}
            loop
            autoPlay
            muted
            src={data.videoSrc}
          >
          </video>
        ))}
      </div>
    </div>
  )
}

export default Onboarding
