import { useEffect, useState } from 'react'
import { useEditorContext } from '@/scenes/engine'
import { useSelector } from 'react-redux'
import { selectBackgroundPacks } from '@/store/slices/backgroundPacks/selectors'
import PackContainer from '@/PackContainer'
import ColorsPackContainer from '@/ColorsPackContainer'
import { ThemeProvider, useStyletron } from 'baseui'
import { lightTheme } from '@/customTheme'
import { KIND } from 'baseui/button'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import Transparent from '@/components/icons/Transparent'
import Fill from './Fill'
import AutoScroll from '@/components/AutoScroll'
import { LabelLarge } from 'baseui/typography'
import HeadingInspector from '@/components/HeadingInspector'
import useAppContext from '@/hooks/useAppContext'
import { getResizeUrl } from '@/utils/getResizeUrl'
import { ObjectType } from '@/scenes/engine/common/constants'
import { debounce } from 'lodash'
import BtnOpenNewSlide from '@/components/BtnOpenNewSlide'
import Icons from '@/scenes/Editor/components/Icons'
import Resize, { findSizeId } from '@/scenes/Editor/components/Navbar/components/Resize'
import { setBackgoundActive } from '@/store/slices/templates/actions'
import { useAppDispatch } from '@store/store'
import Filter from '../../Toolbox/ToolboxItems/components/Filter'
import Adjust from '../../Toolbox/ToolboxItems/components/Adjust'
import GridFilter from '../../Toolbox/ToolboxItems/components/FilterBg'
import { selectImageElements } from '@/store/slices/imageElement/selectors'
import { selectUserIsPremium } from '@/store/slices/user/selectors'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'
import { convertUnitValue } from '@/components/ShowModal/ModalCustomSize'

function Background() {
  const dispatch = useAppDispatch()
  const backgroundPacks = useSelector(selectBackgroundPacks)

  const [isOpenFill, setIsOpenFill] = useState(false)
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const [firstOpenFill, setFirstOpenFill] = useState(false)
  const [preObj, setPreObj] = useState(null)
  const [isOpenAdjust, setIsOpenAdjust] = useState(false)
  const [isOpenResize, setIsOpenResize] = useState(false)
  // bg select
  const [bgActiveID, setBgActiveID] = useState(null)

  const [colorSelected, setColorSelected] = useState(null)
  const [isBoxShadow, setIsBoxShadow] = useState(false)
  const [activeBackground, setActiveBackground] = useState(null)
  const [filterActiveId, setFilterActiveId] = useState(null)
  const [resizeActiveItem, setResizeActiveItem] = useState(null)
  
  const [css] = useStyletron()

  const { editor, canvas } = useEditorContext()
  const { setHasBgImage, isLoadedJsonSuccess } = useAppContext()
  const hasPremium = useSelector(selectUserIsPremium)
  const [abortForAddImage, setAbortForAddImage] = useState(null)
  const debounceDelay = 300
  const debouncedLoadImage = debounce(
    backgroundObject => loadBackgroundImage(backgroundObject),
    debounceDelay
  )
  const debouncedLoadColor = debounce((color: string) => loadColor(color), debounceDelay)
  const debouncedLoadTransparent = debounce((color: string) => loadTransparent(), debounceDelay)
  const filterPacks = useSelector(selectImageElements)

  const handleChangesBg = () => {
    let bgImage = editor.handlers.frameHandler.getBackgroundImage()
    // setActiveBackground(null)

    
    if (bgImage) {
      setActiveBackground(bgImage)
      const currentBackgroundImageJSON = bgImage
      //@ts-ignore
      const backgrondIdentifier = currentBackgroundImageJSON.backgrondIdentifier
      if (backgrondIdentifier) {
        //@ts-ignore
        let backgrondIdentifierArray = currentBackgroundImageJSON.backgrondIdentifier.split('_')
        let backgrond_store_id = backgrondIdentifierArray[1]
        let backgrond_content_id = backgrondIdentifierArray[0]
        if(backgrond_store_id == "color") {
          setBgActiveID(null)
          setColorSelected(backgrond_content_id)
        } else {
          setBgActiveID(Number(backgrond_content_id))
          setColorSelected(null)
        }
      }
      else{
        // @ts-ignore
        setBgActiveID(Number(currentBackgroundImageJSON.id))
        setColorSelected(null)
      }
    }
     else {
      setBgActiveID(null)
      setColorSelected(editor.handlers.frameHandler.get()?.fill)
    }
  }

  const handleFrameSizeChange = () => {
    if(!editor) return
    const frame = editor.handlers.frameHandler.get()
    const sizeId = findSizeId(frame.width, frame.height)
    // @ts-ignore
    const { width, height } = convertUnitValue('px', frame.unit, {width: frame.width, height: frame.height})
    
    if(sizeId) {
      setResizeActiveItem({
        name: sizeId.split('_')[1],
        description: `${width} x ${height}`,
        size: {
          width: frame.width,
          height: frame.height,
        },
        // @ts-ignore
        icon: frame.icon,
        // @ts-ignore
        unit: frame.unit
      })
    } else {
      setResizeActiveItem({
        name: 'Custom size',
        icon: Icons.CustomSize,
        description: `${width} x ${height}`,
        size: {
          width: frame.width,
          height: frame.height,
        },
        // @ts-ignore
        unit: frame.unit
      })
    }
  }

  useEffect(() => {
    if (!editor) {
      return
    }

    if (preObj && preObj.id !== activeBackground.id) {
      setIsOpenFilter(false)
      setFirstOpenFill(false)
      setPreObj(activeBackground)
    } else {
      setPreObj(activeBackground)
    }
  }, [activeBackground])

  useEffect(() => {
    if (editor) {
      editor.on('history:changed', value => {
        //TODO: Filter image will reload after undo/redo so previews reload. Close Filter and Adjust for better UX. Check again after image do not need to reload
        if (value && value.afterUndo) {
          let bgImage = editor.handlers.frameHandler.getBackgroundImage()
          if (bgImage) {
            setActiveBackground(bgImage)
          }
          setIsOpenAdjust(false)
          setIsOpenFilter(false)
        }
        setTimeout(() => {
          handleChangesBg()
        }, 1)
        return
      })
      editor.on('background:changed', () => {
        setTimeout(() => {
          handleChangesBg()
        }, 1)
        return
      })
      
      handleChangesBg()
      return () => {
        editor.off('background:changed', handleChangesBg)
      }
    }
  }, [editor])

  useEffect(() => {
    if(isLoadedJsonSuccess) {
      handleFrameSizeChange()
    }
  }, [isLoadedJsonSuccess, editor])

  useEffect(() => {
    if (canvas) {
      canvas.on('mouse:up', e => {
        if (isOpenResize) {
          setIsOpenResize(false)
        }
      })
    }
  }, [isOpenResize, canvas])

  useEffect(() => {
    if (!isOpenResize) {
      return
    }
    editor.handlers.frameHandler.setOriginalObjectsState()
  }, [isOpenResize])

  // function
  const loadColor = async (color: string) => {
    if (bgActiveID !== null) {
      setBgActiveID(null)
    }
    editor.handlers.frameHandler.setBackgroundColor(color)

    setColorSelected(color)
    const eventProperties = {
      Tool: 'bazaart.add.background',
      Type: 'color',
      'Layer Type': ObjectType.BAZAART_BG,
    }
    customAmplitude('Selected tool', eventProperties)
  }

  const handleSelectBgColor = async (color: string) => {
    debouncedLoadColor(color)
  }

  const loadBackgroundImage = async backgroundObject => {
    if (backgroundObject && backgroundObject.id !== bgActiveID) {
      if (abortForAddImage) {
        abortForAddImage.abort()
      }
      let abortController = new AbortController()

      setAbortForAddImage(abortController)
      let resizeLatestURL = getResizeUrl({ size: '0x1600', url: backgroundObject.image.slice(8) })
      let resizeThumbnailURL = getResizeUrl({ size: '0x32', url: backgroundObject.thumbnail.slice(8) })
      await editor.handlers.frameHandler.setBackgroundImageURL(
        backgroundObject,
        resizeLatestURL,
        resizeThumbnailURL,
        abortController.signal
      )
      setHasBgImage(true)
      setColorSelected(null)
      dispatch(setBackgoundActive({ id: backgroundObject.id, category: backgroundObject.source_platform }))
      const eventProperties = {
        Tool: 'bazaart.add.background',
        Type: 'fill',
        'Layer Type': ObjectType.BAZAART_BG,
      }
      customAmplitude('Selected tool', eventProperties)
    }
  }

  const handleSelectBgImage = async backgroundObject => {
    debouncedLoadImage(backgroundObject)
  }

  const loadTransparent = async () => {
    await editor.handlers.frameHandler.setTransparentBg()
    setHasBgImage(false)
    setBgActiveID('Transparent')
    setColorSelected(null)

    const eventProperties = {
      Tool: 'bazaart.transparent',
    }
    customAmplitude('Selected tool', eventProperties)
  }

  const handleTransparentBg = async () => {
    if (bgActiveID === 'Transparent') {
      return
    }
    debouncedLoadTransparent()
  }

  const renderRow = ({ index, style }) => {
    if (index === 1) {
      return (
        <ColorsPackContainer
          style={{
            boxSizing: 'border-box',
            padding: '0',
            width: '300px',
          }}
          onItemSelected={handleSelectBgColor}
          colorSelected={colorSelected}
        ></ColorsPackContainer>
      )
    } else if (index === 2) {
      return (
        <>
          <PackContainer
            style={{
              padding: '0 ',
              height: 'fit-content',
              boxSizing: 'border-box',
              // marginTop: '50px',
            }}
            pack={backgroundPacks[index - 2]}
            onItemSelected={handleSelectBgImage}
            handleClickSubAction={() => setIsOpenFill(true)}
            bgActiveID={bgActiveID}
            itemsPerLine={5}
            hasPremium={true}
          />
          <div className="mt-50">
            <ButtonCustom
              kind={KIND.tertiary}
              type={SizeButton.LARGE}
              startEnhancer={<Transparent size={24} />}
              onClick={handleTransparentBg}
            >
              <LabelLarge>{t('Transparent')}</LabelLarge>
            </ButtonCustom>
          </div>
          {bgActiveID && bgActiveID !== 'Transparent' ? (
            <div>
              <GridFilter
                handleSelectItem={() => {}}
                actionToggle={() => {
                  let wrapFilterPanel = document.getElementById('wrap-filter-panel')
                  if (wrapFilterPanel) {
                    wrapFilterPanel.style.display = 'flex'
                    wrapFilterPanel.style.right = '0px'
                  } else {
                    setIsOpenFilter(true)
                    setFirstOpenFill(true)
                  }
                }}
                filterObject={activeBackground}
                filterActiveId={filterActiveId}
                setFilterActiveId={setFilterActiveId}
              />
              <BtnOpenNewSlide
                startEnhancer={<Icons.Adjust size={24} />}
                endEnhancer={<Icons.BackRight size={24} />}
                handleOnClick={() => setIsOpenAdjust(true)}
                nameAction={t('Adjust')}
               
              ></BtnOpenNewSlide>
              {(isOpenFilter || firstOpenFill) && preObj?.id === activeBackground.id ? (
                <Filter isOpen={isOpenFilter} setIsOpen={setIsOpenFilter} filterObject={activeBackground} filterActiveId={filterActiveId} setFilterActiveId={setFilterActiveId} />
              ) : null}
              <Adjust isOpen={isOpenAdjust} setIsOpenAdjust={setIsOpenAdjust} background={activeBackground} />
            </div>
          ) : null}

          {/* {bgActiveID && (
            <div className="">
              <FilterBg />
              <BtnOpenNewSlide
                startEnhancer={<Icons.Adjust size={24} />}
                endEnhancer={<Icons.BackRight size={24} />}
                handleOnClick={() => setIsOpenAdjust(true)}
                nameAction="Adjust"
                style={{
                  marginTop: '42px',
                }}
              ></BtnOpenNewSlide>
            </div>
          )} */}
          {/* <Adjust isOpen={isOpenAdjust} setIsOpenAdjust={setIsOpenAdjust} /> */}
        </>
      )
    }
    return null
  }

  useEffect(() => {
    if (canvas && isLoadedJsonSuccess) {
      let canvasObjs = canvas.getObjects()
      let numberObj = canvasObjs.length

      if (!numberObj) {
        setColorSelected('#ffffff')
      } else if (
        numberObj === 2 &&
        canvasObjs[0].type === ObjectType.FRAME &&
        canvasObjs[1].type === ObjectType.BAZAART_BG
      ) {
      }
    }
  }, [canvas, isLoadedJsonSuccess])

  const { t } = useTranslation()

  return (
    <ThemeProvider theme={lightTheme}>
      <div
        style={{
          position: 'relative',
          boxSizing: 'border-box',
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          width: '340px',
          overflow: 'hidden',
          boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.08)',
          borderRadius: '12px',
        }}
      >
        <HeadingInspector
          hasBoxShadow={isBoxShadow}
          hasNavigation={false}
          title={t('Canvas')}
        ></HeadingInspector>
        <AutoScroll
          handleScroll={e => {
            if (e.target.scrollTop > 0) {
              setIsBoxShadow(true)
            } else {
              setIsBoxShadow(false)
            }
          }}
          style={{ marginTop: 0 }}
        >
          {/* TODO */}
          <div
            style={{
              width: '100%',
              height: '26px',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
            }}
          >
            <span style={{ ...lightTheme.typography.Small14Semibold, color:lightTheme.colors.text.text_black_title }}>{t('Resize')}</span>
          </div>
          {resizeActiveItem ? (
            <div
              className={css({
                display: 'flex',
                alignItems: 'center',
                border: lightTheme.borders.borderDefault,
                borderRadius: '8px',
                height: '66px',
                cursor: 'pointer',
                position: 'relative',
                boxSizing: 'border-box',
                ':hover': {
                  background: lightTheme.colors.grayScale50,
                },
              })}
              onClick={e => {
                setIsOpenResize(true)
              }}
            >
              <>
                <div
                  style={{
                    padding: '8px',
                    width: '66px',
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: `${
                        (resizeActiveItem.name === 'Custom size' ? 50 : resizeActiveItem.size.width > resizeActiveItem.size.height
                          ? 50
                          : resizeActiveItem.size.width / resizeActiveItem.size.height) * 50
                      }px`,
                      height: `${
                        resizeActiveItem.name === 'Custom size' ? 50 : resizeActiveItem.size.height > resizeActiveItem.size.width
                          ? 50
                          : (resizeActiveItem.size.height / resizeActiveItem.size.width) * 50
                      }px`,
                      background: lightTheme.colors.grayScale100,
                      borderRadius: '4px',
                    }}
                  >
                    {resizeActiveItem.icon ? <resizeActiveItem.icon /> : null}
                  </div>
                </div>
                <span
                  style={{
                    position: 'absolute',
                    left: '66px',
                    top: '8px',
                    bottom: '9px',
                    borderRight: '1px solid rgba(229, 229, 229, 1)',
                  }}
                ></span>
                <div
                  style={{
                    width: '170px',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px 0 16px 8px',
                    gap: '2px',
                  }}
                >
                  {resizeActiveItem.name ? (
                    <p
                      style={{
                        margin: 0,
                        ...lightTheme.typography.Small11medium,
                        color: lightTheme.colors.contentPrimary,
                      }}
                    >
                      {t(resizeActiveItem.name)}
                    </p>
                  ) : null}
                  {resizeActiveItem.description ? (
                    <p
                      style={{
                        margin: 0,
                        ...lightTheme.typography.Small11medium,
                        color: lightTheme.colors.contentPrimary,
                      }}
                    >
                      {resizeActiveItem.description} {resizeActiveItem.unit ? resizeActiveItem.unit : t('px')}
                    </p>
                  ) : null}
                </div>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: '12px',
                }}>
                  <Icons.CustomArrow size={24} direction='right' />
                </div>
              </>
            </div>
          ) : null}
          {backgroundPacks.map((b, index) => (
            <div key={index}>{renderRow({ index, style: {} })}</div>
          ))}
        </AutoScroll>
      </div>
      <Resize
        isOpen={isOpenResize}
        setIsOpenResize={setIsOpenResize}
        resizeActiveItem={resizeActiveItem}
        setResizeActiveItem={setResizeActiveItem}
      />

      <Fill
        isOpen={isOpenFill}
        setIsOpenFill={setIsOpenFill}
        bgActiveID={bgActiveID}
        // setBgActiveID={setBgActiveID}
        handleSelectBgImage={handleSelectBgImage}
      />
    </ThemeProvider>
  )
}

export default Background
