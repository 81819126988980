import { fabric } from 'fabric'
import * as _ from 'lodash'
import RemoveBaseHandler from './RemoveBaseHandler'
import { CanvasOptions, RemoveHandlerOptions } from '../../common/interfaces'
import { ToolType } from '../../common/constants'
import { getTourState } from '@/utils/tutorial'

class RemoveCanvasHandler extends RemoveBaseHandler {
  public options: CanvasOptions
  public originalBrushSize = 100
  public toolType: ToolType = ""
  public isOpenPixelManipulationObject = false
  
  constructor(props: RemoveHandlerOptions) {
    super(props)
    this.options = {
      width: props.canvas.width,
      height: props.canvas.height,
    }
  }
  resize(nextWidth, nextHeight) {
    this.canvas.setWidth(nextWidth).setHeight(nextHeight)
    this.canvas.renderAll()
    const diffWidth = nextWidth / 2 - this.options.width / 2
    const diffHeight = nextHeight / 2 - this.options.height / 2

    const deltaPoint = new fabric.Point(diffWidth, diffHeight)
    this.canvas.relativePan(deltaPoint)
  }

  exportToCanvasJSON(): { version: string; objects: Object[] } {
    const json = this.canvas.toJSON(this.root.propertiesToInclude)

    // @ts-ignore
    json.objects.forEach(object => {
      if (object.clipPath) {
        // @ts-ignore

        fabric.util.enlivenObjects([object.clipPath], function (arg1) {
          object.clipPath = arg1[0]
        })
      }
    })
    // @ts-ignore
    return _.cloneDeep(json)
  }

  setBrushSizeOnZoom() {
    if(!this.canvas.freeDrawingBrush) {
      return
    }
    
    this.canvas.freeDrawingBrush.width = this.originalBrushSize

    let zoomScale = Math.min
       (
          this.root.pixelManipulationObjectHandler?.scaleX,
          this.root.pixelManipulationObjectHandler?.scaleY
       ) * this.root.zoomRemoveHandler.currentZoom;
    // @ts-ignore
    let brush = this.canvas.freeDrawingBrush as any
    brush.setZoomScale(zoomScale)
    this.canvas.renderAll()
    const customCursor = document.querySelector('.custom-cursor') as HTMLElement
    if(!customCursor) { return }
    let cursorSize = this.originalBrushSize
    customCursor.style.width = `${cursorSize}px`
    customCursor.style.height = `${cursorSize}px`
  }

  createBrushInstance = (toolType) => {
    if (!toolType) { return }
    switch (toolType) {
      case "Remove":
        const pencilBrush: any = new fabric.BlendBrush(this.canvas)
        pencilBrush.limitedToCanvasSize = true
        pencilBrush.width = 100
        this.canvas.freeDrawingBrush = pencilBrush
        break;
      case "Eraser":
        const eraserBrush: any = new fabric.EraseBrush(this.canvas)
        eraserBrush.limitedToCanvasSize = true
        eraserBrush.width = 45
        this.canvas.freeDrawingBrush = eraserBrush
        break;
        case "Enhance":
          this.canvas.isDrawingMode = false
          this.canvas.selection = false
          break;
        case "MagicBg":
          this.canvas.isDrawingMode = false
          this.canvas.selection = false
          this.canvas.freeDrawingBrush = null
          break;
      default:
        return;
    }
  }
}

export default RemoveCanvasHandler