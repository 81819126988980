import { useEffect, useRef } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import useAppContext from '@hooks/useAppContext'
import Loading from './components/Loading'
import { customAmplitude } from './utils/customAmplitude'
import { LIST_LANGUAGE } from './scenes/engine/common/constants'
import { getLocalStorage, setLocalStorage } from './utils/localStorage'
import { useTranslation } from 'react-i18next'
import api from './services/api'
// import { isMobile } from 'react-device-detect'

function Container({ children }) {
  const containerRef = useRef<HTMLDivElement>()
  // const { isMobile, setIsMobile } = useAppContext()
  // const dispatch = useAppDispatch()
  // const updateMediaQuery = (value: number) => {
  //   if (!isMobile && value >= 800) {
  //     setIsMobile(false)
  //   } else if (!isMobile && value < 800) {
  //     setIsMobile(true)
  //   } else {
  //     setIsMobile(false)
  //   }
  // }
  const { i18n } = useTranslation()
  // 
  useEffect(() => {
    const currURl = window.location.href
    const urlParams = new URLSearchParams(window.location.search)
    const referrerParam = urlParams.get('referrer')

    const eventProperties = {
      Type: currURl.includes('create') ? 'Create' :  currURl.includes('edit') ? 'Template' : 'Scratch',
    }
    customAmplitude('Open', eventProperties)
    
    if(referrerParam) {
      customAmplitude('Deep Link Opened', {
        Source: referrerParam
      })
    }
    
    const containerElement = containerRef.current
    const containerWidth = containerElement.clientWidth
    // updateMediaQuery(containerWidth)
    const resizeObserver = new ResizeObserver(entries => {
      const { width = containerWidth } = (entries[0] && entries[0].contentRect) || {}
      // updateMediaQuery(width)
    })
    resizeObserver.observe(containerElement)

    // block on mobile
    if (/Mobi|Android|iPhone|iPad|iPod|Mobile Safari/.test(navigator.userAgent)) {
      window.location.href = 'https://www.bazaart.me/email-app-redirection-desktop/'
    }
    // change language
    var language = window.navigator.language;
    if(language) {
      LIST_LANGUAGE.forEach(lang => {
        if(language.includes(lang.value)) {
          const localStorageLanguage = getLocalStorage('language')
          if(localStorageLanguage) {
            i18n.changeLanguage(localStorageLanguage)
          } else {
            setLocalStorage('language', lang.value)
            i18n.changeLanguage(lang.value)
          }
        }
      })
    }

    api.getContentTranslation().then((result)=>{
      result.forEach(langResource=>{
        let langUrl =langResource.file
        let lang =langResource.name
        fetch(langUrl)
        .then((res) => res.json())
          .then((data) => {
            i18n.addResourceBundle(lang, 'translation', data);
          });
      })
    })
    return () => {
      if (containerElement) {
        resizeObserver.unobserve(containerElement)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { isLoadedJsonSuccess, isOpenInspector} = useAppContext()

  return (
    <div
      ref={containerRef}
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
      }}
    >
      {children}
      {!isLoadedJsonSuccess && (
          <div
            style={{
              position: 'fixed',
              width: '100vw',
              height: '100vh',
              background: 'rgba(255, 255, 255, 0.7)',
              zIndex: 10000,
              cursor: 'auto',
            }}
            id="overlay-loading"
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 'calc(50% + 32px)',
                transform: 'translateY(-50%)',
                left: isOpenInspector ? '88px' : 0,
                right: isOpenInspector ? '356px' : 0,
                zIndex: 1000,
                transition: 'all .4s ease-in',
              }}
            >
              <Loading />
            </div>
          </div>
        )}
      <canvas id="canvas-confetti" style={{background: 'transparent', position: 'fixed', inset: 0}}></canvas>
    </div>
  )
}

export default Container
