import { createReducer } from '@reduxjs/toolkit'
import { setBrushSize } from './action'

export interface RemoveToolInterface {
  brushSize: number
}

const initialState: RemoveToolInterface = {
    brushSize: 100,
}

export const removeToolReducer = createReducer(initialState, builder => {
  builder.addCase(setBrushSize, (state, { payload }) => {
    state.brushSize = payload
  })
})
