import { lightTheme } from '@/customTheme'
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip'
import { useTranslation } from 'react-i18next'

function Tooltip(props: {
  info: {
    content: string
    placement?: PLACEMENT[keyof PLACEMENT]
  }
  children?: JSX.Element
}) {
  const { t } = useTranslation()
  if (props.info && props.info.content) {
    return (
      <StatefulTooltip
        onMouseEnterDelay={10}
        onMouseLeaveDelay={10}
        accessibilityType={'tooltip'}
        content={t(props.info.content)}
        showArrow
        placement={props.info.placement ? props.info.placement : 'auto'}
        popoverMargin={4}
        overrides={{
          Inner: {
            style: ({ $theme }) => ({
              fontFamily: $theme.typography.font250.fontFamily,
              fontSize: '14px',
              fontWeight: 400,
              background: lightTheme.colors.blackGray,
              padding: '9px 14px',
            }),
          },
          Body: {
            style: ({ $theme }) => ({
              zIndex: 110,
              background: lightTheme.colors.blackGray,
            }),
          },
          Arrow: {
            style: ({ $theme }) => ({
              zIndex: 110,
              background: lightTheme.colors.blackGray,
            }),
          },
        }}
      >
        {props.children}
      </StatefulTooltip>
    )
  }
  return props.children
}

export default Tooltip
