import { memo } from 'react'
import { panelListItems } from '@/constants/app-options'
import useAppContext from '@/hooks/useAppContext'
import { styled } from 'baseui'
import AddGraphics from '../Navbar/components/AddGraphics'
import Photos from '../Navbar/components/Photos'
import AddText from '../Navbar/components/AddText'
import PanelListItem from './PanelListItem'
import AddTemplates from '../Navbar/components/AddTemplates'
import AddStock from '../Navbar/components/AddStock'

const Container = styled('div', (props: any) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  justifyContent: 'center',
  ...props.style,
}))

function PanelsList({ style }: { style?: React.CSSProperties }) {
  const { activePanel, isReplacingImage, removingBg } = useAppContext()

  return (
    <Container style={style} id="panel-list">
      {panelListItems.map(panelListItem => {
        if (panelListItem.name === 'Photos') {
          return (
            <div style={{ position: 'relative' }} key={panelListItem.name}>
              <Photos />
              <div
                style={{
                  position: 'absolute',
                  inset: 0,
                  background: 'transparent',
                  display: removingBg ? 'block' : 'none',
                  cursor: 'pointer',
                }}
              ></div>
            </div>
          )
        }
        if (panelListItem.name === 'Stock') {
          return (
            <div style={{ position: 'relative' }} key={panelListItem.name}>
              <AddStock />
            </div>
          )
        }
        if (panelListItem.name === 'Graphics') {
          return (
            <div style={{ position: 'relative' }} key={panelListItem.name}>
              <AddGraphics />
              <div
                style={{
                  position: 'absolute',
                  inset: 0,
                  background: 'transparent',
                  display: isReplacingImage || removingBg ? 'block' : 'none',
                }}
              ></div>
            </div>
          )
        }
        if (panelListItem.name === 'Text') {
          return <AddText key={panelListItem.name} />
        }
        if (panelListItem.name === 'Templates') {
          return <AddTemplates key={panelListItem.name} />
        }
        return (
          <PanelListItem
            // disable={true}
            label={panelListItem.name}
            name={panelListItem.name}
            key={panelListItem.name}
            icon={panelListItem.name}
            activePanel={activePanel}
          />
        )
      })}
    </Container>
  )
}

export default memo(PanelsList)
