// @ts-nocheck
import { fabric } from 'fabric'
import { HandlerOptions } from '../common/interfaces'
import BaseHandler from './BaseHandler'
import {
  drawCircleIcon,
  drawVerticalLineIcon,
  drawHorizontalLineIcon,
  drawRotateIcon,
  mouseRotateIcon,
  drawCircleIconHover,
  drawRotateIconHover,
  drawVerticalLineIconHover,
  drawMoveTextIcon,
  drawMoveTextIconHover,
} from '../utils/drawer'
import { NAVBAR_HEIGHT, ObjectType } from '../common/constants'
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@/scenes/engine/objects/media-repository/media_image_type'
import store from '@/store/store'
import { setHasClipboardImage } from '@/store/slices/editor/action'
import { nanoid } from 'nanoid'



class PersonalizationHandler extends BaseHandler {
  private cornerPre
  private isChanging
  private isMoving
  private endAnimation = false
  private isRenderHover = false
  private isClickRotate = false
  private isRotating = false
  private isDraggingText = false
  private currentScalingCorner = ''
  constructor(props: HandlerOptions) {
    super(props)
    this.init(this.root)
    this.cornerPre = null
    this.isChanging = false
    this.isMoving = false
  }

  init(root) {
    fabric.Object.prototype.transparentCorners = false
    fabric.Object.prototype.cornerColor = '#20bf6b'
    fabric.Object.prototype.cornerStyle = 'circle'
    fabric.Object.prototype.borderColor = '#FF0560'
    fabric.Object.prototype.cornerSize = 12
    fabric.Object.prototype.borderScaleFactor = 1.5
    fabric.Object.prototype.borderOpacityWhenMoving = 1
    fabric.Object.prototype.borderOpacity = 1
    fabric.Object.prototype.shadowBlur = 4
    fabric.Object.prototype.shadowColor = 'rgba(0, 0, 0, 0.25)'

    fabric.Object.prototype.controls.tr = new fabric.Control({
      x: 0.5,
      y: -0.5,
      actionHandler: fabric.controlsUtils.scalingEqually,
      // cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
      cursorStyle: 'nesw-resize',
      actionName: fabric.controlsUtils.scaleOrSkewActionName,
      render: drawCircleIcon,
      cornerSize: 28,
      withConnection: true,
    })

    fabric.Object.prototype.controls.tl = new fabric.Control({
      x: -0.5,
      y: -0.5,
      actionHandler: fabric.controlsUtils.scalingEqually,
      // cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
      cursorStyle: 'nwse-resize',
      actionName: fabric.controlsUtils.scaleOrSkewActionName,
      render: drawCircleIcon,
      cornerSize: 28,
      withConnection: true,
    })

    fabric.Object.prototype.controls.bl = new fabric.Control({
      x: -0.5,
      y: 0.5,
      actionHandler: fabric.controlsUtils.scalingEqually,
      // cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
      cursorStyle: 'nesw-resize',
      actionName: fabric.controlsUtils.scaleOrSkewActionName,
      render: drawCircleIcon,
      cornerSize: 28,
      withConnection: true,
    })

    fabric.Object.prototype.controls.br = new fabric.Control({
      x: 0.5,
      y: 0.5,
      actionHandler: fabric.controlsUtils.scalingEqually,
      cursorStyle: 'nwse-resize',
      actionName: fabric.controlsUtils.scaleOrSkewActionName,
      render: drawCircleIcon,
      cornerSize: 28,
      withConnection: true,
    })

    fabric.Object.prototype.controls.ml = new fabric.Control({
      x: -0.5,
      y: 0,
      offsetX: -1,
      // cursorStyle: `url("data:image/svg+xml;charset=utf-8,${iconResizeHorizontal}") 12 12, crosshair`,
      cursorStyle: 'ew-resize',
      actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
      actionName: fabric.controlsUtils.scaleOrSkewActionName,
      render: drawVerticalLineIcon,
      cornerSize: 18,
      withConnection: true,
    })

    fabric.Object.prototype.controls.mt = new fabric.Control({
      x: 0,
      y: -0.5,
      offsetY: -1,
      // cursorStyle: `url("data:image/svg+xml;charset=utf-8,${iconResizeVertical}") 12 12, crosshair`,
      cursorStyle: 'ns-resize',
      actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
      actionName: fabric.controlsUtils.scaleOrSkewActionName,
      render: drawHorizontalLineIcon,
      cornerSize: 18,
      withConnection: true,
    })

    fabric.Object.prototype.controls.mb = new fabric.Control({
      x: 0,
      y: 0.5,
      offsetY: 2,
      // cursorStyle: `url("data:image/svg+xml;charset=utf-8,${iconResizeVertical}") 12 12, crosshair`,
      cursorStyle: 'ns-resize',
      actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
      actionName: fabric.controlsUtils.scaleOrSkewActionName,
      render: drawHorizontalLineIcon,
      cornerSize: 18,
      withConnection: true,
    })

    fabric.Object.prototype.controls.mr = new fabric.Control({
      x: 0.5,
      y: 0,
      offsetX: 0.5,
      cursorStyle: 'ew-resize',
      actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
      actionName: fabric.controlsUtils.scaleOrSkewActionName,
      render: drawVerticalLineIcon,
      cornerSize: 18,
      withConnection: true,
    })

    fabric.Object.prototype.controls.mtr = new fabric.Control({
      x: 0,
      y: 0.5,
      offsetY: 37.5,
      cursorStyle: mouseRotateIcon(360),
      actionHandler: fabric.controlsUtils.rotationWithSnapping,
      actionName: 'rotate',
      render: drawRotateIcon,
      cornerSize: 48,
      withConnection: false,
    })

    // Texbox controls
    fabric.Textbox.prototype.controls.tr = fabric.Object.prototype.controls.tr
    fabric.Textbox.prototype.controls.tl = fabric.Object.prototype.controls.tl
    fabric.Textbox.prototype.controls.bl = fabric.Object.prototype.controls.bl
    fabric.Textbox.prototype.controls.br = fabric.Object.prototype.controls.br
    // fabric.Textbox.prototype.controls.deleteObject = fabric.Object.prototype.controls.deleteObject
    // // fabric.Textbox.prototype.controls.replaceObject = fabric.Object.prototype.controls.replaceObject
    // fabric.Textbox.prototype.controls.duplicateObject = fabric.Object.prototype.controls.duplicateObject
    // fabric.Textbox.prototype.controls.bringFrontPosition = fabric.Object.prototype.controls.bringFrontPosition
    // fabric.Textbox.prototype.controls.bringBackPosition = fabric.Object.prototype.controls.bringBackPosition

    fabric.Group.prototype.setControlsVisibility({
      mt: false,
      mb: false,
      mr: false,
      ml: false,
    })

    fabric.Textbox.prototype.setControlsVisibility({
      mt: false,
      mb: false,
    })

    fabric.Image.prototype.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
    })

    // changeWidth
    fabric.Textbox.prototype.controls.mr = new fabric.Control({
      x: 0.5,
      y: 0,
      offsetX: 0.5,
      actionHandler: fabric.controlsUtils.changeWidth,
      // cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
      // cursorStyle: resizeIcon(),
      cursorStyle: 'ew-resize',
      actionName: 'resizing',
      render: drawVerticalLineIcon,
      cornerSize: 18,
      withConnection: true,
    })

    fabric.Textbox.prototype.controls.ml = new fabric.Control({
      x: -0.5,
      y: 0,
      offsetX: -1,
      actionHandler: fabric.controlsUtils.changeWidth,
      // cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
      // cursorStyle: resizeIcon(),
      cursorStyle: 'ew-resize',
      actionName: 'resizing',
      render: drawVerticalLineIcon,
      cornerSize: 18,
      withConnection: true,
    })

    fabric.Textbox.prototype.controls.mtr = new fabric.Control({
      offsetX: -18,
      y: 0.5,
      offsetY: 37.5,
      actionHandler: fabric.controlsUtils.rotationWithSnapping,
      cursorStyle: mouseRotateIcon(360),
      actionName: 'rotate',
      render: drawRotateIcon,
      cornerSize: 48,
      withConnection: false,
    })
    fabric.Textbox.prototype.controls.moveText = new fabric.Control({
      offsetX: 18,
      y: 0.5,
      offsetY: 37.5,
      cursorStyle: 'move',
      actionHandler: () => {
        this.editorEventManager.emit('text:moving')
        this.canvas.setCursor('move')
        this.isDraggingText = true
        this.canvas.getActiveObject().set({
          hasRotatingPoint: false,
          selectable: false,
          hasControls: false,
        })
      },
      actionName: 'moveText',
      render: drawMoveTextIcon,
      cornerSize: 48,
      withConnection: false,
    })

    fabric.Textbox.prototype.editingBorderColor = 'rgba(255, 5, 96)'

    this.canvas.on('mouse:over', event => {
      const target = event.target
      const activeObjects = this.canvas.getActiveObject()

      if (
        target &&
        activeObjects !== target &&
        !target.isTemplateLayer &&
        target?.type !== ObjectType.BAZAART_BG &&
        target?.type !== ObjectType.FRAME
      ) {
        const bound = target.getBoundingRect()

        const ctx = this.canvas.getContext()
        ctx.strokeStyle = '#FF0560'
        ctx.lineWidth = 1.5
        // ctx.strokeRect(bound.left, bound.top, bound.width, bound.height)
        ctx.beginPath()
        ctx.moveTo(target.oCoords.bl.x, target.oCoords.bl.y)
        ctx.lineTo(target.oCoords.tl.x, target.oCoords.tl.y)
        ctx.lineTo(target.oCoords.tr.x, target.oCoords.tr.y)
        ctx.lineTo(target.oCoords.br.x, target.oCoords.br.y)
        ctx.lineTo(target.oCoords.bl.x, target.oCoords.bl.y)
        ctx.stroke()
      }
    })

    function treatAngle(angle) {
      return angle - (angle % 15)
    }
    const state = {
      lastAngleRotation: null,
      selectionStart: 0,
      selectionEnd: 0,
      isTextEditing: false,
    }

    this.canvas.on('object:rotating', e => {
      this.isRotating = true
      const angle = treatAngle(e.target.angle)

      if (state.lastAngleRotation !== angle) {
        this.canvas.setCursor(mouseRotateIcon(angle))
        state.lastAngleRotation = angle
      }
      const target = e.target
      if (!target) {
        return
      }
      target.setControlsVisibility({
        ml: false, 
        mr: false,
        tl: false, 
        tr: false, 
        bl: false, 
        br: false,
        moveText: false,
        mtr: true
      })
    })

    this.canvas.on('object:modified', e => {
      const target = e.target
      updateControlCursorStyle(target)
    })

    const updateControlCursorStyle = target => {
      ;['tl', 'tr', 'bl', 'br', 'mtr'].forEach(corner => {
        const rotatedCursorStyle = getRotatedCursorStyle(target.angle, corner)
        target.controls[corner].cursorStyle =
          corner === 'mtr' ? mouseRotateIcon(target.angle) : rotatedCursorStyle
      })
    }

    function getRotatedCursorStyle(angle, corner) {
      let cursorStyle = ''

      switch (corner) {
        case 'tr':
        case 'bl':
          cursorStyle = 'nesw-resize'
          if (
            (angle >= 0 && angle <= 22) ||
            (angle >= 338 && angle < 360) ||
            (angle >= 157 && angle <= 202)
          ) {
            return cursorStyle
          }
          if ((angle >= 23 && angle <= 67) || (angle >= 203 && angle <= 247)) {
            cursorStyle = 'ew-resize'
          }
          if ((angle >= 68 && angle <= 112) || (angle >= 248 && angle <= 292)) {
            cursorStyle = 'nwse-resize'
          }
          if ((angle >= 113 && angle <= 156) || (angle >= 293 && angle <= 337)) {
            cursorStyle = 'ns-resize'
          }
          break
        case 'br':
        case 'tl':
          cursorStyle = 'nwse-resize'
          if (
            (angle >= 0 && angle <= 22) ||
            (angle >= 338 && angle < 360) ||
            (angle >= 157 && angle <= 202)
          ) {
            return cursorStyle
          }
          if ((angle >= 23 && angle <= 67) || (angle >= 203 && angle <= 247)) {
            cursorStyle = 'ns-resize'
          }
          if ((angle >= 68 && angle <= 112) || (angle >= 248 && angle <= 292)) {
            cursorStyle = 'nesw-resize'
          }
          if ((angle >= 113 && angle <= 156) || (angle >= 293 && angle <= 337)) {
            cursorStyle = 'ew-resize'
          }
          break
        default:
          break
      }
      return cursorStyle
    }

    this.canvas.on('object:moving', event => {
      this.isMoving = true
    })

    this.canvas.on('mouse:move', event => {
      if (this.isClickRotate) {
        this.isChanging = true
        this.isClickRotate = false
      }
      const activeObject = this.canvas.getActiveObject()
      if (this.isDraggingText) {
        handleDraggingText(event, activeObject)
      }
      if (this.isMoving) {
        if (event.target) {
          activeObject.hasControls = false
          activeObject.hasRotatingPoint = false
        }
        return
      }
      if (this.isChanging) {
        return
      }
      if (!activeObject || !activeObject?.hasControls) {
        return
      }

      const pointer = this.canvas.getPointer(event.e, true)
      const corner = this.currentScalingCorner ? this.currentScalingCorner : activeObject._findTargetCorner(pointer, fabric.util.isTouchEvent(event.e))
      if (!corner && !this.cornerPre) {
        return
      }
      if (corner && corner === this.cornerPre) {
        return
      }
      if (corner) {
        revertPreviousCorner(this.cornerPre) //
        this.cornerPre = corner
        if(corner !== 'moveText') {
          fabric.Object.prototype.cornerSize = corner === 'mtr' ? 28 : 20
          fabric.Object.prototype.controls[corner] = new fabric.Control({
            ...fabric.Object.prototype.controls[corner],
            render:
              corner === 'mtr'
                ? onDrawRotateIcnHover : onDrawCircleHover,
          })
        }
        fabric.Textbox.prototype.cornerSize = corner === 'mtr' || corner === 'moveText' ? 28 : 20
        fabric.Textbox.prototype.controls[corner] = new fabric.Control({
          ...fabric.Textbox.prototype.controls[corner],
          render:
            corner === 'mtr'
              ? onDrawRotateIcnHover
              : corner === 'moveText'
              ? onDrawMoveTextIcnHover
              : corner === 'mr' || corner === 'ml'
              ? drawVerticalLineIconHover
              : onDrawCircleHover,
        })
      } else {
        if (!this.isChanging) {
          this.endAnimation = true
          this.isRenderHover = false
          revertPreviousCorner(this.cornerPre)
          this.cornerPre = null
          setTimeout(() => {
            this.endAnimation = false
          }, 100)
        }
      }
      this.canvas.requestRenderAll()
    })

    function revertPreviousCorner(cornerPre) {
      if (!cornerPre) {
        return
      }
      if(cornerPre !== 'moveText') {
        fabric.Object.prototype.cornerSize = cornerPre === 'mtr' ? 24 : 12
        fabric.Object.prototype.controls[cornerPre] = new fabric.Control({
          ...fabric.Object.prototype.controls[cornerPre],
          render:
            cornerPre === 'mtr' ? drawRotateIcon : drawCircleIcon,
        })
      }
      fabric.Textbox.prototype.cornerSize = cornerPre === 'mtr' || cornerPre === 'moveText' ? 24 : 12
      fabric.Textbox.prototype.controls[cornerPre] = new fabric.Control({
        ...fabric.Textbox.prototype.controls[cornerPre],
        render:
          cornerPre === 'mtr'
            ? drawRotateIcon
            : cornerPre === 'moveText'
            ? drawMoveTextIcon
            : cornerPre === 'mr' || cornerPre === 'ml'
            ? drawVerticalLineIcon
            : drawCircleIcon,
      })
    }

    this.canvas.on('before:transform', e => {
      // this.isChanging = true
    })

    this.canvas.on('mouse:up', e => {
      const target = e.target
      let rotateAngle = 0
      if (this.isClickRotate) {
        this.root.transactionHandler.save()
        rotateAngle = target.angle + 90 > 360 ? target.angle + 90 - 360 : target.angle + 90
        target.set('angle', rotateAngle)
        // adjustRotatePosition(rotateAngle)
        target.setCoords()
        this.editorEventManager.emit('after:render')
      } else if (this.isChanging) {
        rotateAngle = target.angle
        // adjustRotatePosition(rotateAngle)
      }
      const activeObject = this.canvas.getActiveObject()
      if (activeObject) {
        activeObject.setCoords()
        const pointer = this.canvas.getPointer(e.e, true)
        setTimeout(() => {
          const corner = activeObject._findTargetCorner(pointer, fabric.util.isTouchEvent(e.e))
          if ((this.isClickRotate || this.isChanging) && !corner) {
            this.endAnimation = true
            revertPreviousCorner(this.cornerPre)
            this.canvas.setCursor('default')
            this.cornerPre = null
            setTimeout(() => {
              this.endAnimation = false
            }, 100)
          }
        });
        activeObject.hasControls = true
        activeObject.hasRotatingPoint = true
      }
      if (this.isDraggingText) {
        this.editorEventManager.emit('text:moved')
        this.canvas.setCursor('default')
        this.canvas.getActiveObject().set({
          hasRotatingPoint: true,
          selectable: true,
          hasControls: true,
        })
      }
      if (this.isChanging || this.isDraggingText) {
        if (state.isTextEditing) {
          activeObject.enterEditing()
          activeObject.setSelectionStart(state.selectionStart)
          activeObject.setSelectionEnd(state.selectionEnd)
        }
      }
      if(this.isRotating) {
        activeObject.set('angle', Math.round(activeObject.angle))
        this.isRotating = false
      }
      this.isDraggingText = false
      this.isChanging = false
      this.isClickRotate = false
      this.isMoving = false
      this.currentScalingCorner = ''
      if(target) {
        target.setControlsVisibility({
          tl: true, 
          tr: true, 
          bl: true, 
          br: true, 
          mtr: true, 
        })
        if(target.type === ObjectType.BAZAART_TEXT) {
          target.setControlsVisibility({
            moveText: true, 
            ml: true, 
            mr: true
          })
        }
      }
      this.canvas.requestRenderAll()
    })

    this.canvas.on('mouse:down', async (event) => {
      const activeObject = this.canvas.getActiveObject()
      const target = event.target
      // right click
      if (event.button === 3) {
        const getDataFromClipboard = async () => {
          try {
            const clipboardItems = await navigator.clipboard.read()
            for (const clipboardItem of clipboardItems) {
              for (const type of clipboardItem.types) {
                if (type === 'image/png' || type === 'image/jpeg') {
                  const blob = await clipboardItem.getType(type)
                  const reader = new FileReader()
                  reader.onloadend = async () => {
                    const base64String = reader.result as string
                    let guid = nanoid()
                    let assetStateId = nanoid()

                    let maskInfo =
                      await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractMask(
                        base64String
                      )

                    await MediaImageRepository.getInstance().storeImageBase64(
                      guid,
                      assetStateId,
                      MediaImageType.latest,
                      base64String
                    )
                    await MediaImageRepository.getInstance().storeImageBase64(
                      guid,
                      assetStateId,
                      MediaImageType.original,
                      base64String
                    )
                    await MediaImageRepository.getInstance().storeImageBase64(
                      guid,
                      assetStateId,
                      MediaImageType.mask,
                      maskInfo.blob
                    )

                    let frame = this.root.frameHandler.get()
                    let layerSize = maskInfo.size
                    let canvasAspectRatio = frame.width / frame.height
                    let layerAspectRatio = layerSize.width / layerSize.height
                    let width = 0.6

                    if (layerAspectRatio < canvasAspectRatio) {
                      width = (width * layerAspectRatio) / canvasAspectRatio
                    }
                    console.log('pointer', event.pointer);
                    
                    const object = {
                      type: ObjectType.BAZAART_IMAGE,
                      centerPoint: {
                        x: 0.5,
                        y: 0.5,
                      },
                      sizeOnCanvas: {
                        width: width,
                      },
                      transformation: {
                        horizontalFlip: false,
                        verticalFlip: false,
                      },
                      boundingBox: { y: 0, width: 1, height: 1, x: 0 },
                      absoluteRotation: 0,
                      bazaartGuid: guid,
                      layerAssetStateId: assetStateId,
                      hasTransparency: maskInfo.hasTransparency,
                      top: event.pointer.y,
                      left: event.pointer.x,
                    }
                    this.root.objectsHandler.clipboard = object
                    store.dispatch(setHasClipboardImage('external'))
                  }
                  reader.readAsDataURL(blob)
                }
              }
            }
          } catch (error) {}
        }
        await getDataFromClipboard()

        event.e.preventDefault()
        event.e.stopPropagation()
        if (!target) {
          return
        }
        if (target.type === ObjectType.FRAME) {
          this.canvas.discardActiveObject()
        } else {
          this.canvas.setActiveObject(target)
        }
        const pointer = event.pointer
        const contextMenu = document.getElementById('context-menu')
        const isOverflowY = pointer.y + 64 + contextMenu.offsetHeight > window.innerHeight
        if (isOverflowY) {
          this.context.setContextMenuDetails({
            left: pointer.x,
            top: window.innerHeight - contextMenu.offsetHeight - 64,
          })
        } else {
          this.context.setContextMenuDetails({
            left: pointer.x,
            top: pointer.y,
          })
        }

        return
      }
      if (event.button === 1) {
        this.context.setContextMenuDetails(null)
      }
      const pointer = this.canvas.getPointer(event.e, true)
      if (!activeObject || !activeObject?.hasControls) {
        return
      }
      const corner = activeObject._findTargetCorner(pointer, fabric.util.isTouchEvent(event.e))
      this.isClickRotate = corner === 'mtr'
      if (corner === 'moveText') {
        this.root.transactionHandler.save()
        // calculate offset
        const pointer = event.pointer
        this.offsetX = pointer.x / this.canvas.getZoom() - activeObject.left
        this.offsetY = pointer.y / this.canvas.getZoom() - activeObject.top
      }
      if (activeObject.type === ObjectType.BAZAART_TEXT && (corner === 'moveText' || corner === 'mtr')) {
        state.selectionStart = activeObject.selectionStart
        state.selectionEnd = activeObject.selectionEnd
        state.isTextEditing = activeObject.isEditing
        if (activeObject.isEditing) {
          activeObject.exitEditing()
        }
      }
      else{
        state.isTextEditing = false
      }
    })
    this.canvas.on('object:resizing', (event) => {
      const target = event.target
      if (!target) {
        return
      }
      this.currentScalingCorner = event.transform.corner
      if (target.type === ObjectType.STATIC_TEXT || target.type === ObjectType.BAZAART_TEXT) {
        target.setControlsVisibility({
          ml: this.currentScalingCorner === 'ml', 
          mr: this.currentScalingCorner === 'mr',
          tl: false, 
          tr: false, 
          bl: false, 
          br: false,
          moveText: false,
          mtr: false
        })
      }
    })

    this.canvas.on('object:scaling', (event) => {
      this.currentScalingCorner = event.transform.corner
      const target = event.target
      if(!target) { return }
      target.setControlsVisibility({
        // Set all to false initially
        tl: this.currentScalingCorner === 'tl', 
        tr: this.currentScalingCorner === 'tr', 
        bl: this.currentScalingCorner === 'bl', 
        br: this.currentScalingCorner === 'br',
        ml: false,
        mr: false,
        moveText: false,
        mtr: false
      })
    })

    const onDrawCircleHover = (ctx, left, top, __styleOverride, fabricObject) => {
      if (this.isRenderHover) {
        drawCircleIconHover(ctx, left, top, __styleOverride, fabricObject, 10)
        return
      }
      let radius = 7
      let loop = () => {
        drawCircleIconHover(ctx, left, top, __styleOverride, fabricObject, radius)
        if (radius >= 10 || this.endAnimation) {
          this.isRenderHover = true
          return
        } else {
          radius = Math.min(radius + 0.25, 10)
        }
        requestAnimationFrame(loop)
      }
      requestAnimationFrame(loop)
    }

    const onDrawMoveTextIcnHover = (ctx, left, top, __styleOverride, fabricObject) => {
      if (this.isRenderHover) {
        drawMoveTextIconHover(ctx, left, top, __styleOverride, fabricObject, 56)
        return
      }
      let size = 48
      let loop = () => {
        drawMoveTextIconHover(ctx, left, top, __styleOverride, fabricObject, size)
        if (size >= 56 || this.endAnimation) {
          this.isRenderHover = true
          return
        } else {
          size = Math.min(size + 0.35, 56)
        }
        requestAnimationFrame(loop)
      }
      requestAnimationFrame(loop)
    }

    const onDrawRotateIcnHover = (ctx, left, top, __styleOverride, fabricObject) => {
      if (this.isRenderHover) {
        drawRotateIconHover(ctx, left, top, __styleOverride, fabricObject, 56)
        return
      }
      let size = 48
      let loop = () => {
        drawRotateIconHover(ctx, left, top, __styleOverride, fabricObject, size)
        if (size >= 56 || this.endAnimation) {
          this.isRenderHover = true
          return
        } else {
          size = Math.min(size + 0.35, 56)
        }
        requestAnimationFrame(loop)
      }
      requestAnimationFrame(loop)
    }

    const adjustRotatePosition = currentAngle => {
      if (currentAngle >= 110 && currentAngle <= 250) {
        fabric.Object.prototype.controls.mtr = new fabric.Control({
          ...fabric.Object.prototype.controls.mtr,
          x: 0.5,
          y: 0,
          offsetX: 37.5,
          offsetY: 0,
        })
        fabric.Textbox.prototype.controls.mtr = new fabric.Control({
          ...fabric.Textbox.prototype.controls.mtr,
          x: 0.5,
          y: 0,
          offsetY: 18,
          offsetX: 37.5,
        })
        fabric.Textbox.prototype.controls.moveText = new fabric.Control({
          ...fabric.Textbox.prototype.controls.moveText,
          x: 0.5,
          y: 0,
          offsetY: -18,
          offsetX: 37.5,
        })
      } else {
        fabric.Object.prototype.controls.mtr = new fabric.Control({
          ...fabric.Object.prototype.controls.mtr,
          x: 0,
          y: 0.5,
          offsetX: 0,
          offsetY: 37.5,
        })
        fabric.Textbox.prototype.controls.mtr = new fabric.Control({
          ...fabric.Textbox.prototype.controls.mtr,
          x: 0,
          offsetX: -18,
          y: 0.5,
          offsetY: 37.5,
        })
        fabric.Textbox.prototype.controls.moveText = new fabric.Control({
          ...fabric.Textbox.prototype.controls.moveText,
          x: 0,
          offsetX: 18,
          y: 0.5,
          offsetY: 37.5,
        })
      }
    }

    const handleDraggingText = (event, activeObject) => {
      const pointer = event.pointer
      activeObject.set({
        left: pointer.x / this.canvas.getZoom() - this.offsetX,
        top: pointer.y / this.canvas.getZoom() - this.offsetY,
      })
      this.canvas.requestRenderAll()
      this.root.guidelinesHandler.onObjectMoving(event)
    }

    this.canvas.selectionColor = 'rgba(255, 5, 96, 0.1)'
    this.canvas.selectionBorderColor = 'rgba(255, 5, 96, 1)'
    this.canvas.selectionLineWidth = 1
    this.canvas.on('selection:created', ev => {
      const objects = this.canvas.getActiveObjects()
      const selection = this.canvas.getActiveObject()
      if (objects.length > 1) {
        selection.setControlsVisibility({
          mt: false,
          mb: false,
          mr: false,
          ml: false,
        })
        // selection.padding = 10
      }
    })
  }
  findRotatePosition(activeObj: any, H: number) {
    const [tl, tr, br, bl] = activeObj.getCoords()
    // center point
    let centerObjectPoint = {
        x: (tl.x + tr.x) / 2,
        y: (tl.y + bl.y) / 2
    };
    // projection of center object point on bottom edge
    let projectionPoint

    let directionVector
    if(!activeObj.positionRotateIcon || activeObj.positionRotateIcon === 'bottom') {
      projectionPoint = {
        x: (br.x + bl.x) / 2,
        y: (br.y + bl.y) / 2
      };
      directionVector = {
        x: projectionPoint.x - centerObjectPoint.x,
        y: projectionPoint.y - centerObjectPoint.y
      }
    } else if(activeObj.positionRotateIcon === 'left') {
      projectionPoint = {
        x: (tl.x + bl.x) / 2,
        y: (tl.y + bl.y) / 2
      };
      directionVector = {
        x: centerObjectPoint.x - projectionPoint.x,
        y: centerObjectPoint.y - projectionPoint.y
      }
    } else if(activeObj.positionRotateIcon === 'right') {
      projectionPoint = {
        x: (tr.x + br.x) / 2,
        y: (tr.y + br.y) / 2
      };
      directionVector = {
        x: projectionPoint.x - centerObjectPoint.x,
        y: projectionPoint.y - centerObjectPoint.y
      }
    } else {
      projectionPoint = {
        x: (tl.x + tr.x) / 2,
        y: (tl.y + tr.y) / 2
      };
      directionVector = {
        x: projectionPoint.x - centerObjectPoint.x,
        y: projectionPoint.y - centerObjectPoint.y
      }
    }

    const vectorLength = Math.sqrt(directionVector.x * directionVector.x + directionVector.y * directionVector.y);
    const unitVector = {
        x: directionVector.x / vectorLength,
        y: directionVector.y / vectorLength
    };
    
    // const radian = fabric.util.degreesToRadians(90 - activeObj.angle)
    const rotatePoint = {
      x: projectionPoint.x + unitVector.x * 52,
      y: projectionPoint.y + unitVector.y * 52,
    };
    return rotatePoint;
  }
  // check out bottm
  checkOutScreen(positionRotateIcon) {
    let maxHeightScreen = window.innerHeight - NAVBAR_HEIGHT
    let maxWidthScreen = window.innerWidth - 356
    return positionRotateIcon.y > maxHeightScreen || positionRotateIcon.x < 0 || positionRotateIcon.x > maxWidthScreen || positionRotateIcon.y < 0
  }
  rePositionRotate(): boolean {
    const activeObj = this.canvas.getActiveObject()
    
    let x, y, offsetX, offsetY
    if(!activeObj.positionRotateIcon || activeObj.positionRotateIcon === 'bottom') {
      if((activeObj.angle < 90 && activeObj.angle >= 0) || (activeObj.angle > 270 && activeObj.angle < 360)) {
        // move to left
        x = -0.5
        y = 0
        offsetX = -37.5
        offsetY = 0
        fabric.Object.prototype.controls.mtr = new fabric.Control({
          ...fabric.Object.prototype.controls.mtr,
          x: x,
          y: y,
          offsetX: offsetX,
          offsetY: offsetY,
        })
        // text
        fabric.Textbox.prototype.controls.mtr = new fabric.Control({
          ...fabric.Textbox.prototype.controls.mtr,
          x: x,
          y: y,
          offsetX: offsetX,
          offsetY: offsetY - 18,
        })
        fabric.Textbox.prototype.controls.moveText = new fabric.Control({
          ...fabric.Textbox.prototype.controls.moveText,
          x: x,
          y: y,
          offsetX: offsetX,
          offsetY: offsetY + 18,
        })
        activeObj.set('positionRotateIcon', 'left')
      } else {
        // move to top
        x = 0
        y = -0.5
        offsetX = 0
        offsetY = -37.5
        fabric.Object.prototype.controls.mtr = new fabric.Control({
          ...fabric.Object.prototype.controls.mtr,
          x: x,
          y: y,
          offsetX: offsetX,
          offsetY: offsetY,
        })
        activeObj.set('positionRotateIcon', 'top')
      }
    } else if(activeObj.positionRotateIcon === 'left') {
      // move to right
      x = 0.5
      y = 0
      offsetX = 37.5
      offsetY = 0
      fabric.Object.prototype.controls.mtr = new fabric.Control({
        ...fabric.Object.prototype.controls.mtr,
        x: x,
        y: y,
        offsetX: offsetX,
        offsetY: offsetY,
      })
      // text
      fabric.Textbox.prototype.controls.mtr = new fabric.Control({
        ...fabric.Textbox.prototype.controls.mtr,
        x: x,
        y: y,
        offsetX: offsetX,
        offsetY: offsetY - 18,
      })
      fabric.Textbox.prototype.controls.moveText = new fabric.Control({
        ...fabric.Textbox.prototype.controls.moveText,
        x: x,
        y: y,
        offsetX: offsetX,
        offsetY: offsetY + 18,
      })
      activeObj.set('positionRotateIcon', 'right')
    }
    
  }
  reCalculateRotatePosition = () => {
    const activeObj = this.canvas.getActiveObject()
    if(!activeObj) { return }
    let positionRotateIcon = this.findRotatePosition(activeObj, activeObj.getBoundingRect().height)
    if(this.checkOutScreen(positionRotateIcon)) {
      this.rePositionRotate()
    } else {
      if(activeObj.positionRotateIcon === 'left' && (activeObj.angle < 45 || activeObj.angle > 135)) {
        let x, y, offsetX, offsetY
        x = -0.5
        y = 0
        offsetX = -37.5
        offsetY = 0
        // move to left
        fabric.Object.prototype.controls.mtr = new fabric.Control({
          ...fabric.Object.prototype.controls.mtr,
          x: x,
          y: y,
          offsetX: offsetX,
          offsetY: offsetY,
        })
        // text
        fabric.Textbox.prototype.controls.mtr = new fabric.Control({
          ...fabric.Textbox.prototype.controls.mtr,
          x: x,
          y: y,
          offsetX: offsetX,
          offsetY: offsetY - 18,
        })
        fabric.Textbox.prototype.controls.moveText = new fabric.Control({
          ...fabric.Textbox.prototype.controls.moveText,
          x: x,
          y: y,
          offsetX: offsetX,
          offsetY: offsetY + 18,
        })
        return
      }
      // 
      const currentAngle = activeObj.angle
      if (currentAngle >= 110 && currentAngle <= 250) {
        activeObj.set('positionRotateIcon', 'left')
        fabric.Object.prototype.controls.mtr = new fabric.Control({
          ...fabric.Object.prototype.controls.mtr,
          x: 0.5,
          y: 0,
          offsetX: 37.5,
          offsetY: 0,
        })
        fabric.Textbox.prototype.controls.mtr = new fabric.Control({
          ...fabric.Textbox.prototype.controls.mtr,
          x: 0.5,
          y: 0,
          offsetY: 18,
          offsetX: 37.5,
        })
        fabric.Textbox.prototype.controls.moveText = new fabric.Control({
          ...fabric.Textbox.prototype.controls.moveText,
          x: 0.5,
          y: 0,
          offsetY: -18,
          offsetX: 37.5,
        })
      } else {
        activeObj.set('positionRotateIcon', 'bottom')
        fabric.Object.prototype.controls.mtr = new fabric.Control({
          ...fabric.Object.prototype.controls.mtr,
          x: 0,
          y: 0.5,
          offsetX: 0,
          offsetY: 37.5,
        })
        fabric.Textbox.prototype.controls.mtr = new fabric.Control({
          ...fabric.Textbox.prototype.controls.mtr,
          x: 0,
          offsetX: -18,
          y: 0.5,
          offsetY: 37.5,
        })
        fabric.Textbox.prototype.controls.moveText = new fabric.Control({
          ...fabric.Textbox.prototype.controls.moveText,
          x: 0,
          offsetX: 18,
          y: 0.5,
          offsetY: 37.5,
        })
      }
    }
  }
}

export default PersonalizationHandler