import Icons from '../../Icons'
import { KIND } from 'baseui/button'
import { useEditorContext, useRemoveEditorContext } from '@/scenes/engine'
import HeadingInspector from '@/components/HeadingInspector'
import AutoScroll from '@/components/AutoScroll'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { LabelLarge } from 'baseui/typography'
import { lightTheme } from '@/customTheme'
import { ThemeProvider, styled, useStyletron } from 'baseui'
import GridFilter from './components/FilterBg'
import BtnOpenNewSlide from '@/components/BtnOpenNewSlide'
import Opacity from './components/Opacity'
import Transform from './components/Transform'
import { useEffect, useState } from 'react'
import Filter from './components/Filter'
import Adjust from './components/Adjust'
import Shadow from './components/Shadow'
import Outline from './components/Outline'
import useAppContext from '@/hooks/useAppContext'
import { fabric } from 'fabric'
import PixelManipulation from './components/PixelManipulation'
import { selectUserIsPremium } from '@/store/slices/user/selectors'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store/store'
import { useTranslation } from 'react-i18next'
import { setIntroVideoDetail, setOpenModalTryPremium } from '@/store/slices/user/actions'
import RemoveBgImage from '@assets/images/remove-bg.png'
import RemoveObjectsImage from '@assets/images/remove-objects.jpg'
import RemoveObjectsIntro from '@assets/video/intro-remove-thumb.mp4'
import RemoveBgIntro from '@assets/video/intro-remove-bg-thumb.mp4'
import MagicBackgroundImage from '@assets/images/magic-background.jpg'
import { StatefulTooltip } from 'baseui/tooltip'
import { customAmplitude } from '@/utils/customAmplitude'
import { StepType, useTour } from '@reactour/tour'
import { ShowModalType } from '@/scenes/engine/common/constants'
import MagicBgIntroThumb from '@assets/video/intro-magic-bg-thumb.mp4'
import VideoIntroRemove from '@assets/video/intro-remove.mp4'
import VideoIntroMagicBg from '@assets/video/intro-magic-bg.mp4'
import { BUBBLE_TUTORIAL_HEIGHT, BUBBLE_TUTORIAL_WIDTH, createCloneRemoveObjectText, setTourState } from '@/utils/tutorial'
// @ts-ignore
import firstStepAnimation from '@/assets/lottie/first_step_tutorial.lottie';
import { DotLottiePlayer } from '@dotlottie/react-player'

const WrapImage = styled('div', (props: any) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  alignItems: 'center',
  cursor: 'pointer',
  zIndex: 1,
  ':hover div': {
    // display: 'block', doing it on index.css
    background: 'rgb(0,0,0,0.2)',
  },
  ':active div': {
    // display: 'block', doing it on index.css
    background: 'rgb(0,0,0,0.4)',
  },
  ...props.style,
}))

const OPEN_TUTORIAL_TIME_DURATION = 400

function Image() {
  const { editor } = useEditorContext()
  const removeEditor = useRemoveEditorContext().editor
  const { activeObject, canvas } = useEditorContext() as any
  const [isOpenAdjust, setIsOpenAdjust] = useState(false)
  const [isOpenOutline, setIsOpenOutline] = useState(false)
  const [isOpenShadow, setIsOpenShadow] = useState(false)
  const [isBoxShadow, setIsBoxShadow] = useState(false)
  const [filterActiveId, setFilterActiveId] = useState(null)

  const [isOpenFill, setIsOpenFill] = useState(false)
  const [firstOpenFill, setFirstOpenFill] = useState(false)
  const [preObj, setPreObj] = useState(null)
  const [hoverRemoveBgBtn, setHoverRemoveBgBtn] = useState(false)
  const [hoverRemoveObjBtn, setHoverRemoveObjBtn] = useState(false)
  const [hoverMagicBtn, setHoverMagicBtn] = useState(false)

  const hasPremium = useSelector(selectUserIsPremium)
  const dispatch = useAppDispatch()

  const [css] = useStyletron()

  const {
    setRemovingBg,
    removingBg,
    removeBgSuccess,
    setRemoveBgSuccess,
    setRemoveBgAbortController,
    setToolType,
    setIsOpenPixelManipulationWithAnimation,
    isOpenTutorial,
    setShowModalType,
    popoverActive,
    setPopoverActive,
    setCanCancelRemoveBg,
    isOpenInspector
  } = useAppContext()
  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps } = useTour() 

  useEffect(() => {
    globalThis.startExpanding = false
    globalThis.endAnimation = false
  }, [])

  const removeBg = async () => {
    // @ts-ignore
    window.dataLayer.push({ event: 'remove_bg_click'});
    editor.handlers.transactionHandler.save()
    setRemoveBgSuccess(false)
    setRemovingBg(true)
    let abortController = new AbortController()
    setRemoveBgAbortController(abortController)

    // drawParticles()
    setTimeout(async () => {
      await editor.handlers.objectsHandler.removeBg(activeObject, abortController.signal)
      setTimeout(() => {
        setRemoveBgSuccess(true)
      }, 100)
    }, 100)
  }

  const removeObjects = () => {
    // @ts-ignore
    window.dataLayer.push({ event: 'remove_object_click'});
    if (!hasPremium) {
      // hidden intro video
      // dispatch(
      //   setIntroVideoDetail({
      //     isOpen: true,
      //     title: t('Keep what’s important, remove the rest'),
      //     subtitle: t('Easily remove people and objects.'),
      //     videoSrc: 'remove',
      //     poster: '',
      //     onClickButton: () => {
      //       const eventProperties = {
      //         Source: 'BtSubscriptionHeal',
      //         Type: 'Standard',
      //       }
      //       customAmplitude('Premium Prompt', eventProperties)
      //       // @ts-ignore
      //       window.dataLayer.push({event: 'premium_prompt',...eventProperties});
      //       dispatch(
      //         setOpenModalTryPremium({
      //           isOpen: true,
      //           source: 'BtSubscriptionHeal',
      //           callback: () => {
      //             setToolType('Remove')
      //             setIsDisplayedIntro(true)
      //             customAmplitude('Selected tool', {
      //               Tool: 'bazaart.remove',
      //             })
      //           },
      //         })
      //       )
      //     },
      //   })
      // )
      dispatch(
        setOpenModalTryPremium({
          isOpen: true,
          source: 'BtSubscriptionHeal',
          callback: () => {
            setToolType('Remove')
            customAmplitude('Selected tool', {
              Tool: 'bazaart.remove',
            })
          },
          video: VideoIntroRemove
        })
      )
      const eventProperties = {
        Source: 'BtSubscriptionHeal',
        Type: 'Standard',
      }
      customAmplitude('Premium Prompt', eventProperties)
    } else {
      // show overlay to prevent user click on canvas
      let containerOverlay = document.getElementById('container-overlay-remove')
      if(containerOverlay) {
        containerOverlay.style.display = 'block'
      }
      setToolType('Remove')
      customAmplitude('Selected tool', {
        Tool: 'bazaart.remove',
      })
    }
  }
  const onEnhanceImage = () => {
    if (!hasPremium) {
      // intro video
      dispatch(
        setIntroVideoDetail({
          isOpen: true,
          title: 'Keep what’s important, remove the rest',
          subtitle: 'Easily remove people and objects.',
          videoSrc: 'remove',
          poster: '',
          onClickButton: () => {
            const eventProperties = {
              Source: 'BtSubscriptionHeal',
              Type: 'Standard',
            }
            customAmplitude('Premium Prompt', eventProperties)
            // @ts-ignore
            window.dataLayer.push({event: 'premium_prompt',...eventProperties});
            dispatch(
              setOpenModalTryPremium({
                isOpen: true,
                source: '',
                callback: () => {
                  setToolType('Enhance')
                  // customAmplitude('Selected tool', {
                  //   Tool: 'bazaart.remove',
                  // })
                },
              })
            )
          },
        })
      )
      // customAmplitude('Intro Prompt', {
      //   Feature: 'bazaart.intro.remove',
      // })
    } else {
      // show overlay to prevent user click on canvas
      let containerOverlay = document.getElementById('container-overlay-remove')
      if(containerOverlay) {
        containerOverlay.style.display = 'block'
      }
      setToolType('Enhance')
      // customAmplitude('Selected tool', {
      //   Tool: 'bazaart.enhance',
      // })
    }
  }

  useEffect(() => {
    if (removeBgSuccess) {
      setRemovingBg(false)
      globalThis.endAnimation = true
      let containerOverlay = document.getElementById('container-overlay-remove')
      if(containerOverlay) {
        containerOverlay.style.display = 'none'
      }
      setRemoveBgSuccess(false)
      const textRemoveBg = document.getElementById('text-remove-bg')
      textRemoveBg.innerText = t('Just a sec...')
    }
  }, [removeBgSuccess])

  useEffect(() => {
    if(removingBg) {
      globalThis.endAnimation = false
    }
  }, [removingBg])

  const expandParticles = () => {
    globalThis.startExpanding = true
  }

  useEffect(() => {
    if (removingBg) {
      let containerOverlay = document.getElementById('container-overlay-remove')
      if(containerOverlay) {
        containerOverlay.style.display = 'block'
      }
      drawParticles()
    }
  }, [removingBg])

  const updateOptions = (object: fabric.IObjectOptions) => {
    // @ts-ignore
    if (object && object.mask) {
      expandParticles()
    }
  }

  useEffect(() => {
    if (!editor) return
    const handleChanges = () => {
      if (activeObject) {
        updateOptions(activeObject)
      } else {
        let object = editor ? editor.handlers.objectsHandler.getDefaultStaticImageObject() : null
        if (object) {
          updateOptions(object)
        }
      }
    }
    editor.on('history:changed', handleChanges)
    return () => {
      editor.off('history:changed', handleChanges)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  useEffect(() => {
    updateOptions(activeObject)

    if (preObj && preObj.id !== activeObject.id) {
      setIsOpenFill(false)
      setFirstOpenFill(false)
      setPreObj(activeObject)
    } else {
      setPreObj(activeObject)
    }
  }, [activeObject])

  const drawParticles = () => {
    let radius = 48
    let particleSize = 7
    let particleVelocity = 1.6
    let particleRandomVelocity = 0.5
    let particleMultiplier = 2.2
    let iteration = 0
    let expendIteration = 60 * 5

    let radiusSqr = radius * radius

    let canvas = document.querySelector('#canvas-animation') as HTMLCanvasElement
    let ctx = canvas.getContext('2d')

    // (onresize = function(){
    // canvas.width = 200
    // canvas.height = 200

    ctx.setTransform(1, 0, 0, 1, canvas.width / 2, canvas.height / 2)
    // })();

    let particles = (function () {
      var max = (1 << 16) - 1,
        length = 0,
        nextIndex = 0,
        map = new Uint16Array(max),
        x = new Float32Array(max),
        y = new Float32Array(max),
        vx = new Float32Array(max),
        vy = new Float32Array(max),
        life = new Uint16Array(max),
        startLife = new Uint16Array(max),
        each = function (fn) {
          for (var i = 0; i < length; i++) {
            fn(map[i], i)
          }
        },
        remove = function (i) {
          length = length >= 0 ? length - 1 : 0
          map[i] = map[length]
        }
      return {
        create: function (conf) {
          x[nextIndex] = conf.x || 0
          y[nextIndex] = conf.y || 0
          vx[nextIndex] = conf.vx || 0
          vy[nextIndex] = conf.vy || 0
          life[nextIndex] = conf.life || 0
          startLife[nextIndex] = conf.life
          map[length] = nextIndex
          length = length === max ? 0 : length + 1
          nextIndex = nextIndex === max ? 0 : nextIndex + 1
        },
        update: function () {
          each(function (i, id) {
            x[i] += vx[i]
            y[i] += vy[i]
            var modifier = globalThis.startExpanding ? i / 10 : 1
            var d = x[i] * x[i] + y[i] * y[i],
              dx = (x[i] * (1 + radius / d)) / 1000 / modifier,
              dy = (y[i] * (1 + radius / d)) / 1000 / modifier
            vx[i] += d < radiusSqr ? dx : -dx
            vy[i] += d < radiusSqr ? dy : -dy
            if (--life[i] < 0) remove(id)
          })
        },
        draw: function () {
          ctx.fillStyle = '#FFFFFF'
          ctx.globalCompositeOperation = 'screen'
          ctx.lineWidth = 2
          ctx.strokeStyle = '#FFFFFF'

          each(function (i) {
            var f = life[i] / startLife[i]
            const alpha = (f *= globalThis.startExpanding ? 1 - iteration / expendIteration : 1)

            ctx.globalAlpha = alpha
            ctx.beginPath()
            ctx.arc(x[i], y[i], particleSize * (1 - f), 0, Math.PI * 2)
            ctx.fill()
          })
        },
      }
    })()

    let loop = function () {
      ctx.save()
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.restore()
      if (!globalThis.startExpanding) {
        for (var i = 0; i < particleMultiplier; i++) {
          var a = Math.random() * Math.PI * 2,
            cos = Math.cos(a),
            sin = Math.sin(a)
          particles.create({
            x: cos * radius,
            y: sin * radius,
            vx: -sin * particleVelocity + (Math.random() - 0.5) * 2 * particleRandomVelocity,
            vy: cos * particleVelocity + (Math.random() - 0.5) * 2 * particleRandomVelocity,
            life: 100,
          })
        }
      }

      if (globalThis.startExpanding) {
        radius *= 1.1
        radiusSqr = radius * radius
        iteration++
      }
      particles.update()
      particles.draw()

      // if (iteration === expendIteration) {
      //   setRemovingBg(false)
      //   // editor.handlers.objectsHandler.deselect()
      //   return
      // }
      if (globalThis.endAnimation) {
        // setRemovingBg(false)
        // editor.handlers.objectsHandler.deselect()
        globalThis.endAnimation = false
        return
      }
      requestAnimationFrame(loop)
    }

    requestAnimationFrame(loop)
  }

  const updateBackgroundColor = (color: string) => {
    const activeSticker = activeObject as fabric.Image
    activeSticker.filters = []
    const blendColor = new fabric.Image.filters.BlendColor({
      color: color,
      mode: 'tint',
      alpha: 1,
    })
    activeSticker.filters.push(blendColor)
    activeSticker.applyFilters()
    canvas.requestRenderAll()
  }

  useEffect(() => {
    setTourState({isOpen: isOpenTutorial, setSteps, setIsOpen})
    if(!isOpenTutorial) { return }
    const handleTutorialStep1 =() => {
      createCloneRemoveObjectText()
      const rootElement = document.querySelector('#root') as HTMLElement
      const descElm = document.getElementById('desc-remove-obj-clone')
      const spotlightElement = document.getElementById('remove-obj-media-content') as HTMLElement
      const spotlightBB = spotlightElement.getBoundingClientRect()
      const step: StepType = {
        selector: '#remove-obj-media-content',
        content: 
          <>
            <p style={{fontSize: '16px', fontWeight: '600', lineHeight: '24px', marginTop: '4px'}}>{t('Click remove objects')}</p>
            <div style={{ position: 'absolute', left: 'calc(100% + 23px)', top: '50%', transform: 'translateY(-50%)', borderLeft: '12px solid #fff',borderTop: '12px solid transparent', borderBottom: '12px solid transparent'}}></div>
            <p className={css({
              width: 'fit-content',
              ...lightTheme.typography.Small12medium, 
              color: lightTheme.colors.grayScale400, 
              marginTop: '8px', 
              cursor: 'pointer',
              ':hover': {
                color: lightTheme.colors.contentPrimary
              },
              ':active': {
                color: lightTheme.colors.blackGray
              }
            })}
              onClick={(e) => {
                setShowModalType(ShowModalType.CANCEL_TUTORIAL)
                setIsOpen(false)
                if (descElm) {
                  rootElement.removeChild(descElm)
                }
              }}
              >{t('Skip')}</p>
          </>,
        position: [spotlightBB.left - BUBBLE_TUTORIAL_WIDTH - 15 , spotlightBB.top - (Math.abs(BUBBLE_TUTORIAL_HEIGHT - spotlightBB.height) / 2)],
        styles: {
          popover: (base) => ({
            ...base,
            width: `${BUBBLE_TUTORIAL_WIDTH}px`,
            height: `${BUBBLE_TUTORIAL_HEIGHT}px`,
            borderRadius: '12px',
            padding: '24px',
            transition: 'none'
          }),
          maskArea: (base) => ({
            ...base,
            rx: 8,
          })
        }
      }
      setSteps([...steps, step])
      setTourState({currentStep: 0, steps: [...steps, step], setSteps, setIsOpen})
      setCurrentStep(0)
      setIsOpen(true)
      if(popoverActive) {
        setPopoverActive(null)
      }

    }
    if(isOpenInspector) {
      setTimeout(() => {
        handleTutorialStep1()
      }, OPEN_TUTORIAL_TIME_DURATION)
      return
    }
    handleTutorialStep1()
  }, [isOpenTutorial])

  const { t } = useTranslation()
  const onClickErase = () => {
    editor.handlers.zoomHandler.preZoomBeforeErase = editor.handlers.canvasHandler.canvas.getZoom()
    editor.handlers.scrollbarHandler.preVptBeforeErase = editor.handlers.canvasHandler.canvas.viewportTransform

    removeEditor.handlers.zoomRemoveHandler.minZoom = editor.handlers.zoomHandler.minZoom
    removeEditor.handlers.zoomRemoveHandler.maxZoom = editor.handlers.zoomHandler.maxZoom
    removeEditor.handlers.zoomRemoveHandler.baseStep = editor.handlers.zoomHandler.baseStep
    removeEditor.handlers.zoomRemoveHandler.sliderStep = editor.handlers.zoomHandler.sliderStep
    // editor.handlers.zoomHandler.zoomToFit()
    setToolType('Eraser')
    customAmplitude('Selected tool', {
      Tool: 'bazaart.cutout.finger',
    })
  }
  

  const onMagicBg = () => {
    if (!hasPremium) {
      dispatch(
        setOpenModalTryPremium({
          isOpen: true,
          source: 'BtSubscriptionMagicBackground',
          callback: () => {
            openMagicBgTool()
          },
          video: VideoIntroMagicBg
        })
      )
      const eventProperties = {
        Source: 'BtSubscriptionMagicBackground',
        Type: 'Standard',
      }
      customAmplitude('Premium Prompt', eventProperties)
    } else {
      openMagicBgTool()
      // customAmplitude('Magic Backgrounds')
    }
  }

  const openMagicBgTool = () => {
    editor.handlers.zoomHandler.preZoomBeforeErase = editor.handlers.canvasHandler.canvas.getZoom()
    editor.handlers.scrollbarHandler.preVptBeforeErase =
    editor.handlers.canvasHandler.canvas.viewportTransform
    editor.handlers.zoomHandler.zoomToFit()
    setToolType('MagicBg')
    setCanCancelRemoveBg(false)
    customAmplitude('Selected tool', {
      Tool: 'bazaart.magic_bg',
      'Layer type': 'bazaart.item.magic_background'
    })
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <div
        style={{
          boxSizing: 'border-box',
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          width: '340px',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <HeadingInspector
          hasBoxShadow={isBoxShadow}
          hasNavigation={false}
          title="Photo"
          hasClose={true}
          handleClose={() => {
            canvas.discardActiveObject()
          }}
        ></HeadingInspector>
        <AutoScroll
          handleScroll={e => {
            if (e.target.scrollTop > 0) {
              setIsBoxShadow(true)
            } else {
              setIsBoxShadow(false)
            }
          }}
        >
          {/* magic tool */}
          <div style={{ width: '300px', paddingRight: '8px' }}>
            <div
              style={{
                width: '292px',
                height: '26px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '8px',
              }}
            >
              <p
                style={{
                  ...lightTheme.typography.Small14Semibold,
                  color: lightTheme.colors.text.text_black_title,
                }}
              >
                {t('Magic tools')}
              </p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '16px', flexWrap: 'wrap' }}>
              {/* remove bg */}
              <WrapImage
                onClick={() => removeBg()}
                id="btn-remove-bg"
                onMouseEnter={() => {
                  setHoverRemoveBgBtn(true)
                }}
                onMouseLeave={() => {
                  setHoverRemoveBgBtn(false)
                }}
              >
                <StatefulTooltip
                  onMouseEnterDelay={10}
                  onMouseLeaveDelay={10}
                  accessibilityType={'tooltip'}
                  content={t('Remove the background from any photo')}
                  showArrow
                  placement={'top'}
                  popoverMargin={4}
                  overrides={{
                    Inner: {
                      style: ({ $theme }) => ({
                        fontSize: '12px',
                        fontWeight: 400,
                        color: '#fff',
                        background: lightTheme.colors.blackGray,
                        padding: '9px 14px',
                        textAlign: 'center',
                        letterSpacing: '-0.006em',
                      }),
                    },
                    Body: {
                      style: ({ $theme }) => ({
                        zIndex: 110,
                        background: lightTheme.colors.blackGray,
                        width: '170px',
                        borderRadius: '8px',
                      }),
                    },
                    Arrow: {
                      style: ({ $theme }) => ({
                        zIndex: 110,
                        background: lightTheme.colors.blackGray,
                      }),
                    },
                  }}
                >
                  <div style={{ borderRadius: '8px', width: '138px', height: '92px', position: 'relative' }}>
                    {hoverRemoveBgBtn ? (
                      <video
                        src={RemoveBgIntro}
                        width="100%"
                        height="100%"
                        style={{
                          position: 'absolute',
                          inset: 0,
                          width: '100%',
                          height: '100%',
                          zIndex: 2,
                          borderRadius: '8px',
                        }}
                        autoPlay
                        muted
                        loop
                        poster={RemoveBgImage}
                      >
                        <source src={RemoveBgIntro} type='video/mp4; codecs=hevc"' />
                      </video>
                    ) : (
                      <></>
                    )}
                    <img
                      src={RemoveBgImage}
                      alt="remove-bg"
                      style={{
                        position: 'absolute',
                        inset: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1,
                        borderRadius: '8px',
                      }}
                    />
                  </div>
                </StatefulTooltip>
                <p style={{ ...lightTheme.typography.Small11medium, color: '#666666', margin: 0 }}>
                  {t('Remove background')}
                </p>
              </WrapImage>
              {/* remove objects */}
              <WrapImage
                onClick={() => {
                  // @ts-ignore
                  window.dataLayer.push({ event: 'remove_from_editor' })
                  
                  if (isOpen) {
                    setIsOpenPixelManipulationWithAnimation(false)
                    setToolType('Remove')
                    const rootElement = document.querySelector('#root') as HTMLElement
                    const descElm = document.getElementById('desc-remove-obj-clone')
                    setTourState({currentStep: 1})
                    if (descElm) {
                      rootElement.removeChild(descElm)
                    }
                    customAmplitude('Tutorial Step Completed', { 'Tutorial Step Number' : 1 })
                  } else {
                    removeObjects()
                  }
                }}
                id="btn-remove-obj"
                onMouseEnter={e => {
                  setHoverRemoveObjBtn(true)
                }}
                onMouseLeave={e => {
                  setHoverRemoveObjBtn(false)
                }}
              >
                <StatefulTooltip
                  onMouseEnterDelay={10}
                  onMouseLeaveDelay={10}
                  accessibilityType={'tooltip'}
                  content={(isOpen || isOpenTutorial) ? null : t('Easily remove people and objects')}
                  showArrow
                  placement={'top'}
                  popoverMargin={4}
                  overrides={{
                    Inner: {
                      style: ({ $theme }) => ({
                        fontSize: '12px',
                        fontWeight: 400,
                        color: '#fff',
                        background: lightTheme.colors.blackGray,
                        padding: '9px 14px',
                        textAlign: 'center',
                        letterSpacing: '-0.006em',
                      }),
                    },
                    Body: {
                      style: ({ $theme }) => ({
                        zIndex: 110,
                        background: lightTheme.colors.blackGray,
                        width: '170px',
                        borderRadius: '8px',
                      }),
                    },
                    Arrow: {
                      style: ({ $theme }) => ({
                        zIndex: 110,
                        background: lightTheme.colors.blackGray,
                      }),
                    },
                  }}
                >
                  <div style={{ borderRadius: '8px', width: '138px', height: '92px', position: 'relative', pointerEvents: (isOpen || isOpenTutorial) ? 'none' : 'auto' }}>
                    {hoverRemoveObjBtn ? (
                      <video
                        src={RemoveObjectsIntro}
                        width="100%"
                        height="100%"
                        style={{
                          position: 'absolute',
                          inset: 0,
                          width: '100%',
                          height: '100%',
                          zIndex: 2,
                          borderRadius: '8px',
                        }}
                        autoPlay
                        muted
                        loop
                        poster={RemoveObjectsImage}
                        id="remove-obj-media-content"
                      >
                        <source src={RemoveObjectsIntro} type='video/mp4; codecs=hevc"' />
                      </video>
                    ) : (
                      <></>
                    )}
                    <img
                      src={RemoveObjectsImage}
                      alt="remove-objects"
                      style={{
                        position: 'absolute',
                        inset: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1,
                        borderRadius: '8px',
                      }}
                      id="remove-obj-media-content"
                    />
                    {isOpen && currentStep === 0 ? (
                      <DotLottiePlayer
                        src={firstStepAnimation}
                        autoplay
                        loop
                        style={{
                          position: 'absolute',
                          inset: 0,
                          width: '100%',
                          height: '100%',
                          zIndex: 2,
                          borderRadius: '8px',
                          padding: '6px 29px'
                        }}
                      ></DotLottiePlayer>
                    ) : null}
                  </div>
                </StatefulTooltip>

                <p
                  style={{ ...lightTheme.typography.Small11medium, color: '#666666', margin: 0 }}
                  id="desc-remove-obj-btn"
                >
                  {t('Remove objects')}
                </p>
                {!hasPremium && !isOpenTutorial ? (
                  <span style={{ position: 'absolute', top: '4px', right: '4px', zIndex: 3 }}>
                    <Icons.PremiumIcon />
                  </span>
                ) : null}
              </WrapImage>
              {/* magic background */}
              <WrapImage
                onClick={() => {
                  onMagicBg()
                }}
                id="btn-magic-background"
                onMouseEnter={e => {
                  setHoverMagicBtn(true)
                }}
                onMouseLeave={e => {
                  setHoverMagicBtn(false)
                }}
              >
                <StatefulTooltip
                  onMouseEnterDelay={10}
                  onMouseLeaveDelay={10}
                  accessibilityType={'tooltip'}
                  content={'Create studio-quality photos'}
                  showArrow
                  placement={'bottom'}
                  popoverMargin={4}
                  overrides={{
                    Inner: {
                      style: ({ $theme }) => ({
                        fontSize: '12px',
                        fontWeight: 400,
                        color: '#fff',
                        background: '#000',
                        padding: '9px 14px',
                        textAlign: 'center',
                        letterSpacing: '-0.006em',
                      }),
                    },
                    Body: {
                      style: ({ $theme }) => ({
                        zIndex: 110,
                        background: '#000',
                        width: '170px',
                        borderRadius: '8px',
                      }),
                    },
                    Arrow: {
                      style: ({ $theme }) => ({
                        zIndex: 110,
                        background: '#000',
                      }),
                    },
                  }}
                >
                  <div style={{ borderRadius: '8px', width: '138px', height: '92px', position: 'relative' }}>
                    {hoverMagicBtn ? (
                      <video
                        src={MagicBgIntroThumb}
                        width="100%"
                        height="100%"
                        style={{
                          position: 'absolute',
                          inset: 0,
                          width: '100%',
                          height: '100%',
                          zIndex: 2,
                          borderRadius: '8px',
                        }}
                        autoPlay
                        muted
                        loop
                        poster={MagicBackgroundImage}
                      >
                        <source src={MagicBgIntroThumb} type='video/mp4; codecs=hevc"' />
                      </video>
                    ) : (
                      <></>
                    )}
                    <img
                      src={MagicBackgroundImage}
                      alt="magic-background"
                      style={{
                        position: 'absolute',
                        inset: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1,
                        borderRadius: '8px',
                      }}
                    />
                  </div>
                </StatefulTooltip>

                <p style={{ ...lightTheme.typography.Small11medium, color: '#666666', margin: 0 }}>
                  {t('Magic Background')}
                </p>
                {!hasPremium ? (
                  <span style={{ position: 'absolute', top: '4px', right: '4px', zIndex: 3 }}>
                    <Icons.PremiumIcon />
                  </span>
                ) : null}
              </WrapImage>
              {/* enhance background */}
              {/* <WrapImage
                onClick={() => {
                  onEnhanceImage()
                }}
                id="btn-enhance-image"
              >
                <img src={EnhanceImage} alt="enhance-obj" style={{ borderRadius: '8px', width: '138px', height: '92px' }} />
                <StatefulTooltip
                  onMouseEnterDelay={10}
                  onMouseLeaveDelay={10}
                  accessibilityType={'tooltip'}
                  // content={''}
                  showArrow
                  placement={'top'}
                  popoverMargin={4}
                  overrides={{
                    Inner: {
                      style: ({ $theme }) => ({
                        fontSize: '12px',
                        fontWeight: 400,
                        color: '#fff',
                        background: '#000',
                        padding: '9px 14px',
                        textAlign: 'center',
                        letterSpacing: '-0.006em',
                      }),
                    },
                    Body: {
                      style: ({ $theme }) => ({
                        zIndex: 110,
                        background: '#000',
                        width: '170px',
                        borderRadius: '8px',
                      }),
                    },
                    Arrow: {
                      style: ({ $theme }) => ({
                        zIndex: 110,
                        background: '#000',
                      }),
                    },
                  }}
                >
                  <div
                    className="overlay"
                    style={{
                      width: '100%',
                      height: '92px',
                      position: 'absolute',
                      inset: 0,
                      display: 'none',
                      background: 'rgb(0,0,0,0.1)',
                      borderRadius: '8px',
                    }}
                  ></div>
                </StatefulTooltip>

                <p style={{ ...lightTheme.typography.Small11medium, color: '#666666', margin: 0 }}>
                  Enhance
                </p>
                {!hasPremium ? (
                  <span style={{ position: 'absolute', top: '4px', right: '4px' }}>
                    <Icons.PremiumIcon />
                  </span>
                ) : null}
              </WrapImage> */}
            </div>
          </div>

          <div style={{ gap: '16px' }} className="mt-50">
            {/* <ButtonCustom
              onClick={() => editor.handlers.objectsHandler.toggleCrop()}
              startEnhancer={<Icons.Crop />}
              kind={KIND.tertiary}
              type={SizeButton.LARGE}
            >
              <LabelLarge>Crop</LabelLarge>
            </ButtonCustom>  */}
            <ButtonCustom
              startEnhancer={<Icons.Erase />}
              kind={KIND.tertiary}
              type={SizeButton.LARGE}
              onClick={onClickErase}
            >
              <LabelLarge>{t('Erase')}</LabelLarge>
            </ButtonCustom>
          </div>

          <div>
            <BtnOpenNewSlide
              handleOnClick={() => {
                setIsOpenOutline(true)
                const eventProperties = {
                  Tool: 'bazaart.style.outline',
                }
                customAmplitude('Selected tool', eventProperties)
              }}
              startEnhancer={<Icons.Outline />}
              endEnhancer={<Icons.BackRight size={24} />}
              nameAction={t('Outline')}
            ></BtnOpenNewSlide>
            <BtnOpenNewSlide
              handleOnClick={() => {
                setIsOpenShadow(true)
                const eventProperties = {
                  Tool: 'bazaart.style.shadow',
                }
                customAmplitude('Selected tool', eventProperties)
              }}
              startEnhancer={<Icons.Shadow size={24} />}
              endEnhancer={<Icons.BackRight size={24} />}
              nameAction={t('Shadow')}
              style={{
                marginTop: '16px',
              }}
            ></BtnOpenNewSlide>
            <BtnOpenNewSlide
              startEnhancer={<Icons.Adjust size={24} />}
              endEnhancer={<Icons.BackRight size={24} />}
              handleOnClick={() => {
                setIsOpenAdjust(true)
                const eventProperties = {
                  Tool: 'bazaart.adjust',
                }
                customAmplitude('Selected tool', eventProperties)
              }}
              style={{
                marginTop: '16px',
              }}
              nameAction={t('Adjust')}
            ></BtnOpenNewSlide>
          </div>
          {(isOpenFill || firstOpenFill) && preObj?.id === activeObject.id ? (
            <Filter
              isOpen={isOpenFill}
              setIsOpen={setIsOpenFill}
              filterObject={activeObject}
              filterActiveId={filterActiveId}
              setFilterActiveId={setFilterActiveId}
            />
          ) : null}
          <GridFilter
            handleSelectItem={() => {}}
            actionToggle={() => {
              let wrapFilterPanel = document.getElementById('wrap-filter-panel')
              if (wrapFilterPanel) {
                wrapFilterPanel.style.display = 'flex'
                wrapFilterPanel.style.right = '0px'
              } else {
                setIsOpenFill(true)
                setFirstOpenFill(true)
              }
              const eventProperties = {
                Tool: 'bazaart.style.filter.see_all',
              }
              customAmplitude('Selected tool', eventProperties)
            }}
            filterObject={activeObject}
            filterActiveId={filterActiveId}
            setFilterActiveId={setFilterActiveId}
          />
          <Opacity />
          {/* <Blending /> */}
          <Transform />
        </AutoScroll>
        {/* <div>
        <Button
          onClick={() => setActiveSubMenu(SubMenuType.COLOR)}
          size={SIZE.compact}
          kind={KIND.tertiary}
          shape={SHAPE.square}
        >
          <Icons.FillColor size={24} color="#000000" />
        </Button>
        <Button
          onClick={() => editor.handlers.objectsHandler.toggleCrop()}
          size={SIZE.compact}
          kind={KIND.tertiary}
          shape={SHAPE.square}
        >
          <Icons.FillColor size={24} color="#00ff00" />
        </Button>
        <Animate />
      </div>
      <Common />
      <Adjust />
      <Filter />
      <Shadow/> */}
        <Adjust isOpen={isOpenAdjust} setIsOpenAdjust={setIsOpenAdjust} />
        <Outline isOpen={isOpenOutline} setIsOpenOutline={setIsOpenOutline} />
        <Shadow isOpen={isOpenShadow} setIsOpenShadow={setIsOpenShadow} />
        <PixelManipulation />
      </div>
      {/* {loaded ? (
        <></>
      ) : ( */}

      {/* )} */}
    </ThemeProvider>
  )
}

export default Image