import { useContext, useEffect, useState } from 'react'
import { lightTheme } from '@/customTheme'
import { ThemeProvider } from 'baseui'
import Icons from '../../../Icons'
import useAppContext from '@/hooks/useAppContext'
import { EditorContext, RemoveEditorContext } from '@/scenes/engine'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND } from 'baseui/button'
import ModalCustom from '../../../Modal/Modal'
import { useAppDispatch } from '@/store/store'
import { setBrushSize } from '@/store/slices/removeTool/action'
import RemoveTool from '../RemoveTool'
import EraserTool from '../EraserTool'
import { MediaImageRepository } from '@scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@/scenes/engine/objects/media-repository/media_image_type'
import EnhanceTool from './EnhanceTool'
import MagicBgTool from '../MagicBgTool'
import { ObjectType, ScaleType } from '@/scenes/engine/common/constants'
import { nanoid } from 'nanoid'
import MagicBgObjectHandler from '@/scenes/engine/handlers/remove-handler/MagicBgObjectHandler'
import { customAmplitude } from '@/utils/customAmplitude'
import { useTranslation } from 'react-i18next'
import { StepType, useTour } from '@reactour/tour'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { selectSuggestedImagesFromServer } from '@/store/slices/magicBgTool/selector'
import { useSelector } from 'react-redux'
import { setSuggestedImagesFromServer } from '@/store/slices/magicBgTool/action'
import { Point } from '@/scenes/engine/objects/media-repository/point'
import { useStyletron } from 'styletron-react'
import { BUBBLE_TUTORIAL_HEIGHT, BUBBLE_TUTORIAL_WIDTH, getTourState, setTourState } from '@/utils/tutorial'

function PixelManipulation() {
  const dispatch = useAppDispatch()
  const [historyStatus, setHistoryStatus] = useState({ hasUndo: false, hasRedo: false })
  const [isOpenPopupDiscard, setIsOpenPopupDiscard] = useState(false)
  const { isOpenPixelManipulationObject, toolType, setToolType, setCanCancelRemoveBg, setIsOpenPixelManipulationWithAnimation, setRemovingBg, setRemoveBgSuccess, setShowModalType, removeBgAbortController, cancelPixelManipulation } = useAppContext()
  const { canvas } = useContext(RemoveEditorContext)
  const { editor, activeObject } = useContext(EditorContext)
  const removeEditor = useContext(RemoveEditorContext).editor
  const duration = 800
  const [isOpenSideBar, setIsOpenSideBar] = useState(false)
  const [isTextTipInit, setIsTextTipInit] = useState(false)
  const [isTextTipError, setIsTextTipError] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)
  const { setIsLoadedJsonSuccess } = useAppContext()
  const suggestedImagesFromServer = useSelector(selectSuggestedImagesFromServer)

  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps } = useTour()
  const [css] = useStyletron()

  useEffect(() => {
    setIsOpenSideBar(isOpenPixelManipulationObject)
    removeEditor.handlers.canvasRemoveHandler.isOpenPixelManipulationObject = isOpenPixelManipulationObject
    editor.handlers.canvasHandler.isOpenPixelManipulationObject = isOpenPixelManipulationObject
    // TODO : check better way to solve it - 
    if (removeEditor.handlers.pixelManipulationObjectHandler) {
      removeEditor.handlers.pixelManipulationObjectHandler.activeObject = activeObject
    }
    const editorCanvas = document.getElementById('uibox-editor-container')
    if (isOpenPixelManipulationObject) {
      // editorCanvas.style.zIndex = '-1'
      // remove overlay
      if(toolType === 'Remove' || toolType === 'Eraser') {
        setTimeout(() => {
          let containerOverlay = document.getElementById('container-overlay-remove')
          if(containerOverlay) {
            containerOverlay.style.display = 'none'
          }
        }, 800)
      }
      if(toolType !== 'Enhance' && toolType !== 'MagicBg') {
        removeEditor.handlers.eventsRemoveHandler.handleCursor();
      }
      removeEditor.handlers.eventsRemoveHandler.initialize();
      editor.handlers.scrollbarHandler.hideScrollbar()
    } else {
      editorCanvas.style.zIndex = '1'
      setIsTextTipInit(false)
      setIsTextTipError(false)
      setCanCancelRemoveBg(false)
      if(toolType !== 'Eraser') {
        let canvasRemoveTool = document.getElementById('wrap-canvas-remove-tool')
        if (canvasRemoveTool) {
          canvasRemoveTool.style.zIndex = '-1'
        }
      }
      if (removeEditor.handlers.pixelManipulationObjectHandler?.activeObject) {
        editor.handlers.canvasHandler.canvas.setActiveObject(removeEditor.handlers.pixelManipulationObjectHandler.activeObject)
      }
      removeEditor.handlers.eventsRemoveHandler.destroy();
    }
  }, [isOpenPixelManipulationObject])

  useEffect(() => {
    const handleHistoryChange = (data: any) => {
      setHistoryStatus({ ...historyStatus, hasUndo: data.hasUndo, hasRedo: data.hasRedo })
      removeEditor.handlers.pixelManipulationObjectHandler.activeObject = editor.handlers.canvasHandler.canvas.getActiveObject()
    }
    if (removeEditor) {
      removeEditor.on('remove-history:changed', handleHistoryChange)
      removeEditor.on('remove-tool:error', () => {
        setIsTextTipInit(false)
        setIsTextTipError(true)
        setTimeout(() => {
          setIsTextTipError(false)
        }, 8000)
      })
      removeEditor.on('remove-tool-animating', (value) => {
        setIsAnimating(value.isAnimating)
      })
    }
    return () => {
      if (editor) {
        removeEditor.off('remove-history:changed', handleHistoryChange)
        removeEditor.off('remove-tool:error', () => { })
        removeEditor.off('remove-tool-animating', (value) => {
          setIsAnimating(false)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeEditor])

  useEffect(() => {
    if (toolType === 'Remove' && !isOpen) {
      setIsTextTipInit(true)
      setTimeout(() => {
        setIsTextTipInit(false)
      }, 8000)
    }
  }, [toolType])

  useEffect(() => {
    const onMouseDown = (e) => {
      if(getTourState().currentStep === 1) {
        const rootElement = document.querySelector('#root') as HTMLElement
        const brushAnimation = document.querySelector('.brush-animation') as HTMLElement
        if(brushAnimation) {
          rootElement.removeChild(brushAnimation)
          customAmplitude('Tutorial Step Completed', { 'Tutorial Step Number' : 2 })
        }
      }
    }
    canvas.on('mouse:down', onMouseDown)
    return () => {
      canvas.off('mouse:down')
    }
  }, [])

  useEffect(() => {
    if (historyStatus.hasUndo && isOpen && toolType === 'Remove' && currentStep === 1) {
      const rootElement = document.querySelector('#root') as HTMLElement

      const footerActionButtons = document.querySelectorAll('#footer-action-buttons button')
      const doneButton = footerActionButtons[1]
      
      const doneTutorialBtn = createDoneTutorialButton(doneButton)
      doneTutorialBtn.addEventListener('click', () => {
        rootElement.removeChild(doneTutorialBtn)
        setIsOpen(false)
        setShowModalType(ShowModalType.GOOD_WORK_MODAL)
        const containerElement = document.querySelector(
          '#wrap-canvas-remove-tool .remove-container-class'
        ) as HTMLElement
        containerElement.style.borderRadius = '0px'  
        customAmplitude('Tutorial Step Completed', { 'Tutorial Step Number' : 3 })
      })
      rootElement.appendChild(doneTutorialBtn)
      
      const doneBtnBB = doneButton.getBoundingClientRect()

      const newStep: StepType = {
        selector: '.remove-container-class',
        position: [doneBtnBB.left - BUBBLE_TUTORIAL_WIDTH + (doneBtnBB.width * 3 / 4), doneBtnBB.top - BUBBLE_TUTORIAL_HEIGHT - 15],
        content: 
          <>
            <p style={{fontSize: '16px', fontWeight: '600', lineHeight: '24px', marginTop: '4px'}}>{t('Click when done')}</p>
            <div style={{ position: 'absolute', top: 'calc(100% + 23px)', right: '0px', borderTop: '12px solid #fff',borderLeft: '12px solid transparent', borderRight: '12px solid transparent'}}></div>
            <p className={css({
              width: 'fit-content',
              ...lightTheme.typography.Small12medium, 
              color: lightTheme.colors.grayScale400, 
              marginTop: '8px', 
              cursor: 'pointer',
              ':hover': {
                color: lightTheme.colors.contentPrimary
              },
              ':active': {
                color: lightTheme.colors.blackGray
              }
            })}  
            onClick={(e) => {
            setShowModalType(ShowModalType.CANCEL_TUTORIAL)
            setIsOpen(false)
            const doneCloneBtn = document.getElementById('done-clone-btn')
            if (doneCloneBtn) {
              doneCloneBtn.style.display = 'none'
            }
          }}>{t('Skip')}</p>
          </>,
        styles: {
          maskArea: (base) => ({
            ...base,
            rx: 10, // It's close to border-radius: 16px of the css 
          }),
          popover: (base) => ({
            ...base,
            width: `${BUBBLE_TUTORIAL_WIDTH}px`,
            height: `${BUBBLE_TUTORIAL_HEIGHT}px`,
            borderRadius: '12px',
            padding: '24px',
            transition: 'none'
          }),
        }
      }
      setTourState({steps: [...steps, newStep], currentStep: currentStep + 1})
      setSteps([...steps, newStep])
      setCurrentStep(currentStep + 1)
      customAmplitude('Tutorial Completed', { 'Tutorial Name': 'Remove', State: 'Ended'})
    }
  }, [historyStatus])

  const createDoneTutorialButton = (originalElement): HTMLElement => {
    const doneCloneBtn = originalElement.cloneNode(true) as HTMLElement
    // @ts-ignore
    doneCloneBtn.firstElementChild.style.color = '#fff'
    const originalBB = originalElement.getBoundingClientRect()
    doneCloneBtn.id = 'done-clone-btn'
    doneCloneBtn.style.position = 'fixed'
    doneCloneBtn.style.top = `${originalBB.top}px`
    doneCloneBtn.style.left = `${originalBB.left}px`
    doneCloneBtn.style.width = `${originalBB.width}px`
    doneCloneBtn.style.height = `${originalBB.height}px`
    doneCloneBtn.style.pointerEvents = 'auto'
    doneCloneBtn.style.backgroundColor = '#FF0560'
    doneCloneBtn.style.zIndex = '999999999'

    return doneCloneBtn
  }

  const handleCancelRemoveTool = async (isUpdate?) => {
    if (isUpdate) {
      if (toolType !== "Eraser") {
        setIsLoadedJsonSuccess(false)
      }
      let imageProcessing = MediaImageRepository.getInstance()._mediaImageRepositoryProcessing
      switch (toolType) {
        case 'Remove':
          let obj = canvas.getObjects()[1]
          editor.handlers.transactionHandler.save()
          // @ts-ignore
          let removeResult = obj.getElement()
          // @ts-ignore
          let currentOriginal = await MediaImageRepository.getInstance().getImage(activeObject.id, activeObject.layerAssetStateId, MediaImageType.original)
          let currentOriginalImageElement = await imageProcessing.loadImage(currentOriginal)
          
          // @ts-ignore
          let compositionOffset = new Point(activeObject.boundingBox.x * currentOriginalImageElement.width, activeObject.boundingBox.y * currentOriginalImageElement.height );
          let removeResultAfterComposition = await imageProcessing.composite(currentOriginalImageElement, removeResult, compositionOffset)

          // @ts-ignore
          await editor.handlers.objectsHandler.replaceImage(removeResultAfterComposition.src, false, false)
          // @ts-ignore
          editor.handlers.canvasHandler.canvas.renderAll()
          // @ts-ignore
          window.dataLayer.push({ event: 'remove_object_success' });
          break;
        case 'Eraser':
          const eraseLayer = canvas.getObjects()[0]
          editor.handlers.transactionHandler.save()
          // @ts-ignore
          let mask = await MediaImageRepository.getInstance().getImage(eraseLayer.id, eraseLayer.assetStateId, MediaImageType.mask)
          await editor.handlers.objectsHandler.replaceMask(mask)
          customAmplitude('Selected tool', {
            Tool: 'bazaart.cutout.finger.erase'
          })
          activeObject.set({
            // @ts-ignore
            finger: true
          })
          break;
        case 'Enhance':
          let objEnhance = canvas.getObjects()[1]
          editor.handlers.transactionHandler.save()
          // @ts-ignore
          activeObject.replaceImage(objEnhance.getElement().src, true).then(() => {
            // @ts-ignore
            objEnhance.useNewTextureNextTime()
            canvas.renderAll();
          })
          // @ts-ignore
          editor.handlers.canvasHandler.canvas.renderAll()
          break;
          case 'MagicBg':
            editor.handlers.transactionHandler.save()
            const indexMainLayer = editor.handlers.canvasHandler.canvas.getObjects().indexOf(activeObject)
            let magicBgObjectHandler = removeEditor.handlers
              .pixelManipulationObjectHandler as MagicBgObjectHandler
            let magicBgLayer = canvas.getObjects()[0]
            // remove bg for main layer
            let removeSuccess = true
            // @ts-ignore
            if (!activeObject.hasTransparency) {
              removeSuccess = await editor.handlers.objectsHandler.removeBg()
            }
            if (!removeSuccess) {
              setIsLoadedJsonSuccess(true)
            } else {
              // add magic bg
              let guid = nanoid()
              let assetStateId = nanoid()
              // @ts-ignore
              let latestImage = await magicBgObjectHandler.inpaintImage( magicBgLayer.getElement().src, magicBgObjectHandler.base64_image_mask)
              // const latestImageData = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.convertImageToBase64(latestImage)
              let resizedLatestImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.resizeBlobToMaxEdgeSize(latestImage, 1280)
              await MediaImageRepository.getInstance().storeImageBase64(guid, assetStateId, MediaImageType.original, resizedLatestImage)
              await MediaImageRepository.getInstance().storeImageBase64(guid, assetStateId, MediaImageType.latest, resizedLatestImage)
              let maskInfo = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractMask(resizedLatestImage)
              await MediaImageRepository.getInstance().storeImageBase64(
                guid,
                assetStateId,
                MediaImageType.mask,
                maskInfo.base64
              )
              let frame = editor.handlers.frameHandler.get()
              let layerSize = maskInfo.size
              let canvasAspectRatio = frame.width / frame.height
              let layerAspectRatio = layerSize.width / layerSize.height
              let width = 0.35
              if (layerAspectRatio < canvasAspectRatio) {
                width = (width * layerAspectRatio) / canvasAspectRatio
              }
              let object = {
                type: ObjectType.BAZAART_IMAGE,
                centerPoint: {
                  y: 0.5,
                  x: 0.5,
                },
                sizeOnCanvas: {
                  width: width,
                },
                transformation: {
                  horizontalFlip: false,
                  verticalFlip: false,
                },
                boundingBox: { y: 0, width: 1, height: 1, x: 0 },
                absoluteRotation: 0,
                bazaartGuid: guid,
                layerAssetStateId: assetStateId,
                scaleX: 1,
                scaleY: 1,
                isMagicBackgroundLayer: true,
                hasTransparency: maskInfo.hasTransparency,
              }
              // @ts-ignore
              let magicBgLayerOnMainCanvas = editor.handlers.canvasHandler.canvas.getObjects().find(obj => obj.isMagicBackgroundLayer)
              if (magicBgLayerOnMainCanvas) {
                if (magicBgLayerOnMainCanvas == activeObject) {
                  //@ts-ignore
                  activeObject.set({isMagicBackgroundLayer: false})
                } else {
                  editor.handlers.canvasHandler.canvas.remove(magicBgLayerOnMainCanvas)
                } 
              }
              await editor.handlers.objectsHandler.add(object, false)
              // editor.handlers.canvasHandler.canvas.getActiveObject().moveTo(indexMainLayer)
              editor.handlers.objectsHandler.scale(ScaleType.FILL, false)
              editor.handlers.objectsHandler.sendToBack()
              editor.handlers.canvasHandler.canvas.setActiveObject(activeObject)
            }
            break;
        default:
          break;
      }
      setIsLoadedJsonSuccess(true)
    }

    if (toolType === 'Eraser') {
      let canvasRemoveTool = document.getElementById('wrap-canvas-remove-tool')
      if (canvasRemoveTool) {
        canvasRemoveTool.style.zIndex = '-1'
      }
      removeEditor.handlers.pixelManipulationObjectHandler.removeEraserBackground()
    }
    removeEditor.handlers.pixelManipulationObjectHandler.reset()
    canvas.clear()
    cancelPixelManipulation(true)
    dispatch(setBrushSize(100))
    setHistoryStatus({ hasUndo: false, hasRedo: false })
    const cursorPreview = document.querySelector('.custom-cursor') as HTMLElement;
    cursorPreview.style.display = 'none'
    if(toolType === 'MagicBg') {
      /**
       * Set main canvas to the previous state because it sets to zoomtofit when magic bg tool is opened
       * Because it is opened from create page so no need to set them back
       */ 
      if(editor.handlers.zoomHandler.preZoomBeforeErase) {
        editor.handlers.zoomHandler.zoomToRatio(editor.handlers.zoomHandler.preZoomBeforeErase)
      }
      if(editor.handlers.scrollbarHandler.preVptBeforeErase) {
        editor.handlers.canvasHandler.canvas.setViewportTransform(editor.handlers.scrollbarHandler.preVptBeforeErase)
      }
      editor.handlers.zoomHandler.preZoomBeforeErase = null
      editor.handlers.scrollbarHandler.preVptBeforeErase = null
      if(removeBgAbortController) {
        removeBgAbortController.abort()
      }
      if(suggestedImagesFromServer.length) {
        dispatch(setSuggestedImagesFromServer([]))
      }
    }
    // if(toolType === 'Eraser') {
    //   editor.handlers.zoomHandler.zoomToRatio(editor.handlers.zoomHandler.preZoomBeforeErase)
    //   editor.handlers.canvasHandler.canvas.setViewportTransform(editor.handlers.scrollbarHandler.preVptBeforeErase)
    //   editor.handlers.zoomHandler.preZoomBeforeErase = null
    //   editor.handlers.scrollbarHandler.preVptBeforeErase = null
    // }
    editor.handlers.scrollbarHandler.updateScrollPosition()
  }

  const { t } = useTranslation()

  return (
    <ThemeProvider theme={lightTheme}>
      <style>
        {`
        @keyframes collapse {
          from {
            right: -356px;
          }
          to {
            right: 0px;
          }
        }
        @keyframes expand {
          from {
            right: 0px;
          }
          to {
            right: -356px;
          }
        }
        `}
      </style>
      <div
        style={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          width: '340px',
          overflow: 'hidden',
          position: 'absolute',
          top: '0px',
          bottom: '0px',
          right: isOpenSideBar ? '0px' : '-356px',
          background: '#fff',
          zIndex: 1,
          transition: 'all 0.8s ease-in-out',
          animation: isOpenSideBar ? 'expand' : 'collapse',
          borderRadius: '12px',
        }}
      >
        {toolType === 'Remove' ? <RemoveTool /> : null}
        {toolType === 'Eraser' ? <EraserTool /> : null}
        {toolType === 'Enhance' ? <EnhanceTool /> : null}
        {toolType === 'MagicBg' ? <MagicBgTool ratioFrame={editor.handlers.frameHandler.get().width / editor.handlers.frameHandler.get().height} /> : null}
        <div
          id="footer-action-buttons"
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '80px',
            borderTop: '1px solid #E5E5E5',
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            padding: '16px',
            background: '#fff',
          }}
        >
          <ButtonCustom
            type={SizeButton.LARGE}
            kind={KIND.secondary}
            onClick={() => {
              console.log('historyStatus', historyStatus)

              if (removeEditor.handlers.pixelManipulationObjectHandler.isDoneButtonActive()) {
                setIsOpenPopupDiscard(true)
              } else {
                removeEditor.handlers.zoomRemoveHandler.zoomToRatio(1)
                removeEditor.handlers.pixelManipulationObjectHandler.hideCursorPreview()
                setIsOpenSideBar(false)
                removeEditor.handlers.pixelManipulationObjectHandler.FinishToolState().then(() => {
                  handleCancelRemoveTool(false)
                })
              }
            }}
            style={{
              background: isAnimating ? '#F2F2F2' : 'auto',
              pointerEvents: isAnimating ? 'none' : 'auto',
              transition: 'none'
            }}
          >
            <span style={{ ...lightTheme.typography.Small14Semibold, color: isAnimating ? '#CCCCCC' : '#666666' }}>{t('Cancel')}</span>
          </ButtonCustom>
          <ButtonCustom
            type={SizeButton.LARGE}
            kind={KIND.primary}
            onClick={() => {
              if (isOpen) {
                setIsOpenPixelManipulationWithAnimation(true)
                setIsOpen(false)
                setShowModalType(ShowModalType.GOOD_WORK_MODAL)
                return
              }
              removeEditor.handlers.zoomRemoveHandler.zoomToRatio(1)
              removeEditor.handlers.pixelManipulationObjectHandler.hideCursorPreview()
              setIsOpenSideBar(false)
              if (toolType === 'Eraser') {
                setIsLoadedJsonSuccess(false)
              }
              removeEditor.handlers.pixelManipulationObjectHandler.FinishToolState().then(() => {
                handleCancelRemoveTool(true)
              })
            }}
            style={{
              background: isAnimating ? '#F2F2F2' : removeEditor.handlers.pixelManipulationObjectHandler?.isDoneButtonActive() ? '#FF0560' : '#F2F2F2',
              pointerEvents: isAnimating ? 'none' : removeEditor.handlers.pixelManipulationObjectHandler?.isDoneButtonActive() ? 'auto' : 'none',
              transition: 'none'
            }}
          >
            <span
              style={{
                ...lightTheme.typography.Small14Semibold,
                color: isAnimating ? '#CCCCCC' : removeEditor.handlers.pixelManipulationObjectHandler?.isDoneButtonActive() ? '#FFFFFF' : '#ACACAC',
              }}
            >
              {t('Done')}
            </span>
          </ButtonCustom>
        </div>
      </div>
      <ModalCustom
        isOpen={isOpenPopupDiscard}
        onClose={setIsOpenPopupDiscard}
        content={{ header: t('Discard changes?'), desc: t('Your changes will not be saved.') }}
        action={{
          primary: {
            name: 'Discard',
            handle: () => {
              setIsOpenPopupDiscard(false)
              setTimeout(() => {
                removeEditor.handlers.zoomRemoveHandler.zoomToRatio(1)
                removeEditor.handlers.pixelManipulationObjectHandler.hideCursorPreview()
                setIsOpenSideBar(false)
                removeEditor.handlers.pixelManipulationObjectHandler.FinishToolState().then(() => {
                  handleCancelRemoveTool(false)
                })
              }, 0)
            },
          },
          secondary: {
            name: 'Cancel',
            handle: () => {
              setIsOpenPopupDiscard(false)
            },
          },
        }}
      />
      <TextTip
        isInit={isTextTipInit}
        isError={isTextTipError}
        close={() => {
          setIsTextTipInit(false)
          setIsTextTipError(false)
        }}
      />
    </ThemeProvider>
  )
}

export default PixelManipulation

function TextTip({ isInit = true, isError = false, close }) {
  return (
    <div
      style={{
        position: 'fixed',
        top: '80px',
        left: 'calc((100vw - 356px + 88px) / 2)',
        transform: 'translateX(-50%)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
        borderRadius: '20px',
        display: isInit || isError ? 'flex' : 'none',
        width: 'fit-content',
        height: '40px',
        overflow: 'hidden',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '16px',
        padding: '8px 8px 8px 16px',
        background: isInit ? '#52B5E0' : '#FF0505',
        zIndex: '10'
      }}
    >
      <p style={{ margin: 0, fontSize: '14px', fontWeight: 500, lineHeight: '16px', color: '#fff' }}>
        {isInit ? 'Mark objects or people you want to remove' : 'oops something went wrong, please try again'}
      </p>
      <div
        style={{
          width: '24px',
          height: '24px',
          borderRadius: '50%',
          background: 'rgba(0,0,0,0.1)',
          cursor: 'pointer',
        }}
        onClick={() => close()}
      >
        <Icons.Close size={24} fill={'#fff'} />
      </div>
    </div>
  )
}